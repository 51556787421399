import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import CommitteeVoteStatus, { CommitteeVoteStatusMini } from '@/components/Status/CommitteeVoteStatus';
import { dictTreeToDict, formatDateWithFormat, getDict, isEmptyValues, isNotEmptyValues } from '@/utils';
import { StatusItem } from '@/components/Status/index';
import { useAppSelector } from '@/store';
import { useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

export interface IAdditionalStatusBadgesProps {
  isCard: boolean;
  data: any;
  items: any[];
  projectData: Project;
}

const AdditionalStatusBadges = (props: IAdditionalStatusBadgesProps) => {
  const extModule = useExtImport<IExtendableProps>('components/Status/AdditionalStatusBadgesExt');

  const { isCard, data, items, projectData } = props;

  const dict = useAppSelector(state => state.dict);

  const getSegmentText = () => {
    if (isEmptyValues(data.segmentChain)) {
      return null;
    }

    const from = getDict(dictTreeToDict(dict.segment), data.segmentChain[0]);
    const to = getDict(dictTreeToDict(dict.segment), data.segmentChain[1]);

    if (!from && !to) {
      return null;
    }

    return `${from || '?'} -> ${to || '?'}`
  }

  const segmentText = getSegmentText();

  return (
    <ExtendableWrapper {...extModule} props={props}>
      {!isCard && (
        <div>
          <Link to={`/${i18n.t('base')}/${data.projectVersionId}/agreement`}>
            <CommitteeVoteStatus items={items} />
          </Link>
        </div>
      )}
      {isNotEmptyValues(segmentText) && (
        <StatusItem
          className="status-adv" text={segmentText}
          hoverTitle={`${i18n.t('projectVersion.segment')} -> ${i18n.t('projectVersion.segmentExecutor')}`}
          wrap={true}
        />
      )}
      {data.isClosed && (
        <StatusItem
          className="status-adv CLOSED"
          text={`Закрыто ${formatDateWithFormat(projectData.dateClosed)}`}
        />
      )}

      {isCard && (
        <div className='width100'>
          <CommitteeVoteStatusMini projectData={projectData} />
        </div>
      )}
    </ExtendableWrapper>
  )
}

export default AdditionalStatusBadges;