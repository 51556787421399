import cx from 'classnames';
import i18n from "i18next";
import { groupBy, last } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavigateFunction } from 'react-router-dom';
import ButtonBack from '@/components/Helper/ButtonBack';
import SaveCancel from '@/components/Helper/SaveCancel';
import ModalAsync from '@/components/ModalAsync';
import ProjectHeader from '@/components/ProjectHeader';
import ButtonSimple from '@/elements/ButtonSimple';
import TeamLoadTable from '@/pages/CreateProject/Command/TeamLoadTable';
import service from '@/services';
import {
  checkAccess,
  formatDateToServer,
  getDictForSelect,
  getDictObj,
  getDictCodeById, dictFilterActive
} from '@/utils';
import { withRouterParams } from '@/utils/router';
import { AppendIcon } from '@/pages/CreateProject/BasicNav/Icon';
import { BusinessProcess, ProjectStatus, UserRight } from '@/config/const';
import { updateRequiredSections } from "@/actions/required/updateRequiredSections";
import ButtonRt from "@/components/Helper/ButtonRt";
import withDeviceQuery from "@/hocs/withDeviceQuery";
import {
  WorkTeamManageModalButton
} from "@/pages/CreateProject/Blocks/Components/WorkTeamManageModal";
import services from "@/services";
import AddEmployeeFields from '@/pages/CreateProject/Command/AddEmployee/AddEmployeeFields';
import EditEmployeeRows from '@/pages/CreateProject/Command/AddEmployee/EditEmployeeRows';

export const parseData = (data) => {
  const x = groupBy(data.map(item => ({
    ...item,
    date: moment(item.date),
  })), item => item.date.year());

  return Object.keys(x).map(key => ({
    year: key,
    data: x[key].reduce((acc, item) => ({
      ...acc,
      [item.date.month()]: item
    }), {})
  }));
};

const post = (projectId, data) => service.post(`/employee/project/${projectId}`, data);
const put = (teamId, data) => service.put(`/employee/${teamId}`, data);
const getEmployeeTags = (projectVersionId) => service.get(`/employee/tag/${projectVersionId}`);
const updateEmployeeTag = (empId, tag) => service.put(`/employee/updateTag/${empId}`, tag);
const hide = (teamId) => service.put(`/employee/hide/${teamId}`);
const getWorkTeams = (projectId) => services.get(`/work/getWorkTeam/${projectId}`);

const getTitle = (isEdit, isEditProject) => {
  if (!isEdit) {
    return 'Добавить сотрудника';
  }

  return isEditProject ? 'Редактировать сотрудника' : 'Просмотр сотрудника';
};

export const loadCfo = (query: string) => {
  return service.get(`/user/cfo`, {
    query
  });
};

export const loadCurrentLoadUser = (data: any) => service.get(`/ruz/byUser`, data);

const defaultTag = {value: '', label: 'Не указано'};

interface State {
  data: {
    user: any;
    userLogin?: SelectType;
    roleId?: number;
    powers?: string;
    cfoId?: number;
    projectVersionId?: number;
    isFotCapitalize?: boolean;
    isHideEmployee?: boolean;
    isUserResponsibleWorkOrMilestone?: boolean;
    employeeWorkTeamIds?: any[];
  };
  dataInfo: any[];
  errorModalMessage: any;
  userCfoAccept: number[];
  currentUserLoad: any[];
  initRoleId: number;
  tags: string[];
  tag?: any;
  enableRequiredAccent: boolean;
  isDirty: boolean;
  workTeams?: any[]
}

interface Props {
  isEdit: boolean;
  isAccess: boolean;
  teamId: number;
  dict: Dict;
  projectVersionId: number;
  history: NavigateFunction;
  newProjectData: Project;
  updateRequiredSections: (projectVersionId: number) => void;
  getRequiredAccent: (projectVersionId: number) => boolean;
  isDesktop?: boolean;
}

class AddEmployee extends React.Component<Props, State> {
  modalRef: any;
  modalRefRole: any;
  modalRefOuterEmployee: any;
  modalRefResponsibleEmployee: any;
  modalRefWorkTeam: any;

  disableRedirectOnSave: any;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        user: {},
        isFotCapitalize: false,
        isHideEmployee: false,
        isUserResponsibleWorkOrMilestone: false,
        employeeWorkTeamIds: []
      },
      dataInfo: [],
      errorModalMessage: {},
      userCfoAccept: [],
      currentUserLoad: [],
      initRoleId: null,
      tags: [],
      tag: defaultTag,
      enableRequiredAccent: false,
      isDirty: false,
      workTeams: []
    };

    this.modalRef = React.createRef();
    this.modalRefRole = React.createRef();
    this.modalRefOuterEmployee = React.createRef();
    this.modalRefResponsibleEmployee = React.createRef();
    this.disableRedirectOnSave = React.createRef();
    this.modalRefWorkTeam = React.createRef();

    this.disableRedirectOnSave.current = false;
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.loadData().then(data => {
        if (!data.load.length) {
          this.addRow();
        }
      });
    } else {
      this.addRow();
    }
    this.updateTags();
    this.updateWorkTeams();
  }

  componentDidUpdate(prevProps, prevState): void {
    if (this.props.isEdit && this.state.data.projectVersionId !== prevState.data.projectVersionId) {
      const { projectVersionId, roleId, user: { id: userId } } = this.state.data;

      loadCurrentLoadUser({ projectVersionId, roleId, userId }).then(data => {
        this.setState({
          currentUserLoad: parseData(data)
        });
      });
    }

    if (this.props.isEdit && prevState.data.roleId && this.state.data.roleId !== prevState.data.roleId) {
      this.modalRefRole.current
        .open('В связи с изменением роли сотрудника просим проверить ранее введенную информацию по плановой загрузке сотрудника');
    }

    if(!prevProps.newProjectData.projectId && !!this.props.newProjectData.projectId) {
      this.updateWorkTeams();
    }
  }

  loadData = () => {
    return service.get(`/employee/${this.props.teamId}`).then(data => {
      this.setState({
        data: {
          ...data,
          userLogin: {
            value: data.user.login,
            label: data.user.displayName + (data.user.department ? ` / ${data.user.department}` : ''),
            isLdap: data.user.isLdap,
          },
        },
        dataInfo: parseData(data.load),
        initRoleId: data.roleId,
        tag: data.tag ? this.tagToDict(data.tag) : this.state.tag,
      });
      return data;
    });
  };

  isRoleHidden = (roleDictItem) => roleDictItem?.rightsDict.includes(UserRight.HIDE_SELECT_IN_TEAM);

  isRoleHiddenEdit = (roleDictItem) => roleDictItem?.rightsDict.includes(UserRight.HIDE_SELECT_IN_TEAM_EDIT);

  getRoleOptionsList = (isEditEmployee = false) => {
    const projectType = getDictCodeById(this.props.dict.projectTypes,
      this.props.newProjectData?.typeId);

    const businessProcess = getDictCodeById(this.props.dict.businessProcess,
      this.props.newProjectData?.businessProcessId);

    const btiRoles = ['PROJECT_LEADER_HEAD'];

    return getDictForSelect({
      ...this.props.dict.roles,
      data: this.props.dict.roles.data.filter(item => {
        return item.typeCode === 'PROJECT'
          && dictFilterActive(item)
          && (!btiRoles.includes(item.code) || businessProcess === BusinessProcess.BTI) //TODO: переделать под хелпер
          && (item.projectTypes.length == 0 || item.projectTypesEnum.includes(projectType))
          && !this.isRoleHidden(item)
          && (this.state.initRoleId === item.id || !isEditEmployee || !this.isRoleHiddenEdit(item));
      })
    });
  }

  addRow = () => {
    const prevYear = this.state.dataInfo.length
      ? +last<any>(this.state.dataInfo).year + 1
      : new Date().getFullYear();

    this.setState(({ dataInfo }) => ({
      dataInfo: [
        ...dataInfo,
        {
          year: prevYear,
          data: {}
        }
      ]
    }));
  };

  tagToDict = (tag) => ({ label: tag, value: tag });

  updateTags = () => getEmployeeTags(this.props.projectVersionId)
    .then(data => this.setState({tags: data}));

  updateWorkTeams = () => {
    if (this.props.newProjectData.projectId) {
      getWorkTeams(this.props.newProjectData.projectId)
        .then(data => this.setState({workTeams: data}));
    }
  };

  getTagsForSelect = () => [defaultTag].concat(this.state.tags.map(this.tagToDict));

  deleteRow = (index) => {
    this.setState(({ dataInfo }) => ({
      dataInfo: dataInfo.filter((_, i) => i !== index)
    }));
  };

  addNewEmployee = async (checkIsLdap = true, disableRedirect = false) => {
    if (checkIsLdap
      && !this.props.isEdit
      && this.state.data.userLogin?.isLdap !== true
      && getDictCodeById(this.props.dict.roles, this.state.data.roleId) === 'EXECUTOR') {
      this.modalRefOuterEmployee.current.open().then(() => this.addNewEmployee(false), () => false);
      return false;
    }

    const {projectVersionId, teamId} = this.props;
    const data = {
      ...this.state.data,
      load: this.state.dataInfo
        .reduce((acc, item) => ([...acc, ...Object.values(item.data)]), [])
        .map(item => ({
          ...item,
          date: formatDateToServer(item.date)
        })),
      userLogin: this.state.data.userLogin ? this.state.data.userLogin.value : null,
      user: undefined,
      projectVersionId: projectVersionId,
      employeeTag: this.state.tag?.value,
      employeeWorkTeamIds: this.state.data.employeeWorkTeamIds,
    };

    const isOk = await service.post(`/employee/checkLoad`, data, {
      disableCheckStatus: [409]
    }).then(() => true)
      .catch((data) => {
        if (data.isCheck) {
          this.setState({enableRequiredAccent: true});
          this.props.updateRequiredSections(projectVersionId);
          throw data;
        }

        this.setState({
          errorModalMessage: JSON.parse(data.body.message)
        });
        this.modalRef.current?.open();
        return false;
      });

    if (isOk === false) {
      return false;
    }

    return (this.props.isEdit ? put(teamId, data) : post(projectVersionId, data)).then(() => {
      if (!disableRedirect) {
        this.props.history(`/${i18n.t('base')}/${projectVersionId}/team`, {state: {disableBlock: true}});
      }
    }).catch(reason => {
      this.setState({enableRequiredAccent: true});
      throw reason;
    }).finally(() => this.props.updateRequiredSections(projectVersionId));
  };

  handleChangeSelect = (name, value) => {
    this.setState(prevState => ({
      isDirty: true,
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  };

  handleTagChange = (tag, send = true) => {
    this.setState({tag: tag});
    if (send) {
      this.sendTag(tag);
    }
  };

  sendTag = (tag) => updateEmployeeTag(this.props.teamId, {tag: tag.value, isRemoveTag: !tag.value})
    .then(this.updateTags);

  deleteTeam = () => {
    const { projectVersionId, teamId } = this.props;

    if (!!this.getEmployeeWorkTeams()?.length) {
      this.modalRefWorkTeam.current.open();
      return false;
    }

    service.remove(`/employee/${teamId}`).then(() => {
      this.props.history(`/${i18n.t('base')}/${projectVersionId}/team`, {state: {disableBlock: true}});
    });

    return false;
  };

  hideEmployee = () => {
    if (!!this.getEmployeeWorkTeams()?.length) {
      this.modalRefWorkTeam.current.open();
      return false;
    }

    this.modalRefResponsibleEmployee.current.open().then(() => {
      const {projectVersionId, teamId} = this.props;
      return hide(teamId).then(() => {
        this.props.history(`/${i18n.t('base')}/${projectVersionId}/team`, {state: {disableBlock: true}});
      });
    }, () => false);
  };

  cancel = () => {
    this.props.history(`/${i18n.t('base')}/${this.props.projectVersionId}/team`, {state: {disableBlock: true}})
    return false;
  };

  setData = (dataInfo) => {
    this.setState({
      dataInfo
    });
  };

  isEditCurrentItem = () => {
    if (!this.props.isEdit) {
      return true;
    }

    const roleDict = this.props.dict.roles;
    return !(getDictObj(roleDict, this.state.data.roleId)?.rightsDict || []).includes(UserRight.TEAM_DISABLE_EDIT);
  };

  getEmployeeWorkTeams = () => this.state.data
    .employeeWorkTeamIds?.map(item => this.state.workTeams?.find(team => team.id === item)?.name)
    .filter(item => !!item);

  render() {
    const data = this.state.data;
    const { roleId, user, powers } = data;
    const { projectVersionId, isEdit, isAccess } = this.props;
    const projectId = this.props.newProjectData?.projectId;
    const isEditProject = this.props.newProjectData.edit && this.isEditCurrentItem();
    const isEditFot = getDictObj(this.props.dict.status, this.props.newProjectData.statusId)?.code === ProjectStatus.RELEASE
      && !this.props.newProjectData.isClosed;
    const roleDict = getDictObj(this.props.dict.roles, roleId);
    const isRoleHidden = this.isRoleHidden(roleDict);
    const isFired = getDictCodeById(this.props.dict.userStatus, user?.statusId) === 'DISABLED';
    const isAccessSave = isAccess && (isEditProject || isEditFot);
    const isAccessRemove = isAccess && (!isRoleHidden || roleDict?.rightsDict.includes('DELETE_EMPLOYEE_RELEASE'));

    const isTeamLoadNotEmpty = () => {
      return this.state.dataInfo.some(diObj => Object.keys(diObj.data).some(key => diObj.data[key].value));
    }

    const isTeamLoadRequired = !roleDict?.rightsDict.includes('NO_CHECK_TEAM_LOAD')
      && !isTeamLoadNotEmpty()
      && !isFired
      && !data.isHideEmployee;

    const redRequired = this.props.getRequiredAccent(projectVersionId) || this.state.enableRequiredAccent
      ? 'red'
      : 'default';
    const getDescTextColor = () => redRequired === 'red' ? 'red' : 'rgba(16, 24, 40, 0.50)';

    const getRoleComment = () => {
      if (!isEdit) {
        return null;
      }
      return user?.roleComments?.find(roleComment => roleId
        && roleComment.roleId === roleId)?.roleComment;
    }

    const roleComment = getRoleComment();
    const employeeWorkTeams = this.getEmployeeWorkTeams();
    const tagsForSelect = this.getTagsForSelect();

    const isRemovable = isAccessRemove
      && (this.props.isEdit || roleDict?.rightsDict.includes('DELETE_EMPLOYEE_RELEASE'))
      && !data.isUserResponsibleWorkOrMilestone;

    const isHidable = isAccessRemove
      && (this.props.isEdit || roleDict?.rightsDict.includes('DELETE_EMPLOYEE_RELEASE'))
      && data.isUserResponsibleWorkOrMilestone
      && !data.isHideEmployee;

    return (
      <React.Fragment>
        <ButtonBack to={`/${i18n.t('base')}/${projectVersionId}/team`} />
        <div className="wrapper-option">
          <ProjectHeader title={getTitle(isEdit, isEditProject)} />
          {/* form */}
          <div className={cx('form-felix', 'command__add')}>
            <div className="marginBottom-20">
              {isEdit ? (
                <table className="table table-hove table-felix">
                  <tbody>
                    <EditEmployeeRows
                      isEditProject={isEditProject}
                      isRoleHidden={isRoleHidden}
                      data={data}
                      handleChangeSelect={this.handleChangeSelect}
                      roleOptionsList={this.getRoleOptionsList(true)}
                      tagsForSelect={tagsForSelect}
                      state={this.state}
                      handleTagChange={this.handleTagChange}
                      roleComment={roleComment}
                    />
                  </tbody>
                </table>
              ) : (
                <div>
                  <AddEmployeeFields
                    redRequired={redRequired}
                    data={data}
                    handleChangeSelect={this.handleChangeSelect}
                    roleOptionsList={this.getRoleOptionsList()}
                    tagsForSelect={tagsForSelect}
                    state={this.state}
                    handleTagChange={this.handleTagChange}
                    employeeWorkTeams={employeeWorkTeams}
                  />
                </div>
              )}
            </div>
            <div className="marginBottom-30">
              <hr />
              <div className="flex-space-between">
                <div >
                  <h3 className="h5-felix-medium marginBottom-0 marginTop-0">
                    {i18n.t('employeeDataCreate.title.loadPlan')}
                  </h3>
                  {isTeamLoadRequired &&
                    <div className={'input-title__description break'}
                         style={{color: getDescTextColor(), paddingLeft: '0px'}}>
                      Заполните загрузку минимум одного месяца
                    </div>}
                </div>
                <div className="flex-center">
                  {isEditProject && (
                    <ButtonSimple className="flex-center" children={(
                      <AppendIcon/>
                    )} onClick={this.addRow}/>
                  )}
                </div>
              </div>
              <hr />
              <TeamLoadTable
                deleteRow={this.deleteRow}
                data={this.state.dataInfo}
                setData={this.setData}
                readonly={!isEditProject}
              />
            </div>

            <SaveCancel
              save={isAccessSave
                ? this.addNewEmployee
                : undefined}
              saveFromModal={isAccessSave
                ? () => this.addNewEmployee(true, true)
                : undefined}
              cancel={this.cancel}
              remove={isRemovable
                ? this.deleteTeam
                : undefined}
              isDirty={this.state.isDirty}
              setIsDirty={(isDirty) => this.setState({isDirty: isDirty})}
              childrenLast={isHidable
                ? <ButtonRt type="outline" onClick={this.hideEmployee}>Удалить</ButtonRt>
                : undefined}
            />

            {this.props.isEdit && (
              <div className='mt-5'>
                <hr />
                <div className="flex-space-between">
                  <div className="flex-center">
                    <h3 className="h5-felix-medium marginBottom-0 marginTop-0">
                      {i18n.t('employeeDataCreate.title.loadFact')}
                    </h3>
                  </div>
                </div>
                <hr />

                <TeamLoadTable
                  data={this.state.currentUserLoad}
                  readonly
                />
              </div>
            )}

          </div>
          <ModalAsync size='lg' title="Внимание" ref={this.modalRef} isAlert={true}>
            {`Превышена загрузка сотрудника в ${this.state.errorModalMessage.month} ${this.state.errorModalMessage.year} 
            на ${this.state.errorModalMessage.percent}%. `}
            {'Для добавления сотрудника необходимо высвободить его загрузку по другим проектам путем корректировки. Проекты, в которых задействован сотрудник, можно посмотреть в '}
            <Link to='/report/teamLoad'
                  target="_blank"><span style={{color: 'var(--primary-color)'}}>"Сводном отчете по загрузке сотрудников"</span></Link>
          </ModalAsync>
          <ModalAsync title="Внимание" ref={this.modalRefRole} isAlert={true} />
          <ModalAsync size='lg' title="Ошибка" ref={this.modalRefWorkTeam} isAlert={true}>
            <div>Нельзя удалить сотрудника из команды, так как он состоит в следующих рабочих группах:</div>
            {!!employeeWorkTeams?.length && <div>
              {employeeWorkTeams?.map(item =>
                <div>- {item}</div>)}
            </div>}
            <div>
              <WorkTeamManageModalButton
                updateTeam={() => this.updateWorkTeams()}
                projectId={projectId}
                setData={() => {}}
                onClose={() => this.modalRefWorkTeam?.current?.close()}
                disableChoose/>
            </div>
          </ModalAsync>
          <ModalAsync title="Внимание" ref={this.modalRefOuterEmployee} isAlert={false} okTitle='Да' cancelTitle='Нет'>
            Вы назначаете роль «Исполнитель» внешнему сотруднику.
            В этом случае он будет иметь доступ к просмотру всей карточки.
            Вы уверены, что хотите назначить роль «Исполнитель» внешнему сотруднику?
          </ModalAsync>
          <ModalAsync title="Внимание" ref={this.modalRefResponsibleEmployee} isAlert={false} okTitle='Да' cancelTitle='Нет'>
            Данный сотрудник является ответственным на блоке работ, удаление недоступно, скрыть сотрудника из списка команды?
          </ModalAsync>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  newProjectData: state.NewProject.newProjectData,
  dict: state.dict,
  isAccess: checkAccess(state),
  getRequiredAccent: (projectVersionId) => state.Required[projectVersionId]?.accent,
});

const mapDispatchToProps = (dispatch) => ({
  updateRequiredSections: (projectVersionId) => dispatch(updateRequiredSections(projectVersionId)),
});

export default withRouterParams(connect(mapStateToProp, mapDispatchToProps)(withDeviceQuery(AddEmployee)));