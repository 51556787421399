import React, { useRef } from 'react';
import { DropTargetMonitor, useDrop, XYCoord } from 'react-dnd';
import {
  CalendarType,
  CalendarTypeData,
  ItemType,
  ItemTypes,
  SAUSAGE_HEIGHT,
  SAUSAGE_TOP
} from '@/components/Gantt/const';
import GanttItem, { GanttItemPure } from '@/components/Gantt/elements/GanttItem';
import { useWeekendUtil, WeekendUtilType } from '@/components/Gantt/util/dateUtil';
import {
  checkNotBeforeParent,
  fixByLinks,
  fixGroup,
} from '@/components/Gantt/util/linkUtil';
import {
  calcAppendWith,
  findAndUpdate,
  flatten,
  getAutoFakeIndex,
  getDuration,
  getLinkType, updateWorkDataWithChecks
} from '@/components/Gantt/util/utils';
import {
  formatDate,
  getDictCodeById,
  getDictIdByCode,
  isEmptyValues,
  momentToSelectDate,
  parseDate,
  prevent
} from '@/utils';
import { useAppSelector } from '@/store';
import { ShowModal, useShowModal } from '@/utils/hooks';
import { compact, keyBy } from 'lodash';
import { checkMilestone, getCalendarDuration } from '@/pages/CreateProject/Blocks/utils';
import moment from 'moment/moment';
import { FORMAT_DATE, WORK_STATUS_SUCCESS, WorkStatus } from '@/config/const';

const calcDelta = (x: number, calendarType: CalendarType) => {
  const width = CalendarTypeData[calendarType].width;
  return Math.round(x / width);
};

const updateWork = (
  deltaDays: number,
  type: ItemType,
  oldItem: GanttTableItem,
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
  updateDateEndFact = false
): GanttTableItem => {
  const isSuccess = WORK_STATUS_SUCCESS.includes(WorkStatus[getDictCodeById(workStatusDict, oldItem.statusId)]);

  const isUseDateEndFact = !checkMilestone(oldItem) && isSuccess && oldItem.dateEndFact;

  if (type === ItemTypes.SAUSAGE) {
    const dateEndFact = parseDate(oldItem.dateEndFact);
    const newDateStart = updateDateEndFact ? parseDate(oldItem.dateStart) : parseDate(oldItem.dateStart).add(deltaDays, 'day');
    let newDateEnd = isUseDateEndFact ? parseDate(oldItem.dateEnd) : weekendUtil.getDateEnd(newDateStart, oldItem.duration, false);
    let newDateEndFact = isUseDateEndFact && !updateDateEndFact ? dateEndFact.add(deltaDays, 'day') : dateEndFact;
    if (updateDateEndFact) {
      newDateEndFact = dateEndFact.add(deltaDays, 'day');
    }
    const dateEndForDuration =  isSuccess && newDateEndFact && newDateStart && newDateEndFact.isSameOrAfter(newDateStart)
      ? newDateEndFact
      : newDateEnd;

    return {
      ...oldItem,
      dateStart: momentToSelectDate(newDateStart),
      dateEnd: momentToSelectDate(newDateEnd),
      dateEndFact: momentToSelectDate(newDateEndFact),
      duration: weekendUtil.getDuration(newDateStart, dateEndForDuration),
      calendarDuration: getCalendarDuration(newDateStart, dateEndForDuration),
      isLocalSaved: true,
    };
  }

  if (type === ItemTypes.RESIZE_LEFT) {
    const dateStart = parseDate(oldItem.dateStart);
    const dateEnd = parseDate(oldItem.dateEnd);
    const dateEndFact = parseDate(oldItem.dateEndFact);
    const realDuration = getDuration(dateStart, dateEnd);
    const sub = (realDuration + deltaDays) < 1 ? -realDuration + 1 : deltaDays;
    const newDateStart = dateStart.subtract(sub, 'day');
    const dateEndForDuration =  isSuccess && dateEndFact && newDateStart && dateEndFact.isSameOrAfter(newDateStart)
      ? dateEndFact
      : dateEnd;

    return {
      ...oldItem,
      dateStart: momentToSelectDate(newDateStart),
      duration: weekendUtil.getDuration(newDateStart, dateEndForDuration),
      calendarDuration: getCalendarDuration(newDateStart, dateEndForDuration),
      isLocalSaved: true,
    };
  }

  if (type === ItemTypes.RESIZE_RIGHT) {
    const dateStart = parseDate(oldItem.dateStart);
    const dateEnd = parseDate(oldItem.dateEnd);
    const dateEndFact = parseDate(oldItem.dateEndFact);
    const realDuration = getDuration(dateStart, dateEnd);
    const realDurationDelta = (realDuration + deltaDays) < 1 ? 1 : realDuration + deltaDays;
    const newDateEnd = isUseDateEndFact ? dateEnd : dateStart.add(realDurationDelta - 1, 'days');
    const newDateEndFact = isUseDateEndFact ? dateEndFact.add(deltaDays, 'day') : dateEndFact;
    const dateEndForDuration =  isSuccess && newDateEndFact && dateStart && newDateEndFact.isSameOrAfter(dateStart)
      ? newDateEndFact
      : newDateEnd;

    return {
      ...oldItem,
      dateEnd: momentToSelectDate(newDateEnd),
      dateEndFact: momentToSelectDate(newDateEndFact),
      duration: weekendUtil.getDuration(dateStart, dateEndForDuration),
      calendarDuration: getCalendarDuration(dateStart, dateEndForDuration),
      isLocalSaved: true,
    };
  }
};

const getDelta = (type: string, monitor: DropTargetMonitor, calendarType: CalendarType) => {
  let deltaX = null;

  if (type === ItemTypes.SAUSAGE) {
    deltaX = monitor.getDifferenceFromInitialOffset().x;
  }

  if (type === ItemTypes.RESIZE_LEFT || type === ItemTypes.RESIZE_RIGHT) {
    deltaX = calcAppendWith(
      monitor.getInitialSourceClientOffset(),
      monitor.getSourceClientOffset(),
      type,
      calendarType
    );
  }

  return calcDelta(deltaX, calendarType);
};

interface SausageItem {
  data: GanttTableItem;
  type: ItemType;
}

const updateSausage = (
  item: SausageItem,
  monitor: DropTargetMonitor,
  data: GanttTableItem[],
  setData: UpdateFunc<GanttTableItem[]>,
  calendarType: CalendarType,
  weekendUtil: WeekendUtilType,
  links: GanttTableLink[],
  showModal: ShowModal,
  workStatusDict: DictItem,
  milestoneView = false
) => {
  if (item.data.isFromOtherProject) {
    return;
  }

  const deltaDays = getDelta(item.type, monitor, calendarType);
  const posType = item.data?.posType;
  const updateItem = (oldItem: GanttTableItem) => updateWork(deltaDays, item.type, oldItem, weekendUtil, workStatusDict, posType === 'dateEndFact');
  const onSave = (isUpdateChildren, dropLinkChildrenIds = []) =>
    saveOnUpdateSausage(item, setData, weekendUtil, links, workStatusDict, updateItem, isUpdateChildren, dropLinkChildrenIds);

  updateWorkDataWithChecks(data, links, workStatusDict, weekendUtil, showModal, item.data.id, updateItem, onSave, milestoneView);
};

const saveOnUpdateSausage = (
  item: SausageItem,
  setData: any,
  weekendUtil: WeekendUtilType,
  links: GanttTableLink[],
  workStatusDict: DictItem,
  updateItem: (oldItem: GanttTableItem) => GanttTableItem,
  isUpdateChildren: boolean,
  dropLinkChildrenIds: number[],
) => {
  const newLinks = links.filter(link => link.fromId !== item.data.id || !dropLinkChildrenIds.includes(link.toId));
  setData(oldData => {
    let result = findAndUpdate(oldData, item.data.id, updateItem, workStatusDict, weekendUtil);

    if (isUpdateChildren) {
      const { fixedResult } = fixByLinks(item.data.id, result, newLinks, weekendUtil, workStatusDict);
      result = fixedResult;
    }
    result = fixGroup(result, weekendUtil, workStatusDict);

    return result;
  }, oldLinks => newLinks);
}

const getTargetPosition = (offset: XYCoord, ref: HTMLElement): XYCoord => {
  const { x, y } = offset;
  const { top, left } = ref.getBoundingClientRect();
  return {
    x: Math.round(x - left),
    y: Math.round(y - top)
  };
};

const updateConnect = (
  item: SausageItem,
  positions: PositionGanttItemsType,
  monitor: DropTargetMonitor,
  targetPosition: XYCoord,
  workLinkType: any,
  data: GanttTableItem[],
  setData: any,
  weekendUtil: WeekendUtilType,
  links: GanttTableLink[],
  isEditLinkedWorks: boolean,
  showModal: (message: string, withThrow?: boolean, options?: any) => void,
  workStatusDict: DictItem,
) => {
  const toElementPosition = getIdByPosition(positions, targetPosition);

  if (isEmptyValues(toElementPosition) || item.data.id === toElementPosition.id) {
    return;
  }

  const toItem = data.find(i => i.id === toElementPosition.id);

  if (item.data.isFromOtherProject && toItem.isFromOtherProject) {
    return;
  }

  if (item.data.isBaseGroup || toItem.isBaseGroup) {
    return;
  }

  isEditLinkedWorks = isEditLinkedWorks && !item.data.isFromOtherProject && !toItem.isFromOtherProject;

  const linkType = getLinkType(
    monitor.getItemType() === ItemTypes.CONNECT_LEFT,
    targetPosition.x - toElementPosition.x < toElementPosition.width / 2
  );

  const link = {
    id: getAutoFakeIndex(),
    fromId: item.data.id,
    fromProjectId: item.data.projectId,
    toId: toItem.id,
    toProjectId: toItem.projectId,
    type: linkType,
    typeId: getDictIdByCode(workLinkType, linkType)
  };

  //Проверим не стала ли работа раньше родителя
  if (isEditLinkedWorks) {
    try {
      const currentItem = keyBy(flatten(data), 'id')[item.data.id];
      checkNotBeforeParent(currentItem, data, [...links, link], weekendUtil, workStatusDict);
    } catch (e: any) {
      showModal(e[0].message, true);
    }
  }

  setData(oldData => {
    let result = findAndUpdate(oldData, item.data.id, w => ({ ...w, isLocalSaved: true }), workStatusDict, weekendUtil);
    if (isEditLinkedWorks) {
      const { fixedResult } = fixByLinks(item.data.id, result, [...links, link], weekendUtil, workStatusDict, true);
      result = fixedResult;
    }

    result = fixGroup(result, weekendUtil, workStatusDict);

    return result;
  }, oldLinks => ([...oldLinks, link]));
};

const getIdByPosition = (positions: PositionGanttItemsType, { x, y }: XYCoord) => {
  return Object.values(positions).find(({ work }) => {
    const [x1, y1, x2, y2] = [work.x, work.y, work.x + work.width, work.y + SAUSAGE_HEIGHT];
    return (x1 < x && x < x2) && (y1 < y && y < y2);
  })?.work;
};

export interface GanttContainerProps {
  data: GanttTableItem[];
  width: number;
  setData: any;
  positions: PositionGanttItemsType;
  links: GanttTableLink[];
  calendarType: CalendarType;
  setOpenWorkId: UpdateFunc<number>;
  isEdit: boolean;
  isEditLink: boolean;
  milestoneView?: boolean;
}

const GanttContainer = ({
  data, width, setData, positions, links, calendarType, setOpenWorkId, isEdit, isEditLink, milestoneView = false
}: GanttContainerProps) => {
  const ref = useRef(null);
  const weekendUtil = useWeekendUtil();
  const showModal = useShowModal();
  const [workLinkType, workStatusDict] = useAppSelector(state => [state.dict.workLinkType, state.dict.workStatus]);

  const acceptEditTypes = [];
  if (isEdit) {
    acceptEditTypes.push(ItemTypes.SAUSAGE);
  }
  if (isEditLink) {
    acceptEditTypes.push(ItemTypes.CONNECT_LEFT);
    acceptEditTypes.push(ItemTypes.CONNECT_RIGHT);
  }

  const [, dropRef] = useDrop(
    () => ({
      accept: acceptEditTypes,
      drop: (item: any, monitor) => {
        switch (monitor.getItemType()) {
          case ItemTypes.SAUSAGE:
            updateSausage(item, monitor, data, setData, calendarType, weekendUtil, links, showModal, workStatusDict, milestoneView);
            break;
          case ItemTypes.CONNECT_LEFT:
          case ItemTypes.CONNECT_RIGHT:
            updateConnect(item, positions, monitor, getTargetPosition(monitor.getClientOffset(), ref.current),
              workLinkType, data, setData, weekendUtil, links, isEdit, showModal, workStatusDict);
            break;
          default:
            break;
        }
      },
    }), [positions, links]);

  dropRef(ref);

  return (
    <div className="gantt-items" ref={!isEdit && !isEditLink ? undefined : ref} style={{
      height: (SAUSAGE_HEIGHT) * data.length,
      width
    }}>
      {data.map(item => {
        if (!positions[item.id]) {
          return;
        }

        const positionWork = positions[item.id].work;
        const positionDateEndInit = positions[item.id].dateEndInitial;
        const positionDateEndFact = positions[item.id].dateEndFact;

        const isMilestone = checkMilestone(item);

        const getMilestoneColorClass = () => {
          if (!isMilestone) {
            return {classPlan: '', classFact: ''}
          }

          let classPlan = '';

          const today = moment();
          const parsedStart = parseDate(item.dateStart);
          const parsedEndFact = parseDate(item.dateEndFact);

          const isTodayAfterPlan = !parsedEndFact
            && today.isAfter(parsedStart, 'day');

          const closedInTime = parsedEndFact
            && parseDate(parsedStart).isAfter(parsedEndFact, 'day');

          const closedSameDay = parsedEndFact
            && parseDate(parsedStart).isSame(parsedEndFact, 'day');

          const isOverdue = isTodayAfterPlan;
          const isCanceled = 'CANCEL' === getDictCodeById(workStatusDict, item.statusId);

          if (isOverdue) {
            classPlan = 'red';
          }
          if (closedInTime) {
            classPlan =  'blue';
          }
          if (closedSameDay) {
            classPlan = 'green';
          }
          if (isCanceled) {
            classPlan = '';
          }
          return {classPlan: classPlan, classFact: 'green'}
        }

        const milestoneColorClass = getMilestoneColorClass();

        const maxPosId = compact([positionWork, positionDateEndInit, positionDateEndFact])
          .sort((a, b) => (b?.x ?? 0) - (a?.x ?? 0))[0]?.id;

        return (
          <React.Fragment key={item.id}>
            {positionDateEndInit && isMilestone && (
              <GanttItemPure
                key={positionDateEndInit.id}
                item={{ name: milestoneView ? item.name : 'Базовый срок (план)',
                        posType: 'dateEndInitial',
                        customClass: ['milestone', 'light-gray'] } as GanttTableItem}
                tooltip={`Базовый план: ${formatDate(item.dateEndInitial, FORMAT_DATE)}`}
                left={positionDateEndInit.x}
                top={positionDateEndInit.y + SAUSAGE_TOP}
                width={positionDateEndInit.width}
                onClick={() => {}}
                readonly={true}
                milestoneView={milestoneView}
                isLast={positionDateEndInit.id === maxPosId}
              />
            )}
            {positionDateEndFact && isMilestone && (
              <GanttItem
                key={positionDateEndFact.id}
                data={{
                  ...item,
                  posType: 'dateEndFact',
                  customClass: ['milestone'].concat(milestoneColorClass.classFact) }}
                tooltip={`Факт: ${formatDate(item.dateEndFact, FORMAT_DATE)}`}
                position={positionDateEndFact}
                onClick={prevent(() => setOpenWorkId(item.id))}
                readonly={!isEdit && !isEditLink || item.isExtreme || item.isBaseGroup}
                milestoneView={milestoneView}
                isLast={positionDateEndFact.id === maxPosId}
              />
            )}
            {positionWork && (
              <GanttItem
                key={positionWork.id}
                data={{
                  ...item,
                  posType: 'datePlan',
                  customClass: (item.customClass && !isMilestone ? item.customClass : [])
                    .concat(item.cpm ? 'CPM' : '')
                    .concat(milestoneColorClass.classPlan)}}
                tooltip={isMilestone && `План: ${formatDate(item.dateEnd, FORMAT_DATE)}`}
                position={positionWork}
                onClick={prevent(() => setOpenWorkId(item.id))}
                readonly={!isEdit && !isEditLink || item.isExtreme || item.isBaseGroup}
                milestoneView={milestoneView}
                isLast={positionWork.id === maxPosId}
              />
            )}
            {positionDateEndInit && !isMilestone && (
              <GanttItemPure
                key={positionDateEndInit.id}
                item={{ name: 'Базовый срок (план)', customClass: ['date-end-init'] } as GanttTableItem}
                left={positionDateEndInit.x}
                top={positionDateEndInit.y + SAUSAGE_TOP}
                width={positionDateEndInit.width}
                onClick={() => {}}
                readonly={true}
                milestoneView={milestoneView}
              />
            )}
          </React.Fragment>
        )})}
    </div>
  );
};

export default GanttContainer;