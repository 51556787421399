import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useExpanded, useTable, useResizeColumns, useBlockLayout } from 'react-table';
import { moveGroup } from '@/components/Gantt/components/EditItem';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';
import { fixByLinks, fixGroup } from '@/components/Gantt/util/linkUtil';
import {
  findAndUpdate,
  getDateEndForDuration,
  insertBeforeId,
  isChild,
  removeById,
  searchById,
  searchIndex,
  updateWorkDataWithChecks
} from '@/components/Gantt/util/utils';
import { WORK_STATUS_SUCCESS, WorkStatus, WorkType } from '@/config/const';
import { checkGroup, checkMilestone, getCalendarDuration, getDuration } from '@/pages/CreateProject/Blocks/utils';
import { cloneDeep, sum } from 'lodash';
import MoveRowModal from '@/components/Gantt/components/MoveRowModal';
import { getDictCodeById, momentToSelectDate, parseDate, prevent } from '@/utils';
import { useShowModal } from '@/utils/hooks';
import { useAppSelector } from '@/store';

const DND_ITEM_TYPE = 'row';

const update = (arr, dragIndex, hoverIndex, expanded, groupDirection, weekendUtil, isEditGroup, workStatusDict) => {
  const itemDrag = searchIndex(arr, dragIndex, expanded);
  const itemHover = searchIndex(arr, hoverIndex, expanded);

  isEditGroup = isEditGroup && !itemDrag.isFromOtherProject && !itemHover.isFromOtherProject && !itemHover.isSystem;

  if (!itemDrag || !itemHover) {
    return false;
  }

  if (isChild(itemDrag.subRows, itemHover.id)) {
    return false;
  }

  let result = arr;

  if (groupDirection === 1) {
    if (!isEditGroup) {
      return false;
    }

    result = findAndUpdate(result, itemHover.id, val => {
      val.subRows ||= [];
      val.typeId = WorkType.GROUP;
      val.isLocalSaved = true;
      return val;
    }, workStatusDict, weekendUtil);
    result = findAndUpdate(result, itemDrag.id, val => {
      val.workGroupId = itemHover.id;
      val.isLocalSaved = true;
      return val;
    }, workStatusDict, weekendUtil);
    result = moveGroup(result, itemDrag.id, itemDrag.workGroupId, itemHover.id);
  } else if (groupDirection === -1) {
    if (!isEditGroup || itemHover.isBaseGroup) {
      return false;
    }

    result = findAndUpdate(result, itemDrag.id, val => {
      val.workGroupId = searchById(result, itemDrag.workGroupId)?.workGroupId;
      val.isLocalSaved = true;
      return val;
    }, workStatusDict, weekendUtil);
    result = moveGroup(result, itemDrag.id, itemDrag.workGroupId, null);
  } else {
    if (itemHover.isBaseGroup) {
      return false;
    }

    if (!isEditGroup
      && (itemDrag.workGroupId !== itemHover.workGroupId || checkGroup(itemHover))) {
      return false;
    }

    result = removeById(result, itemDrag.id);
    result = insertBeforeId(result, itemHover.id, dragIndex > hoverIndex, itemDrag, !!expanded[itemHover.id]);
  }

  result = fixGroup(result, weekendUtil, workStatusDict);

  return result;
};

const Header = ({ headerGroups }) => {
  return headerGroups.map((headerGroup) => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => {
        const style = {
          ...(column.headerStyle || {}),
        };

        return (
          <th {...column.getHeaderProps({
            className: column.headerClassName,
            style,
          })}>
            {column.render('Header')}
            {column.resizable && (
              <div
                {...column.getResizerProps()}
                className={`resizer ${
                  column.isResizing ? "isResizing" : ""
                }`}
              ></div>
            )}
          </th>
        );
      })}
    </tr>
  ))
}

const Row = ({
  row,
  index,
  moveRow,
  onClickRow,
  readonly,
  isEditRight,
  setExpanded,
  data,
  expanded,
  rowProps,
  children = undefined,
}) => {
  const dropRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item: any, monitor) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      const isMove = moveRow(dragIndex, hoverIndex, null, !readonly && isEditRight);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      if (isMove) {
        item.index = hoverIndex;
      }
    },
    drop(item: any, monitor) {
      const dragIndex = item.index;
      const hoverIndex = dragIndex === index ? index - 1 : index;

      if (dragIndex < 0 || hoverIndex < 0) {
        return;
      }

      const leftOffset = monitor.getDifferenceFromInitialOffset().x;
      const hoverItem = searchIndex(data, hoverIndex, expanded);

      if (leftOffset > 20) {
        setExpanded(oldExpanded => ({
          ...oldExpanded,
          [`${hoverItem.id}`]: true
        }));
        moveRow(dragIndex, hoverIndex, 1, !readonly && isEditRight);
      }

      if (leftOffset < -30) {
        moveRow(dragIndex, hoverIndex, -1, !readonly && isEditRight);
      }
    }
  });

  row.drag = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isDragging },, preview] = row.drag;

  const opacity = isDragging ? 0.2 : 1;

  preview(drop(dropRef));

  return children ? (
    <tr
      data-task-id={row.id}
      ref={readonly ? undefined : dropRef}
      onClick={(e) => onClickRow(row, e)}
      {...rowProps}
      className={cx({ 'work-group__item': checkGroup(row.original) }, rowProps.className)}
      style={{ opacity, ...rowProps?.style }}
    >
      {children}
    </tr>
  ) : (
    <tr
      data-task-id={row.id}
      ref={readonly ? undefined : dropRef}
      onClick={(e) => onClickRow(row, e)}
      {...rowProps}
      className={cx({ 'work-group__item': checkGroup(row.original) }, rowProps.className)}
      style={{ opacity, ...rowProps?.style }}
    />
  );
};

const Cell = ({ row, setMoveRowData }) => {
  const dragRef = React.useRef(null);

  const handleMove = () => {
    setMoveRowData({ isShowModal: true, row })
  }

  return row.cells.map(cell => {
    const isDraggable = cell.column.draggable?.({ row });
    if (isDraggable) {
      const [, drag] = row.drag;
      drag(dragRef);
    }

    return (
      <td
        ref={isDraggable ? dragRef : undefined}
        {...cell.getCellProps({ style: cell.column.style })}
        className={cell.column.className}
        onClick={isDraggable ? prevent(() => {}) : undefined}
        onDoubleClick={isDraggable ? prevent(handleMove) : undefined}
      >
        {cell.render('Cell')}
      </td>
    )
  });
};

const TableDragable = ({
  columns,
  data,
  link,
  expandedLocal,
  setExpanded,
  expandAllClick,
  isAllNotExpanded,
  setData,
  onClickRow,
  rowProps,
  readonly,
  isEditRight,
  disableDragModal = false,
  ignoreWorkGroups = false,
  onMove = undefined,
  props,
  milestoneView = false
}) => {
  const workStatusDict = useAppSelector(state => state.dict.workStatus);
  const weekendUtil = useWeekendUtil();
  const showModal = useShowModal();
  const records = data;
  const getRowId = React.useCallback(row => {
    return row.id;
  }, []);

  const [moveRowData, setMoveRowData] = useState({
    isShowModal: false,
    row: null,
  });

  const setDataById = (id, key, value) => {
    const updateItem = (item) => {
      let result = ({
        ...item,
        [key]: value,
      });

      //Для вех продублируем дату начала
      if (key === 'dateEnd' && checkMilestone(item)) {
        result.dateStart = value;
      }

      //Подвинем дату окончания если подвинули дату начала
      const dateStartPrev = parseDate(item.dateStart);
      const dateStart = parseDate(result.dateStart);
      const dateEnd = parseDate(result.dateEnd);
      if (key === 'dateStart' && dateStartPrev && dateStart && dateEnd && !dateStartPrev.isSame(dateStart)) {
        const duration = weekendUtil.getDuration(dateStartPrev, dateEnd);
        result.dateEnd = momentToSelectDate(weekendUtil.getDateEnd(dateStart, duration, false));
      }

      //Затрем дату факт если не готово
      if (key === 'statusId' && !WORK_STATUS_SUCCESS.includes(WorkStatus[getDictCodeById(workStatusDict, result.statusId)])) {
        result.dateEndFact = null;
      }

      const dateEndForDuration = getDateEndForDuration(result, workStatusDict);
      result = {
        ...result,
        duration: getDuration(result.dateStart, dateEndForDuration, weekendUtil),
        calendarDuration: getCalendarDuration(result.dateStart, dateEndForDuration),
        isLocalSaved: true,
      };

      return result;
    }

    const isDateChanged = key === 'dateStart' || key === 'dateEnd' || key === 'dateEndFact';

    const saveOnUpdate = (isUpdateChildren: boolean, dropLinkChildrenIds: number[] = []) => {
      const newLinks = link.filter(link => link.fromId !== id || !dropLinkChildrenIds.includes(link.toId));
      setData(oldData => {
        let result = findAndUpdate(oldData, id, updateItem, workStatusDict, weekendUtil);

        if (isUpdateChildren && isDateChanged) {
          const { fixedResult } = fixByLinks(id, result, link, weekendUtil, workStatusDict);
          result = fixedResult;
        }

        result = fixGroup(result, weekendUtil, workStatusDict);

        return result;
      }, oldLinks => newLinks);
    }

    if (isDateChanged) {
      updateWorkDataWithChecks(data, link, workStatusDict, weekendUtil, showModal, id, updateItem, saveOnUpdate, milestoneView);
    } else {
      saveOnUpdate(true);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    state: { expanded },
  } = useTable(
    {
      columns: columns,
      data: records,
      getRowId,
      setDataById,
      initialState: {
        expanded: expandedLocal
      },
      props,
      autoResetResize: false,
    },
    useExpanded, // Use the useExpanded plugin hook
    useBlockLayout,
    useResizeColumns,
  );

  useEffect(() => {
    setExpanded(prevExpanded => {
      const newExpanded = cloneDeep(prevExpanded);
      Object.keys(newExpanded).forEach(key => newExpanded[key] = expanded[key]);
      return newExpanded;
    });
  }, [expanded]);

  useEffect(() => {
    if (expandAllClick === 0) {
      return;
    }

    toggleAllRowsExpanded(isAllNotExpanded);
  }, [expandAllClick]);

  const moveRow = (dragIndex, hoverIndex, groupDirection, isEditGroup): boolean => {
    let isUpdate = false;
    setData(oldData => {
      const data = update(oldData, dragIndex, hoverIndex,
        expanded, groupDirection, weekendUtil, isEditGroup && !ignoreWorkGroups, workStatusDict);
      if (data === false) {
        return oldData;
      }
      isUpdate = true;
      onMove && onMove(data);
      return data;
    });

    return isUpdate;
  };

  return (
    <>
      <table {...getTableProps()}>
        <thead className='sticky-top'>
        <Header headerGroups={headerGroups} />
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Row
                key={index}
                index={index}
                row={row}
                moveRow={moveRow}
                onClickRow={onClickRow}
                readonly={readonly}
                isEditRight={isEditRight}
                setExpanded={setExpanded}
                data={records}
                expanded={expanded}
                rowProps={row.getRowProps(rowProps(row))}
              >
                <Cell row={row} setMoveRowData={setMoveRowData} />
              </Row>
            );
          })}
        </tbody>
      </table>
      {!disableDragModal &&
      <MoveRowModal
        moveRowData={moveRowData}
        setMoveRowData={setMoveRowData}
        rows={rows}
        setData={setData}
        expanded={expanded}
        isInGroupMoveOnly={!isEditRight}
      />
      }
    </>
  );
};

export default TableDragable;