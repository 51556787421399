export default {
  SYSTEM: {
    translation: {
      'Is show only my': 'Только с моим участием',
      'agreementTextOrganization': '',
      ruz: {
        title: 'Учет фактической занятости',
        reportTitle: 'Учет фактической занятости в проектах',
        loadProjectTitle: 'Фактическая загрузка на проекте, %',
        loadProgramTitle: 'Фактическая загрузка на программе, %',
        loadPortfolioTitle: 'Фактическая загрузка на портфеле, %',
      },
      userManagement: {
        segments: 'Бизнес единицы',
        currentSegments: 'Бизнес единицы сотрудника',
        changeSegments: 'Изменить бизнес единицы',
      },
      userAcceptRole: {
        roleList: 'Права доступа'
      },
      authRequest: {
        login: 'Имя пользователя (логин)',
        loginDesc: 'Tребуется ввести адрес электронной почты',
        password: 'Пароль',
      },
      notifySendSystemMessage: {
        message: 'Текст',
        subject: 'Тема письма'
      },
      accessOrganizationView: {
        name: 'Название организации',
        domain: 'Список разрешенных доменов',
        type: 'Тип организации',
        dzo: 'Аффилированная компания',
        agreementExecutor: 'Подрядчик',
        contractNum: 'Номер договора с подрядчиком',
        curator: 'Инициатор добавления организации',
        blockingDate: 'Дата блокировки организации'
      },
      registerRequest: {
        orgId: 'Название юр. лица',
        displayName: 'ФИО пользователя',
        post: 'Должность',
        phone: 'Служебный тел. (если есть)',
        email: 'Email',
        password: 'Пароль',
        roleList: 'Права доступа',
      },
      passwordRecoveryRequest: {
        email: 'Email'
      },
      passwordRecovery: {
        token: 'Токен',
        password: 'Пароль',
        passwordConfirmation: 'Подтверждение пароля',
      },
      reportPage: {
        project: {
          isClosed: 'Статус карточки'
        },
        budget: {
          isShowAllVersionsWithR12: 'Все версии карточек с кодом проекта'
        }
      },
      committeeProjectWeb: {
        draftDecision: {
          name: 'Пункт проекта решения'
        },
        assignments: {
          name: 'Текст поручения',
          protocolNumber: 'Номер протокола',
          responsible: 'Ответственный',
          controller: 'Контролер поручения',
          dateExecution: 'Дата исполнения',
        }
      },
      committeeProjectCancelWeb: {
        comment: 'Комментарий'
      },
      committeeWeb: {
        name: 'Название',
        typeId: 'Тип',
        dateStart: 'Дата',
      },
      committeeProjectBindWeb: {
        committeeId: 'Название комитета'
      },
      contractWeb: {
        name: 'Номер договора',
        counterparty: 'Название контрагента',
        dateStart: 'Дата начала действия',
        dateEnd: 'Дата окончания действия',
        rolePrice: {
          __name: 'Роль и ставка',
          roleId: 'Роль',
          price: 'Ставка в час руб., без НДС',
        },
      },
      userDeputyWeb: {
        deputyDateStart: 'Начало замещения',
        deputyDateEnd: 'Окончание замещения',
        userDeputy: 'Замещающий',
        isAllRole: 'По всем ролям'
      },
      userUpdateRequest: {
        deputyData: {
          deputyDateStart: 'Начало замещения',
          deputyDateEnd: 'Окончание замещения',
          userDeputy: 'Замещающий',
          deputyLogin: 'Замещающий',
          isAllRole: 'По всем ролям'
        }
      },
      deputyController:{
        updateDeputy:{
          data:{
            deputyDateStart: 'Начало замещения',
            deputyDateEnd: 'Окончание замещения',
            userDeputy: 'Замещающий',
            deputyLogin: 'Замещающий',
            isAllRole: 'По всем ролям'
          }
        },
        checkDeputyHostOfDeputyHostDates:{
          data:{
            deputyDateStart: 'Начало замещения',
            deputyDateEnd: 'Окончание замещения',
            userDeputy: 'Замещающий',
            deputyLogin: 'Замещающий',
            isAllRole: 'По всем ролям'
          }
        }
      },
      releaseNoteData: {
        releaseNote: 'Текст',
        releaseTime: 'Время релиза',
        releaseDate: 'Дата релиза',
      },
      videoLessonData: {
        name: 'Название видеоурока',
        description: 'Стенограмма',
      },
      committeeStage: {
        COMMON: '',
        COMMITTEE_FIRST_QUEUE: '1я очередь',
        COMMITTEE_PORTFOLIO_LEADER: 'Согласование Руководителя портфеля',
        COMMITTEE_SECOND_QUEUE: 'На экспертизе',
        COMMITTEE_VOTE: 'На ПК',
        COMMITTEE_INTRAMURAL: 'Очный ПК'
      },
    },
  },
  PROJECT: {
    translation: {
      'PROJECT_TYPE': 'PROJECT',
      'base': 'project',
      'Name': 'Проект',
      'Main title': 'Проекты',
      'Main title short': 'Проекты',
      'Main title key': 'Ключевые проекты',
      'New title': 'Новый проект',
      'Search title': 'Поиск по названию или ID проекта',
      'Search title mobile': 'Поиск по проектам',
      'Input name': 'Наименование проекта',
      'Input jiraCode title': 'Код в Jira',
      'Create button': 'Создать проект',
      'Create button technical': 'Создать техническую карточку',
      'Start date': 'Начало проекта',
      'End date': 'Окончание проекта',
      'Go to agreement': 'Отправить проект на согласование',
      'Go to rollback': 'Отменить данную версию',
      'Input stage title': 'Стадия',
      'Input perimeter title': 'Периметр проекта',
      'Input manager title': 'Управление проектом',
      'Input geography title': 'География проекта',
      'Input limits title': 'Допущения и ограничения',
      'Input background title': 'Предпосылки проекта и бизнес-потребности',
      'Input units title': 'Подразделения участвующие в проекте',
      'Input provider title': 'Поставщик / Подрядчик / Вендор',
      'Link team load': 'Плановая загрузка команды по проекту',
      'Agreement send first': 'Запрос на инициацию отправлен на согласование',
      'Agreement send second': 'Запрос на изменение отправлен на согласование',
      'Agreement send second section': 'Запрос на изменение разделов (%s) отправлен на согласование',
      'Agreement send close': 'Запрос на закрытие отправлен на согласование',
      'Agreement send close section': 'Запрос на закрытие (разделы %s) отправлен на согласование',
      'Agreement close project': 'Проект закрыт',
      'Agreement accept': 'Проект согласован',
      'Agreement reject': 'Проект отклонён',
      'Agreement rollback': 'Проект возвращен на доработку',
      'agreementToProjectLeader': 'Проект отправлен на согласование Секретарем ПК',
      'budget no data': ' - ',
      'ImportProject': 'Импорт',
      'isNotShowClosed': 'Не показывать закрытые',
      'budgetIntegrationButtonText': '',
      dashboard: {
        dateStart: 'Дата начала',
        dateEnd: 'Дата окончания',
        dateEndInitial: 'Дата окончания в первой версии',
        manager: 'Управление',
        direction: 'Направление',
        curator: 'Куратор',
        topManager: 'Заказчик',
        customer: 'Согласующий от заказчика',
        leaderProject: 'Руководитель',
        agreeDate: 'Дата согласования карточки',
        agreeDateShort: 'Дата согл. карточки',
        reportPublishDate: 'Дата публикации статус-отчета',
        reportPublishDateShort: 'Дата публик. статус-отчета',
        reportCompletePercent: 'Процент выполнения проекта, %',
        reportBudgetStatus: 'Бюджет',
        reportTimingStatus: 'Сроки',
        reportResourceStatus: 'Ресурсы',
        reportPerimeterStatus: 'Периметр',
        reportRiskStatus: 'Риски',
        reportEscalation: 'Эскалация',
        projectAgreeCount: 'Согласования',
        spi: 'SPI',
        cpiOkb: 'CPI ОКВ',
        cpiDds: 'CPI ДДС',
        cpiOpex: 'CPI OPEX',
        cpiFot: 'CPI ФОТ',
        budgetAll: 'Общий бюджет проекта, $t(SUM_TITLE) (без НДС)',
        isTopLevel: 'Верхний уровень',
        isOnlyTopLevel: 'Только верхний уровень',
        isInKey: 'Входит в ключевые',
        closedProjects: 'Закрытые проекты',
        budgetFrom: 'Бюджет от ($t(SUM_TITLE))',
        budgetTo: 'Бюджет до ($t(SUM_TITLE))',
        isCross: 'Кросс-функциональный проект',
      },
      committeeStage: {
        COMMON: '',
        COMMITTEE_FIRST_QUEUE: '1я очередь',
        COMMITTEE_PORTFOLIO_LEADER: 'Согласование Руководителя портфеля',
        COMMITTEE_SECOND_QUEUE: 'На экспертизе',
        COMMITTEE_VOTE: 'На ПК',
        COMMITTEE_INTRAMURAL: 'Очный ПК'
      },
      projectKpiWeb: {
        name: 'Наименование КПЭ',
        plan: 'План по КПЭ',
        planRelease: 'Из плана согласовано',
        fact: 'Факт по КПЭ',
        factRelease: 'Из факта согласовано',
        typeId: 'Тип КПЭ',
        statusId: 'Статус',
        year: 'Год',
        weight: 'Вес КПЭ внутри года, %',
        methodCalc: 'Метод расчета',
        unitType: 'Единица измерения',
        result: 'Результат по итогам выполнения, %',
        dateResult: 'Плановая дата достижения',
        dateEndFact: 'Фактическая дата достижения',
        parentWorkId: 'Связано с блоком работ',
        resultComment: 'Описание достигнутого результата',
        kpiBase: 'Базовая метрика',
        isKpiCascade: 'КПЭ каскадируется ниже',
        cascadeTypeId: 'Способ заполнения плана КПЭ',
        isKpiCascadeTable: 'КПЭ каскадирован',
        parentCascadeKpiIdTable: 'Входит в каскад',
      },
      projectKpiYearWeight: {
        year: 'Год',
        avgResult: 'Средневзвешенное выполнение в год, %',
        weight: 'Вес, %',
        projectResult: 'Выполнение по году, %',
        sumProjectResult: 'Выполнение по проекту, %',
      },
      mboDataRequest: {
        statusId: 'Статус',
        goal: 'Цель',
        goalTypeId: 'Тип цели',
        user: 'Исполнитель',
        userId: 'Исполнитель',
        performance: 'Метод расчета показателя, критерии выполнения',
        weight: 'Вес, %',
        period: 'Год',
        quarterId: 'Период',
        performancePercent: 'Процент выполнения',
        comment: 'Комментарий к оценке выполнения',
        periodAndQuarterId: 'Оценочный период',
        isSignificantInfluence: 'Значимое влияние на результат',
        motivationScaleId: 'Шкала премирования',
        motivationStatus: 'Статус в ИС Премирование',
        motivationWeight: 'Вес MBO в итоговой интегральной оценке, %',
        parentWorkId: 'Связано с блоком работ',
        mboBase: 'Базовая метрика'
      },
      ProjectMilestoneWeb: {
        name: 'Название',
        comment: 'Комментарий',
        responsibleLogin: 'Ответственный',
        responsible: 'Ответственный',
        responsibleRoleId: 'Роль ответственного',
        datePlan: 'Дата (план)',
        datePrediction: 'Прогнозная дата',
        dateFact: 'Дата (факт)',
        datePlanBase: 'Базовый срок (план)',
        statusId: 'Статус',
        status: 'Статус'
      },
      projectMilestoneWeb: {
        name: 'Название',
        comment: 'Комментарий',
        responsibleLogin: 'Ответственный',
        responsible: 'Ответственный',
        responsibleRoleId: 'Роль ответственного',
        datePlan: 'Планируемая дата',
        datePrediction: 'Прогнозная дата',
        dateFact: 'Фактическая дата'
      },
      projectMilestoneController: {
        updateMilestoneFromGantt:{
          data: {
            name: 'Название',
            comment: 'Комментарий',
            responsibleLogin: 'Ответственный',
            responsible: 'Ответственный',
            datePlan: 'Планируемая дата',
            datePrediction: 'Прогнозная дата',
            dateFact: 'Фактическая дата'
          }
        }
      },
      workTeamWeb:{
        name: 'Название',
        memberList: {
          roleId: 'Роль',
          user: 'Исполнитель'
        },
        buttonManage: 'Управление рабочими группами'
      },
      workDataRequest: {
        name: 'Название',
        responsible: 'Ответственный',
        responsibleRoleId: 'Роль ответственного',
        statusId: 'Статус',
        dateStart: 'Начало',
        dateEnd: 'Окончание (план)',
        dateEndFact: 'Окончание (факт)',
        dateEndInitial: 'Базовый срок (план)',
        dateUpdate: 'Дата последнего изменения',
        dateUpdateStatus: 'Последнее изменение статуса',
        duration: 'Кол-во рабочих дней',
        calendarDuration: 'Кол-во календарных дней',
        calendarDurationShort: 'Кол-во календ. дней',
        results: 'Ожидаемые результаты',
        comment: 'Комментарий к выполнению/статус',
        isShowDashboard: 'Отображать в Dashboard',
        isGroup: 'Группа работ',
        workGroupId: 'Входит в группу работ',
        weight: 'Вес текущего блока работ в группе (%)',
        progress: {
          plannedValue: 'Плановое значение',
          nameNaturalIndex: 'Название натурального показателя',
          factValue: 'Фактическое значение',
          completeValue: 'Процент завершения',
          commentProgress: 'Комментарий',
        },
        name_section_PROJECT: 'График работ в Проектах данного Проекта',
        name_section_PROGRAM: 'График работ в Программах данного Проекта',
        responsibleList: {
          user: 'Исполнитель',
          roleId: 'Роль',
          workPlan: 'Плановые трудозатраты',
          workFact: 'Фактические трудозатраты',
          isWithLoad: 'Вести учет рабочего времени',
        },
        typeId: 'Тип блока работ',
        urlList: {
          title: 'Наименование',
          url: 'URL-адрес',
        },
        links: {
          fromId: 'Предшествующий блок работ',
          toId: 'Последующий блок работ',
          typeId: 'Тип связи блоков работ',
        },
      },
      riskDataRequest: {
        name: 'Наименование',
        description: 'Описание',
        user: 'Ответственный за митигацию',
        userLogin: 'Ответственный за митигацию',
        probabilityId: 'Вероятность наступления',
        priorityId: 'Степень влияния',
        riskValue: 'Величина риска',
        value: 'Величина риска',
        statusId: 'Статус риска',
        typeId: 'Тип риска',
        strategyId: 'Стратегия по работе с риском',
        affects: 'На что влияет',
        cause: 'Причины возникновения',
        effects: 'Последствия реализации риска',
        response: 'Меры митигации',
        riskAction: 'Действия при наступлении риска',
        riskRealisationCost: 'Фактическая стоимость реализации риска, $t(SUM_TITLE) (без НДС)',
        riskRealisationCostMobile: 'Факт. стоимость риска, $t(SUM_TITLE) (без НДС)',
        budgetReserve: 'Резерв бюджета, $t(SUM_TITLE) (без НДС)',
        isBudgetReserveCounted: 'Учтен в бюджете проекта',
        timeReserve: 'Резерв по сроку, дней',
        isTimeReserveCounted: 'Учтен в графике реализации проекта'
      },
      userRoleComment: {
        roleId: 'Роль',
        roleComment: 'Комментарий'
      },
      employeeDataCreate: {
        title: {
          loadFact: '$t(ruz.loadProjectTitle)',
          loadPlan: 'Плановая загрузка на проекте, %',
        },
        userLogin: 'Сотрудник',
        roleId: 'Роль',
        roleComment: 'Комментарий',
        load: {
          year: 'Год',
          part1: 'I квартал, %',
          part2: 'II квартал, %',
          part3: 'III квартал, %',
          part4: 'IV квартал, %',
          value: 'Плановая загрузка на проекте, %'
        },
        powers: 'Полномочия',
        deputy: 'Замещающий',
        department: 'Подразделение',
        tag: 'Тег',
        isHideEmployee: 'Скрыть из команды',
        workTeams: 'Рабочие группы пользователя',
        position: 'Должность',
        motivationTypeId: 'Тип премирования',
      },
      projectVersionData: {
        name: 'Наименование проекта',
        projectTypeId: 'Тип карточки',
        statusId: 'Статус карточки',
        alternativeDoNothing: '$t(projectVersion.alternativeDoNothing)',
        alternativeImplementationMin: '$t(projectVersion.alternativeImplementationMin)',
        alternativeImplementation: '$t(projectVersion.alternativeImplementation)',
        alternativeJustification: '$t(projectVersion.alternativeJustification)',
        background: '$t(projectVersion.background)',
        committeeNextMeeting: '$t(projectVersion.committeeNextMeeting)',
      },
      projectWork: {
        name: '$t(workDataRequest.name)',
        dateStart: '$t(workDataRequest.dateStart)',
        dateEnd: '$t(workDataRequest.dateEnd)',
        dateEndFact: '$t(workDataRequest.dateEndFact)',
        status: '$t(workDataRequest.statusId)',
        responsible: '$t(workDataRequest.responsible)',
        responsibleRole: '$t(workDataRequest.responsibleRoleId)',
      },
      projectBudgetSourceWeb: {
        r12Code: 'Код проекта в системе фин. планирования',
        cfoId: 'ЦФО',
        name: 'Название источника финансирования',
        'name mobile': 'Назв. источника финансирования',
        total: 'Бюджет проекта, $t(SUM_TITLE) (ОКВ)',
        typeId: 'Тип источника финансирования',
        isForInfoOnly: 'Источник внесен справочно для мониторинга',
      },
      projectVersion: {
        __sectionName: 'Общее',
        id: 'ID',
        name: 'Наименование карточки',
        type: '$t(PROJECT_TYPE_PROJECT)',
        limits: '$t(Input limits title)',
        perimeter: '$t(Input perimeter title)',
        geography: '$t(Input geography title)',
        segment: 'Бизнес единица заказчика',
        segmentExecutor: 'Бизнес единица исполнителя',
        isStrategicProject: 'Стратегический проект',
        analyst: 'Аналитик портфеля сегмента',
        analystCorporateOffice: 'Аналитик корпоративного офиса',
        financeController: 'Финансовый контролер',
        subscribers: 'Список наблюдателей',
        reportCloseTitle: 'Отчет при закрытии проекта',
        reportCloseGoal: 'Отчет о достижении целей проекта',
        reportCloseTask: 'Отчет о выполнении задач проекта',
        reportCloseGradeId: 'Оценка реализации',
        alternativeDoNothing: 'Что будет если ничего не делать?',
        alternativeImplementationMin: 'Описание варианта реализации в минимальном объеме',
        alternativeImplementation: 'Описание реализации в объеме больше минимального',
        alternativeJustification: 'Обоснование выбранного варианта реализации',
        background: '$t(Input background title)',
        stage: '$t(Input stage title)',
        projectClass: 'Класс',
        isKeyProject: 'Ключевой проект',
        isAgreeByMilestone: 'Согласование только контрольных точек',
        isInTop: 'Входит в $t(IN_TOP)',
        isUseTopSettings: 'Включить параметры для единовременного премирования',
        portfolioId: 'Портфель',
        programId: 'Программа',
        businessProcessId: 'Тип бизнес-процесса',
        budget: {
          __sectionName: 'Бюджет',
          budgetScaleId: 'Масштабность проекта',
          presentValue: 'Чистая приведенная стоимость (NPV), $t(SUM_TITLE) (без НДС)',
          presentValueMobile: 'Чистая привед. стоим. (NPV), $t(SUM_TITLE) (без НДС)',
          budgetAllSum: 'Общий планируемый бюджет, $t(SUM_TITLE) (без НДС)',
          budgetAllSumMobile: 'Общий планируемый бюджет, $t(SUM_TITLE) (без НДС)',
          dpp: 'DPP, лет',
          type: 'Тип бюджета',
          irr: 'Внутренняя норма доходности (IRR), %.',
          irrMobile: 'Внутр. норма доходности (IRR), %.',
          paybackPeriod: 'Срок окупаемости инвестиций (PP)',
          returnIndex: 'Индекс рент. инвестиций, (PI)',
          totalOkb: 'Суммарный Бюджет проекта, $t(SUM_TITLE) (ОКВ)',
          totalDds: 'Суммарный Бюджет проекта, $t(SUM_TITLE) (ДДС)',
          totalOkbMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ОКВ)',
          totalDdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ДДС)',
          budgetDirection: 'Направление бюджета',
          isOperationalProject: 'Операционный проект',
          oibda: {
            __sectionName: 'OIBDA, $t(SUM_TITLE)',
            year: 'Год',
            plan: 'План, $t(SUM_TITLE)',
            fact: 'Факт, $t(SUM_TITLE)',
          },
        },
        partiesConcerned: 'Заинтересованные стороны',
        partiesConcernedDescription: 'Стороны, на которые оказывает влияние данный проект',
        status: 'Статус',
        dateStart: '$t(Start date)',
        dateEnd: '$t(End date)',
        externalCustomer: 'Внешний заказчик',
        committeeNextMeeting: 'Дата следующего УК',
        committeeInterval: 'Интервал проведения УК',
        contractId: 'Номер договора',
      },
      budgetController: {
        postListBudget: {
          dataList: {
            name: '$t(projectBudgetSourceWeb.name)',
            total: '$t(projectBudgetSourceWeb.total)',
            r12Code: '$t(projectBudgetSourceWeb.r12Code)',
            cfoId: '$t(projectBudgetSourceWeb.cfoId)',
          }
        }
      },
      projectData: {
        name: '$t(Input name)',
      },
      projectStatusReport: {
        consolidateStatus: 'Сводный статус',
        consolidateComment: 'Комментарий к сводному статусу',
      },
      projectStatusReportSave: {
        consolidateComment: 'Комментарий сводного статуса',
        budgetComment: 'Комментарий бюджета',
        perimeterComment: 'Комментарий периметра',
        escalationComment: 'Комментарий эскалации',
        timings: {
          comment: 'Сроки, комментарий'
        },
        risk: {
          comment: 'Выполненные меры/Комментарий'
        },
        resources: {
          comment: 'Ресурсы, комментарий',
        },
      },
      menu: {
        kpi: 'КПЭ проекта',
        goal: 'Бизнес-кейс',
        initReport: 'Отчет по проекту'
      },
      projectLessonWeb: {
        description: 'Описание урока',
        recommendations: 'Рекомендации',
        knowledge: 'Область знаний',
        unit: 'В зоне ответственности подразделения',
      },
      workCopyRequest: {
        projectVersionIdFrom: 'Карточка источник',
        dateStart: 'Дата начала работ',
      },
      committeeProjectWeb: {
        draftDecision: {
          name: 'Пункт проекта решения'
        },
        assignments: {
          name: 'Текст поручения',
          protocolNumber: 'Номер протокола',
          responsible: 'Ответственный',
          controller: 'Контролер поручения',
          dateExecution: 'Дата исполнения',
        }
      },
      committeeProjectCancelWeb: {
        comment: 'Комментарий'
      },
      committeeWeb: {
        name: 'Номер ПК',
        typeId: 'Тип',
        dateStart: 'Дата',
        projectData: {
          orders: 'Выданы поручения',
          decisions: 'Приняты решения',
          notes: 'Отметили',
          opinions: 'Отражены особые мнения',
        }
      },
      businessCaseWeb: {
        stageId: 'Стадия проекта',
        typeId: 'Тип выгоды',
        indexId: 'Показатель',
        indexInput: 'Показатель',
        baseIndexDescription: 'Базовый показатель',
        amountBenefit: 'Плановый показатель',
        methodMeasurement: 'Метод измерения достижения выгоды',
        dateBenefit: 'Плановая дата получения выгоды',
        responsible: 'Ответственный за оценку',
        beneficiary: 'Бенефициар',
        statusId: 'Статус',
        beneficiarySegment: 'Бизнес единица бенефициара',
        statusEndDescription: 'Описание результата по итогам проведения оценки достижения',
        dateActualAssessment: 'Фактическая дата проведения оценки',
        noBenefitDescription: 'Описание причины отсутствия измеримых выгод',
        benefitDescription: 'Описание выгоды',
        categoryId: 'Категория выгод',
      },
      team: {
        LEADER_PROJECT: 'Руководитель',
        CUSTOMER: 'Согласующий от заказчика',
        TOP_MANAGER: 'Заказчик',
        CURATOR_PROJECT: 'Куратор',
        BENEFICIARY: 'Бенефициар',
      },
      ruzWeb: {
        project: 'Название карточки',
        roleId: 'Роль',
        monthYear: 'Месяц/Год загрузки',
        loadPercent: 'Загрузка, %',
        comment: 'Комментарий',
      },
      meetingUnscheduledRequest: {
        date: 'Предлагаемая дата проведения',
        agenda: 'Повестка заседания УК',
      },
      keyEventDto: {
        name: 'Ключевое событие на контроле',
        responsible: 'Ответственный',
        dateEnd: 'Контрольная дата',
        comment: 'Комментарий по событию, находящемуся на контроле',
        statusId: 'Событие',
        isExcludeReport: 'Не включать в отчет',
      },
      meetingReportDataDto: {
        budgetComment: 'Комментарий бюджета',
        work: {
          reportComment: 'Комментарий сроков',
        },
        kpi: {
          reportComment: 'Комментарий КПЭ',
        },
      },
      keyReportDto: {
        comment: 'Текущий статус',
      },
      projectAssignmentWeb: {
        committeeName: 'Номер ПК',
        projectName: 'Наименование карточки',
        committeeObjectId: 'Тип комитета',
        name: 'Поручение',
        protocolNumber: 'Номер протокола',
        comment: 'Комментарий',
        statusId: 'Статус',
        responsible: 'Ответственный',
        controller: 'Контролер поручения',
        dateExecutionInit: 'Первичная дата исполнения',
        dateExecution: 'Дата исполнения',
        dateExecutionFact: 'Фактическая дата исполнения',
        createUser: 'Автор поручения',
      },
      projectAgreementRequest: {
        comment: 'Комментарий',
      },
      projectRollbackRequest: {
        comment: 'Комментарий',
      },
      projectAgreeRequest: {
        comment: 'Комментарий',
      },
    }
  },
  PROGRAM: {
    translation: {
      'PROJECT_TYPE': 'PROGRAM',
      'base': 'program',
      'Name': 'Программа проекта',
      'Main title': 'Программы проектов',
      'Main title short': 'Программы',
      'Main title key': 'Программы ключевых проектов',
      'New title': 'Новая программа',
      'Search title': 'Поиск по названию или ID программы',
      'Search title mobile': 'Поиск по программам',
      'Input name': 'Наименование программы',
      'Create button': 'Создать программу',
      'Start date': 'Начало программы',
      'End date': 'Окончание программы',
      'Go to agreement': 'Отправить программу на согласование',
      'Input stage title': 'Стадия',
      'Input perimeter title': 'Периметр Программы проектов',
      'Input geography title': 'География программы',
      'Input manager title': 'Управление программой',
      'Input background title': 'Предпосылки программы и бизнес-потребности',
      'Input units title': 'Подразделения участвующие в программе',
      'Input provider title': 'Поставщик / Подрядчик / Вендор',
      'Link team load': 'Плановая загрузка команды на программе',
      'Agreement send first': 'Запрос на инициацию отправлен на согласование',
      'Agreement send second': 'Запрос на изменение отправлен на согласование',
      'Agreement send second section': 'Запрос на изменение разделов (%s) отправлен на согласование',
      'Agreement send close': 'Запрос на закрытие отправлен на согласование',
      'Agreement send close section': 'Запрос на закрытие (разделы %s) отправлен на согласование',
      'Agreement close project': 'Программа закрыта',
      'Agreement accept': 'Программа проекта согласована',
      'Agreement reject': 'Программа проекта отклонена',
      'Agreement rollback': 'Программа возвращена на доработку',
      'agreementToProjectLeader': 'Программа отправлена на согласование Секретарем ПК',
      projectVersionData: {
        name: 'Наименование программы проекта',
        budgetScaleId: 'Масштабность программы',
      },
      projectBudgetSourceWeb: {
        name: 'Название источника финансирования',
        'name mobile': 'Назв. источника финансирования',
        total: 'Бюджет программы проекта, $t(SUM_TITLE) (ОКВ)',
      },
      employeeDataCreate: {
        title: {
          loadFact: '$t(ruz.loadProgramTitle)',
          loadPlan: 'Плановая загрузка на программе, %',
        },
        load: {
          value: 'Плановая загрузка на программе, %'
        }
      },
      projectVersion: {
        type: '$t(PROJECT_TYPE_PROGRAM)',
        isStrategicProject: 'Стратегическая программа',
        reportCloseTitle: 'Отчет при закрытии программы',
        reportCloseGoal: 'Отчет о достижении целей программы',
        reportCloseTask: 'Отчет о выполнении задач программы',
        reportCloseGradeId: 'Оценка реализации',
        isKeyProject: 'Программа ключевых проектов',
        budget: {
          totalOkb: 'Суммарный бюджет программы проекта, $t(SUM_TITLE), без НДС (ОКВ)',
          totalDds: 'Суммарный бюджет программы проекта, $t(SUM_TITLE), с НДС (ДДС)',
          totalOkbMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ОКВ)',
          totalDdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ДДС)',
          budgetTotalOpex: 'Суммарный бюджет программы проекта, $t(SUM_TITLE), без НДС (OPEX)',
          budgetTotalFot: 'Суммарный бюджет программы проекта, $t(SUM_TITLE), без НДС (ФОТ)',
        },
        status: 'Статус',
        partiesConcernedDescription: 'Стороны, на которые оказывает влияние данная программа',
      },
      workDataRequest: {
        name_section_PROGRAM: 'График работ в Программах данной Программы',
        name_section_PROJECT: 'График работ в Проектах данной Программы',
        plannedCostComment: 'Суммарная стоимость всех блоков работ должна быть равна бюджету ОКВ данной программы',
      },
      menu: {
        kpi: 'КПЭ программы проектов',
        initReport: 'Отчет по программе проектов',
      },
    }
  },
  PORTFOLIO: {
    translation: {
      'PROJECT_TYPE': 'PORTFOLIO',
      'base': 'portfolio',
      'Name': 'Портфель проекта',
      'Main title': 'Портфели проектов',
      'Main title short': 'Портфели',
      'Main title key': 'Портфели ключевых проектов',
      'New title': 'Новый портфель',
      'Search title': 'Поиск по названию или ID портфеля',
      'Search title mobile': 'Поиск по портфелям',
      'Input name': 'Наименование портфеля',
      'Create button': 'Создать портфель',
      'Start date': 'Начало портфеля проекта',
      'End date': 'Окончание портфеля проекта',
      'Go to agreement': 'Отправить портфель на согласование',
      'Input stage title': 'Стадия',
      'Input perimeter title': 'Периметр портфеля проектов',
      'Input geography title': 'География портфеля проектов',
      'Input manager title': 'Управление портфелем проектов',
      'Input background title': 'Предпосылки портфеля и бизнес потребности',
      'Input units title': 'Подразделения участвующие в портфеле',
      'Input provider title': 'Поставщик / Подрядчик / Вендор',
      'Link team load': 'Плановая загрузка команды на портфеле',
      'Agreement send first': 'Запрос на инициацию отправлен на согласование',
      'Agreement send second': 'Запрос на изменение отправлен на согласование',
      'Agreement send second section': 'Запрос на изменение разделов (%s) отправлен на согласование',
      'Agreement send close': 'Запрос на закрытие отправлен на согласование',
      'Agreement send close section': 'Запрос на закрытие (разделы %s) отправлен на согласование',
      'Agreement close project': 'Портфель закрыт',
      'Agreement accept': 'Портфель проекта согласован',
      'Agreement reject': 'Портфель проекта отклонён',
      'Agreement rollback': 'Портфель возвращен на доработку',
      'agreementToProjectLeader': 'Портфель отправлен на согласование Секретарем ПК',
      projectVersionData: {
        name: 'Наименование портфеля проекта',
        budgetScaleId: 'Масштабность портфеля',
      },
      projectBudgetSourceWeb: {
        name: 'Название источника финансирования',
        'name mobile': 'Назв. источника финансирования',
        total: 'Бюджет портфеля проекта, $t(SUM_TITLE) (ОКВ)',
      },
      employeeDataCreate: {
        title: {
          loadFact: '$t(ruz.loadPortfolioTitle)',
          loadPlan: 'Плановая загрузка на портфеле, %',
        },
        load: {
          value: 'Плановая загрузка на портфеле, %'
        }
      },
      projectVersion: {
        type: '$t(PROJECT_TYPE_PORTFOLIO)',
        isStrategicProject: 'Стратегический портфель',
        reportCloseTitle: 'Отчет при закрытии портфеля',
        reportCloseGoal: 'Отчет о достижении целей портфеля',
        reportCloseTask: 'Отчет о выполнении задач портфеля',
        reportCloseGradeId: 'Оценка реализации',
        isKeyProject: 'Портфель ключевых проектов',
        budget: {
          totalOkb: 'Суммарный бюджет портфеля проекта, $t(SUM_TITLE), без НДС (ОКВ)',
          totalDds: 'Суммарный бюджет портфеля проекта, $t(SUM_TITLE), с НДС (ДДС)',
          totalOkbMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ОКВ)',
          totalDdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE) (ДДС)',
          budgetTotalOpex: 'Суммарный бюджет портфеля проекта, $t(SUM_TITLE), без НДС (OPEX)',
          budgetTotalFot: 'Суммарный бюджет портфеля проекта, $t(SUM_TITLE), без НДС (ФОТ)',
        },
        partiesConcernedDescription: 'Стороны, на которые оказывает влияние данный портфель',
        status: 'Статус',
      },
      workDataRequest: {
        name_section_PROJECT: 'График работ в Проектах данного Портфеля',
        name_section_PROGRAM: 'График работ в Программах данного Портфеля',
        plannedCostComment: 'Суммарная стоимость всех блоков работ должна быть равна бюджету ОКВ данного портфеля',
      },
      menu: {
        kpi: 'КПЭ портфеля проектов',
        initReport: 'Отчет по портфелю проектов',
      },
    }
  },
}