import React, { useEffect, useState } from "react";
import { useAppSelector } from '@/store';
import { Modal } from "react-bootstrap";
import ButtonRt from "@/components/Helper/ButtonRt";
import services from "@/services";
import { cloneDeep, isEmpty } from "lodash";
import Checkbox from "@/elements/Checkbox";
import { useCheckRight } from "@/utils/hooks";
import { EDIT_PROJECT_STATUSES, ProjectSection, UserRight } from '@/config/const';
import { getDictObj } from "@/utils";
import { getBasicNewProjectData } from "@/actions/getBasicNewProjectData";
import { useDispatch } from "react-redux";
import { SectionDependency } from '@/pages/CreateProject/Agreement/SendToAgree';
import { RollbackIcon } from '@/pages/CreateProject/BasicNav/Icon';

const rollbackInfo = (projectVersionId) => services.get(`/project/rollbackEditVersion/${projectVersionId}/info`, undefined, { isShowLoad: true });
const rollbackSend = (projectVersionId, data) => services.post(`/project/rollbackEditVersion/${projectVersionId}`, data);

interface IEditRollbackProps {
  projectVersionId: number;
}

const EditRollback = ({ projectVersionId }: IEditRollbackProps) => {
  const dispatch = useDispatch();
  const checkRight = useCheckRight();

  const [acceptCheck, setAcceptCheck] = useState(false);

  const [projectStatusId, isProjectEdit, version, isWorksDisabled, sectionDict, statusDict] = useAppSelector(state => [
    state.NewProject.newProjectData.statusId,
    state.NewProject.newProjectData.edit,
    state.NewProject.newProjectData.version,
    state.NewProject.newProjectData.isWorksDisabled,
    state.dict.agreeSection,
    state.dict.status,
  ]);

  const [isShow, setIsShow] = useState(false);
  const [sections, setSections] = useState([] as ICheckedDictItem[]);
  const [changedSections, setChangedSections] = useState([] as string[]);
  const [sectionDeps, setSectionDeps] = useState({} as SectionDependency);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const isAllChecked = !isEmpty(sections) && sections.every(item => item.checked);
  const isAnyChecked = sections.some(item => item.checked);
  const isShowButton = isProjectEdit && checkRight(UserRight.EDIT_VERSION_ROLLBACK) && version > 1
    && EDIT_PROJECT_STATUSES.includes(getDictObj(statusDict, projectStatusId)?.code);

  useEffect(() => {
    setSections(sectionDict.data.filter(section => !isWorksDisabled || section.code !== ProjectSection.WORKS));
  }, [sectionDict.data, isWorksDisabled]);

  useEffect(() => {
    if (isAllChecked !== isCheckAll) {
      setIsCheckAll(isAllChecked);
    }
  }, [isAllChecked]);

  useEffect(() => {
    if (isAllChecked !== isCheckAll) {
      checkAll(isCheckAll);
    }
  }, [isCheckAll]);

  const check = (code: string) => {
    const newSections = cloneDeep(sections);
    const checkedSection = newSections.find(item => item.code === code);
    checkedSection.checked = !checkedSection.checked;

    if (checkedSection.checked) {
      sectionDeps[checkedSection.code]?.forEach(depCode => {
        const depSection = newSections.find(item => item.code === depCode);
        depSection.checked = true;
      });
    } else {
      Object.entries(sectionDeps).forEach(([key, value]) => {
        if (value.includes(checkedSection.code)) {
          const reverseDepSection = newSections.find(item => item.code === key);
          reverseDepSection.checked = false;
        }
      });
    }

    setSections(newSections);
  }

  const checkAll = (isCheck: boolean) => {
    const newSections = cloneDeep(sections);
    newSections.forEach(item => {
      item.checked = isCheck;
    });
    setSections(newSections);
  }

  const open = () => {
    setIsShow(true);
  }
  const hide = () => setIsShow(false);

  const prepareAgreeData = () => ({
    sections: sections.filter(item => item.checked).map(item => item.code),
  });

  const send = async () => {
    await rollbackSend(projectVersionId, prepareAgreeData());
    checkAll(false);
    dispatch(getBasicNewProjectData(projectVersionId));
    hide();
  }

  const onClick = async () => {
    return rollbackInfo(projectVersionId).then(info => {
      setChangedSections(info.changeSections);
      setSectionDeps(info.dependencies);
      setSections(prevState => {
        return prevState.map(section => ({...section, checked: info?.changeSections?.includes(section.code) }))
      });
      open();
    });
  }

  return (
    <>
      {isShowButton && (
        <button className="rollback-button" onClick={onClick} title='Вернуть к исходному состоянию'>
          <RollbackIcon className="rollback-icon reverted"/>
        </button>
      )}
      <Modal
        onHide={hide}
        show={isShow}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="h3">Выберите разделы карточки, которые бы Вы хотели вернуть к состоянию согласованной версии</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <Checkbox onChange={() => setIsCheckAll(prev => !prev)}
                      checked={isCheckAll} >
              Все разделы
            </Checkbox>
            {sections.map(section => (
              <Checkbox key={section.id}
                        onChange={() => check(section.code)}
                        checked={section.checked} >
                {section.name}{changedSections.includes(section.code) && <span className='green'> (есть изменения)</span>}
              </Checkbox>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Checkbox
            checked={acceptCheck}
            onChange={() => setAcceptCheck(prev => !prev)}
            className='rollback_warning_message'
          >
            Я понимаю, что после нажатия на кнопку «Подтвердить» информация в выбранных разделах будет возвращена к состоянию согласованной версии без возможности восстановления.
          </Checkbox>
          <div className="mb-3 form-group button-felix-group">
            <ButtonRt
              onClick={send}
              disabled={!isAnyChecked || !acceptCheck}
              type='outline'
              className="danger"
            >
              Подтвердить
            </ButtonRt>
            <ButtonRt onClick={hide} type="outline">Отмена</ButtonRt>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditRollback;