import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

const SPLIT_TYPE = "SPLIT_TYPE";

const useSplit = (defaultPercent = 0.5) => {
  const [splitPercent, setSplitPercent] = useState(defaultPercent);

  const [, dragRef] = useDrag(() => ({
    type: SPLIT_TYPE,
  }), []);

  const containerRef = useRef(null);
  const [, dropRef] = useDrop(
    () => ({
      accept: SPLIT_TYPE,
      drop: (_, monitor) => {
        const { x, width } = containerRef.current.getBoundingClientRect();
        const offset = monitor.getClientOffset().x - x;
        setSplitPercent(offset / width);
      }
    }), []);

  dropRef(containerRef);

  return {
    splitPercent,
    setSplitPercent,
    dropRef: containerRef,
    dragRef,
  };
};

export default useSplit;