import React from 'react';
import { FavoriteIcon } from '@/pages/CreateProject/BasicNav/Icon';
import { useAppDispatch, useAppSelector } from '@/store';
import cx from 'classnames';
import service from '@/services';
import { loadUserFavoriteProjects } from '@/actions/user';

const ButtonFavorite = ({ className, projectId }) => {
  const dispatch = useAppDispatch();
  const favoriteProjects = useAppSelector(state => state.Login.favorite.projects);
  const isActive = favoriteProjects.includes(projectId);

  const onClick = (e) => {
    e.preventDefault();
    return service.get(`/user/favorite/project/${projectId}`)
      .then(() => dispatch(loadUserFavoriteProjects()));
  }

  return (
    <button
      onClick={onClick}
      className={cx(className, { active: isActive })}
      title={isActive ? 'Убрать из избранного' : 'Добавить в избранное'}>
      <FavoriteIcon/>
    </button>
  );
}

export default ButtonFavorite;