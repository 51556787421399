import i18n from "i18next";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateName } from '@/actions/updateName';
import PageTitle from '@/components/PageTitle';
import { PROJECT_NAME_TITLE, ProjectStatus } from '@/config/const';
import Input from '@/elements/Input';
import BasicNav from '@/pages/CreateProject/BasicNav';
import { EditIcon } from '@/pages/CreateProject/BasicNav/Icon';
import { useAppSelector } from '@/store';
import { getDictObj } from "@/utils";
import VersionFormModal from "@/pages/CreateProject/Version/VersionFormModal";
import HeaderLinks from '@/components/ProjectHeader/HeaderLinks';

const ProjectHeader = (props) => {
  const [visible, setVisible] = useState(true);
  const statusDict = useAppSelector(state => state.dict.status);

  const { name, statusId, version, projectTypeName } = props.newProjectData;

  const {
    title, edit, updateProjectName,
    saveName, returnChangeName, secondTitle,
    hideFooter = false, titleLink, versionSection
  } = props;

  const pageTitle = `${PROJECT_NAME_TITLE} - ${[name, title, secondTitle].filter(v => v).join(' - ')}`;
  const statusCode = ProjectStatus[getDictObj(statusDict, statusId)?.code];

  const editTitle = (e) => {
    e.preventDefault();
    setVisible(false);
    returnChangeName();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const newName = e.target.value;
    updateProjectName(newName);
  };

  useEffect(() => {
    if (saveName) {
      setVisible(true);
    };
  }, [saveName]);

  // const updateNameProject = (e) => {
  //   const { newProjectData, updateProject } = props;
  //   if (e.key === 'Enter') {
  //     updateProject(newProjectData);
  //     setVisible(true);
  //   }
  // };

  const projectName = projectTypeName ? projectTypeName + ' «' + name + '»' : '';

  const editPosibility =
    edit && visible ? (
      <div className="svg_button_edit" onClick={editTitle}>
        <EditIcon />
      </div>
    ) : null;

  return (
    <React.Fragment>
      <div className="new_project_title mb-3 mt-3">
        {visible ? (
          <PageTitle className="m-0" pageTitle={pageTitle}>
            {titleLink ? (
              <Link to={titleLink}>
                {title ? title : projectName}
              </Link>
            ) : (title ? title : projectName)
            }
          </PageTitle>
        ) : (
          <div className="width100">
            <Input
              label={i18n.t('Input name')}
              value={name}
              name="nameProject"
              onChange={handleChange}
              titleClassName="mb-0"
            />
          </div>
        )}
        {editPosibility}
      </div>
      {!hideFooter && (
        <>
          <div className='new_project_title_footer'>
            <div className="new_project_indicator">
              <HeaderLinks hideExternalLinks={props.hideExternalLinks} section={versionSection}/>
            </div>
            {versionSection && (version > 1 || [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].includes(statusCode)) && (
              <div className='new_project_indicator'>
                <div className="version-section-link item">
                  <VersionFormModal section={versionSection}/>
                </div>
              </div>
            )}
          </div>
          <div className="creacte-project-nav-mobile">
            <BasicNav />
          </div>
        </>
      )}
      <hr className="new_project_title_separator" />
    </React.Fragment>
  );
};
const mapDispatchToProps = {
  updateProjectName: updateName,
};

const mapStateToProps = (state) => ({
  newProjectData: state.NewProject.newProjectData,
  dict: state.dict
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeader);
