export const LOGIN_IS_KEY_PROJECT_SCREEN = 'LOGIN_IS_KEY_PROJECT_SCREEN';

export const initialState = {
  currentUser: ({} as UserWeb),
  isUserLoad: false,
  isAuth: true,
  notifyCount: 0,
  pendingAgreeCount: 0,
  committeeCount: 0,
  agreeCount: 0,
  agreeByRoleCount: 0,
  isKeyProjectScreen: undefined,
  userCheckedRelease: 1,
  userCheckedVideoLesson: 1,
  favorite: {
    projects: [],
  }
};

export const Login = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        currentUser: action.payload,
        isKeyProjectScreen: action.payload.rightCodes.includes('KEY_PROJECT_SCREEN_ON'),
        isAuth: true,
        isUserLoad: true,
      };
    case 'LOGOUT_USER':
      return { ...state, currentUser: {}, isAuth: false };
    case LOGIN_IS_KEY_PROJECT_SCREEN:
      return { ...state, isKeyProjectScreen: action.payload };
    case 'UPDATE_USER_INFO':
      return {
        ...state,
        notifyCount: action.payload.notifyCount,
        pendingAgreeCount: action.payload.pendingAgreeCount,
        committeeCount: action.payload.committeeCount,
        agreeCount: action.payload.pendingAgreeCount + action.payload.committeeCount,
        agreeByRoleCount: action.payload.agreeByRoleCount,
        userCheckedRelease: action.payload.userCheckedRelease,
        userCheckedVideoLesson: action.payload.userCheckedVideoLesson
      };
    case 'UPDATE_FAVORITE_PROJECTS':
      return {
        ...state,
        favorite: {
          ...state.favorite,
          projects: action.payload,
        }
      }
    default:
      return state;
  }
};
