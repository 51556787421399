import i18n from "i18next";
import { dictTreeToDict, formatDate, forrmattedNum, getDict, getPaybackPeriodStr, getRound, importExt } from '@/utils';
import { AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';
import { get } from 'lodash';

const prepareResult = (self, data): any[] => {
  const result = [];

  const projectTypeCode = data.projectTypeCode;

  result.push({ key: 'budgetTypeId', title: i18n.t('projectVersion.budget.type'), data: getDict(self.props.dict.budgetType, data.budgetTypeId) });
  result.push({ key: 'budgetDirectionId', title: i18n.t('projectVersion.budget.budgetDirection'), data: getDict(dictTreeToDict(self.props.dict.budgetDirection), data.budgetDirectionId) });
  result.push({ key: 'budgetAllSum', title: i18n.t('projectVersion.budget.budgetAllSum'), data: getRound(data.budgetAllSum) });

  if (projectTypeCode !== 'PROJECT') {
    result.push({ key: 'totalOkb', title: `Суммарный бюджет, ${i18n.t('SUM_TITLE')} (ОКВ)`, data: data.totalOkb });
    result.push({ key: 'totalDds', title: `Суммарный бюджет, ${i18n.t('SUM_TITLE')} (ДДС)`, data: data.totalDds });
    result.push({ key: 'totalOpex', title: `Суммарный бюджет, ${i18n.t('SUM_TITLE')} (OPEX)`, data: data.totalOpex });
    result.push({ key: 'totalFot', title: `Суммарный бюджет, ${i18n.t('SUM_TITLE')} (ФОТ)`, data: data.totalFot });
  }

  if (['PROJECT', 'PROGRAM'].includes(projectTypeCode)) {
    result.push({ key: 'presentValue', title: i18n.t('projectVersion.budget.presentValue'), data: `${getRound(data.presentValue)} ${i18n.t('SUM_TITLE')}` });
    result.push({ key: 'irr', title: i18n.t('projectVersion.budget.irr'), data: `${data.irr}%` });
    result.push({ key: 'paybackPeriod', title: i18n.t('projectVersion.budget.paybackPeriod'), data: getPaybackPeriodStr(data.paybackPeriod) });
    result.push({ key: 'returnIndex', title: i18n.t('projectVersion.budget.returnIndex'), data: data.returnIndex });
  }

  result.push({ key: 'dpp', title: i18n.t('projectVersion.budget.dpp'), data: forrmattedNum(data.dpp) });
  result.push({ key: 'budgetScaleId', title: i18n.t('projectVersion.budget.budgetScaleId'), data: getDict(self.props.dict.scale, data.budgetScaleId) });

  return result;
}

const AgreementBudgetBlock = async (self) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/budgetExt')
    .then(ext => ext?.(self)) as AgreementBlockExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data);
        result = extModule?.updateResult(data, result) || result;
        return result;
      }
    }
  ]
}

export default AgreementBudgetBlock;
