import { getDictCodeById, importExt } from '@/utils';
import ButtonRtAccept from '@/components/Helper/ButtonRtAccept';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { ProjectStage, ProjectStatus } from '@/config/const';

export interface IStatusHelperProps {
  dict: Dict;
}

export interface IStatusHelper {
  hide: { [key: string]: boolean };
  getCreateReportButton: (projectData: Project, checkRight: (right: string) => boolean, onClickCreateReport: () => void) => JSX.Element;
}

export interface IStatusHelperExt {
  hide?: { [key: string]: boolean };
  getCreateReportButton?: (projectData: Project, checkRight: (right: string) => boolean, onClickCreateReport: () => void) => JSX.Element;
}

const createStatusHelper = async (props: IStatusHelperProps, setter: (helper: IStatusHelper) => void) => {
  const helperExt = await importExt('pages/CreateProject/Status/statusHelperExt').then(ext => ext?.(props)) as IStatusHelperExt;

  const { dict } = props;

  const getCreateReportButton = (projectData: Project, checkRight: (right: string) => boolean,
    onClickCreateReport: () => void) => {
    const projectStatusCode = ProjectStatus[getDictCodeById(dict.status, projectData.statusId)];
    const isProgram = i18n.t('PROJECT_TYPE') === 'PROGRAM';
    const projectStageCode = ProjectStage[getDictCodeById(dict.stage, projectData.stageId)];
    const { isInTop, isUseTopSettings } = projectData;
    const isInTopOrUseTopSettings = isInTop || isUseTopSettings;
    if (projectStatusCode === ProjectStatus.RELEASE && (projectStageCode !== ProjectStage.ASSESSMENT_BENEFITS || isProgram)) {
      if (checkRight('REPORT_CREATE') || (checkRight('REPORT_CREATE_TOP') && isInTopOrUseTopSettings)) {
        return (
          <ButtonRtAccept
            className="mb-2 mini"
            onClick={onClickCreateReport}
            message="Вы действительно хотите сформировать внеплановый статус-отчет?"
          >
            Сформировать внеплановый статус-отчет
          </ButtonRtAccept>
        )
      }
    }
  }

  const helper = {
    hide: {

    },
    getCreateReportButton,
  }

  setter({
    ...helper,
    ...helperExt,
    hide: {
      ...helper.hide,
      ...helperExt.hide,
    }
  });
}

export const useProjectStatusHelper = (props: IStatusHelperProps): IStatusHelper => {
  const [helper, setHelper] = useState<IStatusHelper>(null);

  useEffect(() => {
    createStatusHelper(props, setHelper);
  }, []);

  return helper;
}