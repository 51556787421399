import cx from 'classnames';
import i18n from "i18next";
import { isEmpty, isFunction, uniqBy } from 'lodash';
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DocBlock from '@/components/Doc';
import SaveCancel from '@/components/Helper/SaveCancel';
import UserSearch from "@/components/UserSearch";
import {
  FORMAT_DATE_HUMAN,
  FORMAT_DATE_SERVER,
  ProjectStatus,
  UserRight
} from "@/config/const";
import InputNum from "@/elements/Input/InputNum";
import Select from "@/elements/Select";
import TextArea from "@/elements/TextArea";
import ProgressForm from '@/pages/CreateProject/Blocks/Components/ProgressForm';
import ResponsibleForm from '@/pages/CreateProject/Blocks/Components/ResponsibleForm';
import TimingForm from '@/pages/CreateProject/Blocks/Components/TimingForm';
import { checkGroup, checkMilestone, checkTask } from '@/pages/CreateProject/Blocks/utils';
import {
  formatDateWithFormat,
  getDictByCode,
  getDictForSelect,
  getDictObj, getProjectRoleOptionsList,
} from "@/utils";
import { checkRight as createCheckRight } from '@/utils';
import { useAppSelector } from '@/store';
import services from "@/services";
import UrlsForm from "@/pages/CreateProject/Blocks/Components/UrlsForm";
import { useDispatch } from "react-redux";
import { updateRequiredSections } from "@/actions/required/updateRequiredSections";
import EditItemLink from '@/components/Gantt/components/EditItemLink';
import { useClockManager } from '@/utils/clockmanager';
import EditBlockTopFields from '@/pages/CreateProject/Blocks/Components/EditBlockTopFields';

const getWorkUrls = (workId) => services.get(`/work/urls/${workId}`);

export type EditBlockViewProps = {
  data: Work | GanttTableItem;
  setData: any;
  links: GanttTableLink[];
  setLinks: any;
  isEdit: boolean,
  children?: any;
  projectVersionId: number;
  docs?: any;
  docsAfterRemove?: any;
  getAllWork: Work[];
  onCancel: any;
  onDeleteTask: any;
  isDeleteDisabled?: boolean;
  onSave: any;
  afterSave?: any;
  isEditBudgetPredictionRight?: boolean;
  isFromGantt?: boolean;
  readonly?: boolean;
  linkReadonly?: boolean;
  isOnlyLinkEdit?: boolean;
  funcRef?: any;
  shadowSave?: any;
  milestoneView?: boolean;
};

const EditBlockView = ({
  data,
  setData,
  links,
  setLinks,
  isEdit,
  children = undefined,
  projectVersionId,
  docs = undefined,
  docsAfterRemove = undefined,
  getAllWork,
  onCancel,
  onDeleteTask,
  isDeleteDisabled = false,
  onSave,
  isEditBudgetPredictionRight = false,
  isFromGantt = false,
  readonly = false,
  linkReadonly = false,
  funcRef = undefined,
  shadowSave = undefined,
  milestoneView = false
}: EditBlockViewProps) => {
  const clockManager = useClockManager();
  const [dict, newProjectData, checkRight, curUser, isRequiredAccent] = useAppSelector(state => [
    state.dict,
    state.NewProject.newProjectData,
    createCheckRight(state),
    state.Login.currentUser,
    state.Required[projectVersionId]?.accent,
  ]);
  const [accentRequiredOnSave, setAccentRequiredOnSave] = useState(false);
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);

  const staticId = data.workId ? +data.workId : undefined;
  const baseGroupId = getAllWork.find(item => item.isBaseGroup)?.id;

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (!staticId || !isEmpty(urls)) {
      return;
    }

    getWorkUrls(staticId).then(u => setUrls(u.concat(data.urlList?.filter(url => !url.id) || [])));
  }, [staticId]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      urlList: urls,
    }));
  }, [urls]);

  useEffect(() => {
    if (funcRef && !funcRef.current) {
      funcRef.current = {
        setIsDirty: (isDirty) => setIsDirty(isDirty)
      }
    }
  }, [funcRef]);

  useEffect(() => {
    if ((!isEdit || data.id) && !data.workGroupId && !data.isBaseGroup && baseGroupId) {
      setData(prevState => ({
        ...prevState,
        workGroupId: baseGroupId,
      }));
    }
  }, [data.workGroupId, baseGroupId, isEdit, data.id])

  const handleChangeSelect = (key: string, value: any, updateIsDirty = true) => {
    if (updateIsDirty) {
      setIsDirty(true);
    }
    setData((prevState) => ({
      ...prevState,
      [key]: isFunction(value) ? value(prevState[key]) : value
    }));
  };

  const handleResponsibleListChange = (key: string, value: any, updateIsDirty = true) => {
    if (updateIsDirty) {
      setIsDirty(true);
    }
    setData(prevState => {
      const toChange = prevState.responsibleList || {};
      toChange[key] = isFunction(value) ? value(toChange[key] ?? []) : value;
      return {...prevState, responsibleList: toChange}
    });
  }

  const handleChangeSelectInputNum = (key: string, value: any, source: any) =>
    handleChangeSelect(key, value, !(source?.source === 'prop'));

  const getWorkStatus = (work) => {
    return getDictObj(dict.workStatus, work.statusId)?.code;
  };

  const updateData = (newData) => setData(prevData => ({
    ...prevData,
    ...newData
  }));

  const projectTypeCode = getDictObj(dict.projectTypes, newProjectData.typeId)?.code;

  const isProjectActive = getDictByCode(dict.status, ProjectStatus.RELEASE)?.id === newProjectData?.statusId;
  const isCurUserResponsible = data.responsible?.id === curUser.id
    || data.responsible?.id === curUser.deputyHost?.id;
  const isEditWorkProgressActiveRight = checkRight(UserRight.EDIT_WORK_PROGRESS_RELEASE);
  const isEditUrlsRight = checkRight(UserRight.EDIT_WORK_URL);
  const isEditRight = newProjectData.edit && checkRight(UserRight.EDIT_PROJECT);
  const isEditReleaseRight = isProjectActive && checkRight(UserRight.WORK_EDIT_IN_RELEASE);
  const isCanSave = !readonly && (isEditRight || isEditReleaseRight || isEditBudgetPredictionRight
    || isEditWorkProgressActiveRight || isEditUrlsRight || isCurUserResponsible);

  const getTypeProjectOptionsListParentWork = () => {
    const groups = getAllWork
      .filter(w => w.projectVersionId === projectVersionId)
      .filter(w => w.id !== staticId && w.id !== data.id)
      .filter(w => checkGroup(w))
      .map((w) => ({...w, label: `${w.rowNum} ${w.name}`, value: w.id}));

    return uniqBy(groups, 'value');
  };

  const now = moment();
  const dateEndOrNow = data.dateEnd && moment(data.dateEnd).isSameOrBefore(now) ? moment(data.dateEnd) : now;
  const isGroup = checkGroup(data);
  const isMilestone = checkMilestone(data);
  const isTask = checkTask(data);

  const redRequired = isRequiredAccent ? 'red' : 'default';
  const accentTableOnSave = isRequiredAccent || accentRequiredOnSave ? 'red' : 'default';
  const isAgreeByMilestone = newProjectData.isAgreeByMilestone;

  return (
    <>
      <div className="wrapper-option">
        <div className="form-felix">
          <div className="marginBottom-20">
            <EditBlockTopFields
              data={data}
              isFromGantt={isFromGantt}
              required={redRequired}
              handleChangeSelect={handleChangeSelect}
              readonly={readonly}
              isEdit={isEdit}
              allWorks={getAllWork}
              milestoneView={milestoneView}
            />

            {!milestoneView && <Select
              options={getTypeProjectOptionsListParentWork()}
              label={i18n.t('workDataRequest.workGroupId')}
              value={data.workGroupId}
              onChange={(val) => handleChangeSelect('workGroupId', val)}
              disabled={isProjectActive || readonly}
            />}

            {isTask && data.workGroupId && (
              <InputNum
                tooltip
                label={i18n.t('workDataRequest.weight')}
                value={data.weight}
                onChange={(val, source) => handleChangeSelectInputNum('weight', val, source)}
                disabled={!isEditRight || readonly}
                max={100}
                min={0}
              />
            )}

            {(isTask || isMilestone) && !data.isExtreme && (
              <Fragment>
                <UserSearch
                  tooltip
                  required={redRequired}
                  onChange={val => handleChangeSelect('responsible', val)}
                  value={data.responsible}
                  label={i18n.t('workDataRequest.responsible')}
                  disabled={isProjectActive || readonly}
                />

                <Select
                  required={redRequired}
                  options={getProjectRoleOptionsList(dict.roles, projectTypeCode)}
                  label={i18n.t('workDataRequest.responsibleRoleId')}
                  value={data.responsibleRoleId}
                  onChange={(val) => handleChangeSelect('responsibleRoleId', val)}
                  disabled={isProjectActive || readonly}
                />
              </Fragment>
            )}

            <Select
              required={!isGroup && redRequired}
              options={getDictForSelect(dict.workStatus,
                  item => item.name, item => (!isMilestone && item.isWork) || (isMilestone && item.isMilestone))}
              label={i18n.t('workDataRequest.statusId')}
              value={data.statusId}
              name="statusId"
              onChange={(val) => handleChangeSelect('statusId', val, !isGroup)}
              description={data.dateUpdateStatus ? `Последнее изменение статуса ${formatDateWithFormat(data.dateUpdateStatus, FORMAT_DATE_HUMAN)}` : null}
              disabled={isGroup}
            />

            {isTask && (
              <TextArea label={i18n.t('workDataRequest.comment')}
                        tooltip
                        value={data.comment}
                        onChange={e => handleChangeSelect('comment', e.target.value)}
                        disabled={readonly}
              />
            )}
          </div>
        </div>
      </div>

      {isTask && (
        <ResponsibleForm
          required={accentTableOnSave}
          data={data.responsibleList}
          dataMainTable={data.responsibleListMain}
          setData={handleResponsibleListChange}
          setDataMainTable={val => handleChangeSelect('responsibleListMain', val)}
          isWithLoad={data.responsibleIsWithLoad}
          setIsWithLoad={val => handleChangeSelect('responsibleIsWithLoad', val)}
          isFromGantt={isFromGantt}
          readonly={readonly}
          isEdit={isEdit}
          shadowSave={shadowSave}
          linkDate={dateEndOrNow.startOf('week').format(FORMAT_DATE_SERVER)}
          projectId={newProjectData.projectId}
        />
      )}

      {!data.isExtreme && !milestoneView && <div className="wrapper-option">
        <div className="form-felix">
          <h3 className={cx('h3-felix', 'workList__contentTitle')}>Связи</h3>
          <EditItemLink
            required={accentTableOnSave}
            data={getAllWork}
            links={links}
            curWorkId={data.id}
            curWorkProjectId={data.projectId}
            setLinks={setLinks}
            readonly={linkReadonly || readonly || !isEditRight}
            projectId={newProjectData.projectId}
          />
        </div>
      </div>}

      {children}

      <div className="wrapper-option">
        <div className="form-felix">
          <TimingForm
            required={redRequired}
            data={data}
            setData={updateData}
            isProjectActive={isProjectActive}
            workStatusCode={getWorkStatus(data)}
            handleChangeSelect={handleChangeSelect}
            projectDateEnd={newProjectData.dateEnd}
            projectDateStart={newProjectData.dateStart}
            updateIsDirty={(isDirty) => setIsDirty(isDirty)}
            milestoneView={milestoneView}
          />
          {isTask && (
            <ProgressForm
              required={redRequired}
              data={data}
              setData={handleChangeSelect}
              disabled={isProjectActive && (!isEditWorkProgressActiveRight && !isCurUserResponsible)}
            />
          )}
        </div>
      </div>

      {isTask && (
        <UrlsForm urls={urls}
                  setUrls={setUrls}
                  isEdit={isEditUrlsRight || isCurUserResponsible}
                  updateIsDirty={(isDirty) => setIsDirty(isDirty)}
        />
      )}

      <div className="wrapper-option">
        <div className="form-felix">
          {isEdit && (isTask || isMilestone) && (
            <DocBlock
              tooltip
              isEdit={true}
              typeCode="WORK"
              ref={docs}
              projectId={projectVersionId}
              otherId={staticId}
              isAcceptDelete={isEditRight && isEdit}
              isRejectRemove={readonly || (!isEditRight && !isEditBudgetPredictionRight)}
              afterRemove={docsAfterRemove}
              copyFileToRelease={isAgreeByMilestone}
            />
          )}

          {data.dateUpdate && (
            <div className='work-date-update-title'>Последние
              изменения: {data.userUpdate?.displayName} {clockManager.formatDateTime(data.dateUpdate)}</div>
          )}
          <SaveCancel save={isCanSave ? async () => {
            setAccentRequiredOnSave(true);
            await onSave().finally(() => dispatch(updateRequiredSections(projectVersionId)));
          } : null}
                      cancel={onCancel}
                      remove={((!isEdit && !isFromGantt) || isProjectActive || !isEditRight) || readonly || isDeleteDisabled
                        ? null : onDeleteTask}
                      isDirty={isCanSave && isDirty && !isFromGantt}
                      setIsDirty={(isDirty) => setIsDirty(isDirty)}
          />
        </div>
      </div>
    </>
  );
};

export default EditBlockView;