import i18n from "i18next";
import { get } from 'lodash';
import { dictTreeToDict, formatDate, getDict, importExt } from '@/utils';
import { AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';

const prepareResult = (self, data): any[] => {
  const result = [];

  const projectTypeCode = data.projectTypeCode;

  result.push({ key: 'name', title: i18n.t('Input name'), data: data.name });
  result.push({ key: 'stageId', title: i18n.t('Input stage title'), data: getDict(self.props.dict.stage, data.stageId) });
  result.push({ key: 'typeId', title: i18n.t('projectVersion.type'), data: getDict(self.props.dict.projectTypes, data.typeId) });
  result.push({ key: 'dateStart', title: i18n.t('Start date'), data: formatDate(data.dateStart) });
  result.push({ key: 'dateEnd', title: i18n.t('End date'), data: formatDate(data.dateEnd) });
  result.push({ key: 'segmentId', title: i18n.t('projectVersion.segment'), data: getDict(dictTreeToDict(self.props.dict.segment), data.segmentId) });
  result.push({ key: 'externalCustomer', title: i18n.t('projectVersion.externalCustomer'), data: data.externalCustomer?.label });
  result.push({ key: 'segmentExecutorId', title: i18n.t('projectVersion.segmentExecutor'), data: getDict(dictTreeToDict(self.props.dict.segment), data.segmentExecutorId) });

  if (['PROJECT', 'PROGRAM'].includes(projectTypeCode)) {
    result.push({ key: 'parentPortfolioName', title: i18n.t('projectVersion.portfolioId'), data: data.parentPortfolioName });
  }

  if (['PROJECT'].includes(projectTypeCode)) {
    result.push({ key: 'parentProgramName', title: i18n.t('projectVersion.programId'), data: data.parentProgramName });
  }

  result.push({ key: 'perimeter', title: i18n.t('Input perimeter title'), data: data.perimeter });
  result.push({ key: 'managerId', title: i18n.t('Input manager title'), data: getDict(self.props.dict.manager, data.managerId) });
  result.push({ key: 'geography', title: i18n.t('Input geography title'), data: data.geography });
  result.push({ key: 'limits', title: i18n.t('Input limits title'), data: data.limits });
  result.push({ key: 'partiesConcerned', title: i18n.t('projectVersion.partiesConcerned'), data: data.partiesConcerned });
  result.push({ key: 'analystCorporateOffice', title: i18n.t('projectVersion.analystCorporateOffice'), data: get(data, 'analystCorporateOffice.displayName') });
  result.push({ key: 'analyst', title: i18n.t('projectVersion.analyst'), data: get(data, 'analyst.displayName') });
  result.push({ key: 'financeController', title: i18n.t('projectVersion.financeController'), data: get(data, 'financeController.displayName') });
  result.push({ key: 'contractName', title: i18n.t('projectVersion.contractId'), data: data.contractName });
  result.push({ key: 'isAgreeByMilestone', title: i18n.t('projectVersion.isAgreeByMilestone'), data: data.isAgreeByMilestone ? 'Да' : 'Нет' });

  return result;
}

const AgreementGeneralBlock = async (self) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/generalExt')
    .then(ext => ext?.(self)) as AgreementBlockExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data);
        result = extModule?.updateResult(data, result) || result;
        return result;
      }
    }
  ]
};

export default AgreementGeneralBlock;
