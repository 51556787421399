import i18n from "i18next";

import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import { dictTreeToDict, formatDate, formatDays, getDict, getDictObj, importExt } from '@/utils';
import { AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';

const prepareResult = (self: any, data: any): any[] => {
  if (!data.work) {
    return [];
  }

  return (data.work as Work[]).filter(item => !checkGroup(item)).map(item => {


    return {
      key: item.workId || item.id,
      title: item.name,
      data: [
        {
          key: 'name',
          title: i18n.t('workDataRequest.name'),
          data: item.name,
        },
        {
          key: 'responsible',
          title: i18n.t('workDataRequest.responsible'),
          data: item.responsible
        },
        {
          key: 'responsibleRoleId',
          title: i18n.t('workDataRequest.responsibleRoleId'),
          data: getDict(self.props.dict.roles, item.responsibleRoleId)
        },
        {
          key: 'statusId',
          title: i18n.t('workDataRequest.statusId'),
          data: getDict(self.props.dict.workStatus, item.statusId)
        },
        {
          key: 'dateStart',
          title: i18n.t('workDataRequest.dateStart'),
          data: formatDate(item.dateStart)
        },
        {
          key: 'dateEnd',
          title: i18n.t('workDataRequest.dateEnd'),
          data: formatDate(item.dateEnd)
        },
        {
          key: 'dateEndFact',
          title: i18n.t('workDataRequest.dateEndFact'),
          data: formatDate(item.dateEndFact)
        },
        {
          key: 'duration',
          title: i18n.t('workDataRequest.duration'),
          data: formatDays(item.duration)
        },
        {
          key: 'results',
          title: i18n.t('workDataRequest.results'),
          data: item.results
        },
        {
          key: 'weight',
          title: i18n.t('workDataRequest.weight'),
          data: item.weight
        },
        {
          key: 'nameNaturalIndex',
          title: i18n.t('workDataRequest.progress.nameNaturalIndex'),
          data: item.nameNaturalIndex
        },
        {
          key: 'plannedValue',
          title: i18n.t('workDataRequest.progress.plannedValue'),
          data: item.plannedValue
        },
        {
          key: 'factValue',
          title: i18n.t('workDataRequest.progress.factValue'),
          data: item.factValue
        },
      ]
    }
  });
}

const AgreementWorkBlock = async (self) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/workExt')
    .then(ext => ext?.(self)) as AgreementBlockExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data);
        result = extModule?.updateResult(data, result) || result;
        return result;
      }
    },
  ]
};

export default AgreementWorkBlock;
