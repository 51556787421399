export const LOGO_SUFFIX = process.env.REACT_APP_LOGO_SUFFIX;
export const LOGO_EXT = process.env.REACT_APP_LOGO_EXT || 'ico';
export const FELIX_REST = process.env.REACT_APP_REST;

export const AUTH_COOKIE = 'FELIX-AUTH-ID';
export const PROJECT_NAME_TITLE = 'Феликс';

export const FORMAT_DATE_SERVER = "YYYY-MM-DD";
export const FORMAT_DATE = "DD.MM.YYYY";
export const FORMAT_DATE_HUMAN = `D${String.fromCharCode(160)}MMMM YYYY`;
export const FORMAT_MONTH_YEAR = "MM/YYYY";
export const DEPUTY_PERIOD_MAX_DAYS = 20;

export const ASYNC_SEARCH_TIMEOUT = 400;

//Settings
export enum SettingsType {
  RELEASE_NOTE_TEMPLATE = 'RELEASE_NOTE_TEMPLATE',
  LEADER_RATING_ENABLED = 'LEADER_RATING_ENABLED',
}

// Works
export enum WorkType {
  TASK = 1,
  GROUP = 2,
  MILESTONE = 3,
}

export enum WorkStatus {
  PLAN = 'PLAN',
  WORK = 'WORK',
  CANCEL = 'CANCEL',
  SUCCESS = 'SUCCESS',
  MILESTONE_SUCCESS = 'MILESTONE_SUCCESS',
  MILESTONE_SUCCESS_WITH_COMMENT = 'MILESTONE_SUCCESS_WITH_COMMENT'
}

export const WORK_STATUS_SUCCESS = [WorkStatus.SUCCESS, WorkStatus.MILESTONE_SUCCESS, WorkStatus.MILESTONE_SUCCESS_WITH_COMMENT];

//Project statuses
export enum ProjectStatus {
  DRAFT = 'DRAFT',
  EDITING = 'EDITING',
  REWORK = 'REWORK',
  RELEASE = 'RELEASE',
  ARCHIVE = 'ARCHIVE',
  COORDINATION = 'COORDINATION',
  TECHNICAL_PROJECT = 'TECHNICAL_PROJECT',
}
export const EDIT_PROJECT_STATUSES: string[] = [ProjectStatus.DRAFT, ProjectStatus.EDITING, ProjectStatus.REWORK];
export const EXT_EDIT_PROJECT_STATUSES: string[] = EDIT_PROJECT_STATUSES.concat(ProjectStatus.RELEASE);

//Project stages
export enum ProjectStage {
  CLOSE = 'CLOSE',
  ASSESSMENT_BENEFITS = 'ASSESSMENT_BENEFITS',
}

//Roles
export enum UserRole {
  LEADER = 'LEADER',
  LEADER_PROJECT = 'LEADER_PROJECT',
  ADMIN_PROJECT = 'ADMIN_PROJECT',
  CURATOR_PROJECT = 'CURATOR_PROJECT',
  TOP_MANAGER = 'TOP_MANAGER',
  CUSTOMER = 'CUSTOMER',
  PROJECT_FINANCE_CONTROLLER = 'PROJECT_FINANCE_CONTROLLER',
  PROJECT_ANALYST_CORPORATE_OFFICE = 'PROJECT_ANALYST_CORPORATE_OFFICE',
  PROJECT_COORDINATING_LEADER_POE = 'PROJECT_COORDINATING_LEADER_POE',
  PROJECT_COORDINATING_LEADER_PSR = 'PROJECT_COORDINATING_LEADER_PSR',
  ADMIN_ERMO = 'ADMIN_ERMO',
  MAIN_ADMIN_ERMO = 'MAIN_ADMIN_ERMO',
}

//Rights
export enum UserRight {
  SYSTEM_SECTION_PROJECT_ACCESS = 'SYSTEM_SECTION_PROJECT_ACCESS',
  SYSTEM_SECTION_ARCHIVE_ACCESS = 'SYSTEM_SECTION_ARCHIVE_ACCESS',
  SYSTEM_SECTION_TIMESHEET_ACCESS = 'SYSTEM_SECTION_TIMESHEET_ACCESS',
  SYSTEM_SECTION_REPORTS_ACCESS = 'SYSTEM_SECTION_REPORTS_ACCESS',
  SYSTEM_SECTION_KEY_REPORT_ACCESS = 'SYSTEM_SECTION_KEY_REPORT_ACCESS',
  SYSTEM_SECTION_TOP_REPORT_ACCESS = 'SYSTEM_SECTION_TOP_REPORT_ACCESS',
  SYSTEM_SECTION_PSR_REPORT_ACCESS = 'SYSTEM_SECTION_PSR_REPORT_ACCESS',
  SYSTEM_SECTION_DASHBOARD_ACCESS = 'SYSTEM_SECTION_DASHBOARD_ACCESS',
  SYSTEM_SECTION_COMMITTEE_ACCESS = 'SYSTEM_SECTION_COMMITTEE_ACCESS',
  SYSTEM_SECTION_PPM_ACCESS = 'SYSTEM_SECTION_PPM_ACCESS',
  SYSTEM_SECTION_ADMINISTRATION_ACCESS = 'SYSTEM_SECTION_ADMINISTRATION_ACCESS',

  ADMINISTRATION_USER_MANAGEMENT = 'ADMINISTRATION_USER_MANAGEMENT',
  ADMINISTRATION_SYSTEM_PANEL = 'ADMINISTRATION_SYSTEM_PANEL',
  ADMINISTRATION_ADMIN_PANEL = 'ADMINISTRATION_ADMIN_PANEL',
  ADMINISTRATION_VIDEO_LESSON = 'ADMINISTRATION_VIDEO_LESSON',

  REPORT_ACCESS = 'REPORT_ACCESS',
  RISK_REPORT_ACCESS = 'RISK_REPORT_ACCESS',
  MBO_CHANGES_REPORT_ACCESS = 'MBO_CHANGES_REPORT_ACCESS',
  INTEGRATION_INBOUND_REPORT_ACCESS = 'INTEGRATION_INBOUND_REPORT_ACCESS',
  SHOW_REPORT_KEY = 'SHOW_REPORT_KEY',
  LOAD_B2O_EXCEL = 'LOAD_B2O_EXCEL',
  VERTICAL_AND_TERRITORY_REPORT_ACCESS = 'VERTICAL_AND_TERRITORY_REPORT_ACCESS',
  FINANCIAL_RESULTS_REPORT_ACCESS = 'FINANCIAL_RESULTS_REPORT_ACCESS',
  COMMON_REPORT_ACCESS = 'COMMON_REPORT_ACCESS',
  PPM_LOAD_REPORT_ACCESS = 'PPM_LOAD_REPORT_ACCESS',
  EDIT_PROJECT = 'EDIT_PROJECT',
  SHOW_PROJECT = 'SHOW_PROJECT',
  SHOW_ALL_PROJECT_NO_RESTRICTIONS = 'SHOW_ALL_PROJECT_NO_RESTRICTIONS',
  EDIT_WORK_PROGRESS_RELEASE = 'EDIT_WORK_PROGRESS_RELEASE',
  EDIT_WORK_URL = 'EDIT_WORK_URL',
  WORK_EDIT_IN_RELEASE = 'WORK_EDIT_IN_RELEASE',
  WORK_REMOVE_FORCE = 'WORK_REMOVE_FORCE',
  IMPORT_WORKS_MS_PROJECT = 'IMPORT_WORKS_MS_PROJECT',
  EDIT_BUDGET_PREDICTION = 'EDIT_BUDGET_PREDICTION',
  ACCEPT_BUDGET_PREDICTION = 'ACCEPT_BUDGET_PREDICTION',
  FORCE_ACCEPT_BUDGET_PREDICTION = 'FORCE_ACCEPT_BUDGET_PREDICTION',
  REWORK_BUDGET_PREDICTION = 'REWORK_BUDGET_PREDICTION',
  SHOW_PROJECT_SECTION_ALL = 'SHOW_PROJECT_SECTION_ALL',
  SHOW_PROJECT_SECTION_BUSINESS_CASE = 'SHOW_PROJECT_SECTION_BUSINESS_CASE',
  SHOW_PROJECT_SECTION_EMPLOYEES = 'SHOW_PROJECT_SECTION_EMPLOYEES',
  SHOW_PROJECT_SECTION_RISKS = 'SHOW_PROJECT_SECTION_RISKS',
  SHOW_PROJECT_SECTION_BUDGET = 'SHOW_PROJECT_SECTION_BUDGET',
  SHOW_PROJECT_SECTION_PROJECTS = 'SHOW_PROJECT_SECTION_PROJECTS',
  SHOW_PROJECT_SECTION_WORKS = 'SHOW_PROJECT_SECTION_WORKS',
  SHOW_PROJECT_SECTION_KPI = 'SHOW_PROJECT_SECTION_KPI',
  SHOW_PROJECT_SECTION_MBO = 'SHOW_PROJECT_SECTION_MBO',
  SHOW_PROJECT_SECTION_LESSONS = 'SHOW_PROJECT_SECTION_LESSONS',
  SHOW_PROJECT_SECTION_DOCUMENTS = 'SHOW_PROJECT_SECTION_DOCUMENTS',
  SHOW_PROJECT_SECTION_VERSIONS = 'SHOW_PROJECT_SECTION_VERSIONS',
  SHOW_PROJECT_SECTION_STATUS_REPORT = 'SHOW_PROJECT_SECTION_STATUS_REPORT',
  SHOW_PROJECT_SECTION_PROJECT_REPORT = 'SHOW_PROJECT_SECTION_PROJECT_REPORT',
  SHOW_PROJECT_SECTION_CLOSE_REPORT = 'SHOW_PROJECT_SECTION_CLOSE_REPORT',
  SHOW_PROJECT_SECTION_AGREE = 'SHOW_PROJECT_SECTION_AGREE',
  SHOW_PROJECT_SECTION_AGREE_COMMITTE = 'SHOW_PROJECT_SECTION_AGREE_COMMITTE',
  SHOW_PROJECT_SECTION_AGREE_UK = 'SHOW_PROJECT_SECTION_AGREE_UK',
  SHOW_PROJECT_SECTION_AGREE_UK_MEETING = 'SHOW_PROJECT_SECTION_AGREE_UK_MEETING',
  SHOW_PROJECT_SECTION_STATUS_REPORT_UK = 'SHOW_PROJECT_SECTION_STATUS_REPORT_UK',
  SHOW_PROJECT_SECTION_ASSIGNMENT = 'SHOW_PROJECT_SECTION_ASSIGNMENT',
  SHOW_WORK_OFFSET_MESSAGE = 'SHOW_WORK_OFFSET_MESSAGE',
  ASSIGNMENT_CREATE = 'ASSIGNMENT_CREATE',
  ASSIGNMENT_EDIT = 'ASSIGNMENT_EDIT',
  HIDE_SELECT_IN_TEAM = 'HIDE_SELECT_IN_TEAM',
  HIDE_SELECT_IN_TEAM_EDIT = 'HIDE_SELECT_IN_TEAM_EDIT',
  TEAM_DISABLE_EDIT = 'TEAM_DISABLE_EDIT',
  PROJECT_ROLLBACK = 'PROJECT_ROLLBACK',
  COMMITTEE_EDIT = 'COMMITTEE_EDIT',
  PRELIMINARY_ACCEPT_BUDGET_PREDICTION = 'PRELIMINARY_ACCEPT_BUDGET_PREDICTION',
  SET_IS_IN_TOP = 'SET_IS_IN_TOP',
  KEY_PROJECT_COMMITTEE_DATE = 'KEY_PROJECT_COMMITTEE_DATE',
  CHANGE_IS_STATE_FUNDING = 'CHANGE_IS_STATE_FUNDING',
  EDIT_VERSION_ROLLBACK = 'EDIT_VERSION_ROLLBACK',
  AGREE_PROJECT = 'AGREE_PROJECT',
  REJECT_PROJECT = 'REJECT_PROJECT',
  AGREEMENT_REPEATED = 'AGREEMENT_REPEATED',
  PPM_REQUEST_SHOW = 'PPM_REQUEST_SHOW',
  PPM_SHOW_ALL_REQUESTS = 'PPM_SHOW_ALL_REQUESTS',
  PPM_REQUEST_EDIT = 'PPM_REQUEST_EDIT',
  PPM_REQUEST_EDIT_EXTENDED = 'PPM_REQUEST_EDIT_EXTENDED',
  PPM_REQUEST_SEND_TO_COORDINATION = 'PPM_REQUEST_SEND_TO_COORDINATION',
  PPM_REQUEST_SEND_TO_REWORK = 'PPM_REQUEST_SEND_TO_REWORK',
  PPM_REQUEST_CANCEL_DRAFT = 'PPM_REQUEST_CANCEL_DRAFT',
  PPM_REQUEST_COORDINATION_VOTE = 'PPM_REQUEST_COORDINATION_VOTE',
  PPM_REQUEST_FAMILIARIZATION = 'PPM_REQUEST_FAMILIARIZATION',
  PPM_REQUEST_ROLLBACK_COMMITTEE = 'PPM_REQUEST_ROLLBACK_COMMITTEE',
  PPM_REQUEST_SEND_TO_COMMITTEE = 'PPM_REQUEST_SEND_TO_COMMITTEE',
  PPM_REQUEST_COPY_TO_PROJECT = 'PPM_REQUEST_COPY_TO_PROJECT',
  PPM_REQUEST_RECOPY_TO_PROJECT = 'PPM_REQUEST_RECOPY_TO_PROJECT',
  COMMITTEE_REPORT_ACCESS = 'COMMITTEE_REPORT_ACCESS',
  COMMITTEE_REJECT = 'COMMITTEE_REJECT',
  COMMITTEE_SEND_TO_EXPERT = 'COMMITTEE_SEND_TO_EXPERT',
  CHANGE_BUSINESS_PROCESS = 'CHANGE_BUSINESS_PROCESS',
  REGISTRY_BTI_REPORT_ACCESS = 'REGISTRY_BTI_REPORT_ACCESS',
  DELETE_AGREE_FILE = 'DELETE_AGREE_FILE',
  PROJECT_CHANGE_MODERNIZATION_NETWORK = 'PROJECT_CHANGE_MODERNIZATION_NETWORK',
}
export const SHOW_PROJECT_RIGHTS: string[] = [UserRight.SHOW_PROJECT,
  UserRight.SHOW_ALL_PROJECT_NO_RESTRICTIONS];

export const SHOW_PROJECT_ALL_SECTIONS: string[] = [UserRight.SHOW_PROJECT_SECTION_ALL,
  UserRight.SHOW_ALL_PROJECT_NO_RESTRICTIONS];

//Agree statuses
export enum AgreeStatus {
  ACTIVE = 'ACTIVE',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
  ROLLBACK = 'ROLLBACK',
}

export enum AgreeUserStatus {
  WAIT = 'WAIT',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
  ACCEPT_AUTO = 'ACCEPT_AUTO',
  ACCEPT_WITH_COMMENT = 'ACCEPT_WITH_COMMENT',
  CANCELED = 'CANCELED',
  SEND_TO_EXPERT = 'SEND_TO_EXPERT',
  VOTE_YES  = 'VOTE_YES', // За
  VOTE_NO  = 'VOTE_NO', // Против
  VOTE_REWORK  = 'VOTE_REWORK', // Требуется повторное рассмотрение
  VOTE_ABSTAIN  = 'VOTE_ABSTAIN', // Воздержался
  VOTE_YES_AUTO  = 'VOTE_YES_AUTO', // За - согласованно автоматически
  INTRAMURAL_COMMITTEE_FINISHED  = 'INTRAMURAL_COMMITTEE_FINISHED'
}

export enum AgreeStage {
  COMMON = 'COMMON',
  COMMITTEE_FIRST_QUEUE = 'COMMITTEE_FIRST_QUEUE',
  COMMITTEE_PORTFOLIO_LEADER = 'COMMITTEE_PORTFOLIO_LEADER',
  COMMITTEE_SECOND_QUEUE = 'COMMITTEE_SECOND_QUEUE',
  COMMITTEE_VOTE = 'COMMITTEE_VOTE',
  COMMITTEE_LEADER_VOTE = 'COMMITTEE_LEADER_VOTE'
}

//Sections
export enum ProjectSection {
  COMMON = 'COMMON',
  BUSINESS_CASE = 'BUSINESS_CASE',
  EMPLOYEES = 'EMPLOYEES',
  RISKS = 'RISKS',
  BUDGET = 'BUDGET',
  WORKS = 'WORKS',
  KPI = 'KPI',
  MBO = 'MBO',
  LESSONS = 'LESSONS',
}

//Assignments
export enum AssignmentStatus {
  DRAFT = 'DRAFT',
  CANCEL = 'CANCEL',
  WORK = 'WORK',
  COORDINATION = 'COORDINATION',
  SUCCESS = 'SUCCESS',
}
export enum AssignmentAgreeStatus {
  ACTIVE = 'ACTIVE',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
}
export enum AssignmentAgreeType {
  DATE_CHANGE = 'DATE_CHANGE',
  SUCCESS = 'SUCCESS',
}

//Status report
export enum ProjectStatusReportStatus {
  SIGNIFICANT_DEVIATION = 'SIGNIFICANT_DEVIATION',
  SLIGHT_DEVIATION = 'SLIGHT_DEVIATION',
  PLAN = 'PLAN',
}

export enum BusinessProcess {
  BTI = 'BTI',
  GENERAL = 'GENERAL',
}

//Budget
export enum BudgetType {
  QUALITATIVE = 'QUALITATIVE',
  NO_CAPEX = 'NO_CAPEX',
}