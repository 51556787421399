import { getBudgetNameTable, getSumBudgets } from '@/pages/CreateProject/Budget/util'
import {
  dictTreeToDict,
  forrmattedNum,
  getDict,
  getDictByCode,
  getDictObj,
  getPaybackPeriodStr,
  getRound, importExt
} from '@/utils';
import i18n from 'i18next';
import { sortBy } from 'lodash';
import { AgreementBlockCapexExt, AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';

const prepareResult = (self, data, typeCode: BudgetSourceType): any[] => {
  if (!data.budget) {
    return [];
  }

  const budgetSourceType = getDictByCode(self.props.dict.budgetSourceType, typeCode);
  const result = [];
  getSumBudgets(data.budget, data, budgetSourceType?.id).forEach(budget => {
    result.push({
      key: `year_${budget.year}_${typeCode}`,
      title: `Год ${budget.year}`,
      data: [
        {
          key: `okbPlan_${budget.year}`,
          title: `${getBudgetNameTable(budgetSourceType)} (план)`,
          data: getRound(budget.okbPlan, 0)
        },
        {
          key: `okbFact_${budget.year}`,
          title: `${getBudgetNameTable(budgetSourceType)} (факт)`,
          data: getRound(budget.okbFact, 0)
        },
        ...(typeCode === 'CAPEX' ? ([
          {
            key: `ddsPlan_${budget.year}`,
            title: 'ДДС (план)',
            data: getRound(budget.ddsPlan, 0)
          },
          {
            key: `ddsFact_${budget.year}`,
            title: 'ДДС (факт)',
            data: getRound(budget.ddsFact, 0)
          },
          {
            key: `inputPlan_${budget.year}`,
            title: 'ВВОД (план)',
            data: getRound(budget.inputPlan, 0)
          },
          {
            key: `inputFact_${budget.year}`,
            title: 'ВВОД (факт)',
            data: getRound(budget.inputFact, 0)
          },
        ]) : [])
      ]
    })
  })

  return result;
}

const AgreementCapexBlock = async (self: any, typeCode: BudgetSourceType) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/capexExt')
    .then(ext => ext?.(self)) as AgreementBlockCapexExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data, typeCode);
        result = extModule?.updateResult(data, typeCode, result) || result;
        return result;
      }
    }
  ];
}

export default AgreementCapexBlock;
