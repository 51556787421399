import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from '@/components/Loader';
import CommonModal from '@/pages/General/CommonModal'
import LoaderModal from '@/pages/General/LoaderModal'
import { LOGO_EXT, LOGO_SUFFIX } from '@/config/const';
import Toast from "@/components/Toast";
import { useAppDispatch, useAppSelector } from '@/store';
import TopReport from "@/pages/TopReport";
import service from '@/services';
import AppMessage from '@/pages/Main/AppMessage';
import { useLicenseData } from '@/utils/hooks';
import moment from 'moment/moment';
import { addAppMessage } from '@/actions/appMessage/addAppMessage';
import { clearAppMessage } from '@/actions/appMessage/clearAppMessage';
import { formatDate } from '@/utils';

const Auth = lazy(() => import('../Auth'));
const Layout = lazy(() => import('../Main/Layout'));
const InitReport = lazy(() => import('../CreateProject/ProjectReport/InitReport'));
const CloseReport = lazy(() => import('../CreateProject/ProjectReport/CloseReport'));

export const getRegisteredUserCount = () => service.get('/user/registeredCount');

const Main = () => {
  const dispatch = useAppDispatch();
  const curUserId = useAppSelector(state => state.Login.currentUser?.id);
  const licenseData = useLicenseData();

  useEffect(() => {
    if (!curUserId) {
      dispatch(clearAppMessage());
      return;
    }

    getRegisteredUserCount().then(registeredCount => {
      if (registeredCount && licenseData.licenseUserCnt && registeredCount > licenseData.licenseUserCnt) {
        dispatch(addAppMessage('В системе превышено количество используемых пользовательских лицензий.'));
      }

      if (licenseData.licenseDateEnd && moment().startOf('day').diff(licenseData.licenseDateEnd, 'days') > 0) {
        dispatch(addAppMessage(`Срок действия лицензии на использование системы истек ${formatDate(licenseData.licenseDateEnd)}.`));
      }
    });
  }, [curUserId]);

  useEffect(() => {
    if (LOGO_SUFFIX) {
      const link = document.querySelector("link[rel~='icon']") as any;
      link.href = `/logo${LOGO_SUFFIX}.${LOGO_EXT}`;
    }
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname || !curUserId) {
      return;
    }

    service.post(`/visit`, { source: pathname }, { ignoreError: true });
  }, [pathname, curUserId]);

  const toastList = useAppSelector(state => state.Toast.toastList);

  return (
    <Suspense fallback={<Loader />}>
      <AppMessage/>
      <Routes>
        <Route path="/auth/*" 
               element={<Auth />} />

        <Route path="/portfolio/:id/init-report/*"
               element={<InitReport />} />
        <Route path="/program/:id/init-report/*"
               element={<InitReport />} />
        <Route path="/project/:id/init-report/*"
               element={<InitReport />} />

        <Route path="/portfolio/:id/close-report/*"
               element={<CloseReport />} />
        <Route path="/program/:id/close-report/*"
               element={<CloseReport />} />
        <Route path="/project/:id/close-report/*"
               element={<CloseReport />} />

        <Route path="/topReport/*"
               element={<TopReport type='TOP' />} />
        <Route path="/keyReport/*"
               element={<TopReport type='KEY' />} />
        <Route path="/psrReport/*"
               element={<TopReport type='PSR' />} />

        <Route path="*" 
               element={<Layout />} />
      </Routes>
      <CommonModal />
      <LoaderModal />
      <Toast toastList={toastList} autoDelete={true} autoDeleteTime={4000} />
    </Suspense>
  );
};

export default Main;
