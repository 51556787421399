import i18n from "i18next";

import { useAppSelector } from '@/store';

export const getPathByType = (projectType: string) => {
  return projectType ? i18n.getDataByLanguage(projectType).translation['base'] : null;
};

export const getNameByType = (projectType: string) => {
  return projectType ? i18n.getDataByLanguage(projectType).translation['Name'] : null;
};

export const useProjectPath = () => {
  const projectInfo = useAppSelector(state => state.NewProject?.newProjectData);

  return (url: string) => `/${getPathByType(projectInfo.projectTypeCode)}/${projectInfo.id}${url}`;
};

export const getProjectTypeText = projectType => ({
  PROJECT: 'проекта',
  PROGRAM: 'программы',
  PORTFOLIO: 'портфеля'
}[projectType]);