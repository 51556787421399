import cx from 'classnames';
import i18n from "i18next";
import React from 'react';
import { Link } from 'react-router-dom';
import Status from '@/components/Status';
import { LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { cntDeclOfNum, formatDate, getRound } from '@/utils';
import { useAppSelector } from '@/store';
import { getProjectTypeTextNominative } from "@/pages/CreateProject/Blocks/utils";
import { BusinessProcess } from "@/config/const";

const getStatusList = (project) => ({
  projectId: project.projectId,
  stage: project.stageId,
  status: project.statusId,
  projectInvestmentCommitteeId: project.projectInvestmentCommitteeId,
  steeringCommitteeId: project.steeringCommitteeId,
  isClosed: project.isClosed,
  segmentChain: [project.segmentId, project.segmentExecutorId],
});

const getTeamItemByCode = (team, codes = []) => {
  if (!team) {
    return 'Не указан';
  }

  const items = Object.entries(team).reduce((acc, [key, item]) => ([
    ...acc,
    // @ts-ignore ts-migrate(2550) FIXME: Property 'includes' does not exist on type 'any[]'... Remove this comment to see the full error message
    ...(codes.includes(key) ? item : [])
  ]), []);

  return items.join(', ') || 'Не указан';
};

const getMessageNextVersion = (status) => {
  switch (status) {
    case 'COORDINATION':
      return 'Есть версия на согласовании';
    case 'REWORK':
      return 'Есть версия на доработке';
    default:
      return null;
  }
};

const nextProjectVersion = ({ projectNextStatusCode, projectVersionNextId, projectTypeCode }: Project, disabled: boolean) => {
  if (!['COORDINATION', 'REWORK'].includes(projectNextStatusCode)) {
    return null;
  }

  const translation = i18n.getDataByLanguage(projectTypeCode).translation;

  return (
    <Link
      to={disabled ? '#' : `/${translation['base']}/${projectVersionNextId}`}
      className="link_card next_project_version_link"
      onClick={(e) => e.stopPropagation()}
    >
      <span>
        ({getMessageNextVersion(projectNextStatusCode)})
      </span>
      <LinkIcon className="next_project_version_ico" />
    </Link>
  );
};

export const useIsKeyProjectScreen = () => {
  const [
    isKeyProjectScreen,
  ] = useAppSelector(state => [
    state.Login.isKeyProjectScreen,
  ]);

  return isKeyProjectScreen;
};

export const ProjectItem = ({
  project,
  projectType,
  link = undefined,
  target = undefined,
  children = undefined,
  disabled = false,
  agreeCard = false
}) => {
  const isKeyProjectScreen = useIsKeyProjectScreen();
  const isShowAdvanced = true;
  const translation = i18n.getDataByLanguage(projectType).translation;
  const translateWithLng = (key: string) => i18n.t(key, {
    lng: projectType
  });

  const isProjectBti = project.isBti || BusinessProcess.BTI === project.businessProcessCode;

  return (
    <div className="col-sm-12 col-lg-6 col-xl-4 display-flex col-xxl-3">
      <Link
        to={disabled ? '#' : (link || `/${translation['base']}/${project.id}`)}
        target={target}
        className={cx("link_card", disabled ? 'pointer-events-none' : '')}
      >
        <div className={cx(
          "card card-briefcase",
          { "disabled": disabled },
          { "indicator": isKeyProjectScreen },
          project.consolidateStatusCode
        )}>
          <div className="rt-card-body">
            <Status data={getStatusList(project)} projectData={project} isCard/>
            <h3 className="card-title">{`${(agreeCard && isProjectBti) ? (getProjectTypeTextNominative(projectType) + ' ') : ''}${project.name}`} {nextProjectVersion(project, disabled)}</h3>
            {children}
            {isShowAdvanced && (
              <>
                <p className="card-text">{translateWithLng('Start date')} {formatDate(project.dateStart, undefined, ' - не указано')}</p>
                <p className="card-text">{translateWithLng('End date')} {formatDate(project.dateEnd, undefined, ' - не указано')}</p>
                {isProjectBti && <p className="card-text">Бюджет: {project.budgetAllSum ? `${getRound(project.budgetAllSum)} ${i18n.t('SUM_TITLE')} без НДС` : 'Не указано'}</p>}
                <hr />
              </>
            )}

            {projectType === 'PROJECT' && (!agreeCard || !isProjectBti) && (
              <React.Fragment>
                <p className="rt-card-subtitle">Руководитель: {getTeamItemByCode(project.team, ['LEADER', 'LEADER_PROJECT'])}</p>
                <p className="rt-card-subtitle">Согласующий от заказчика: {getTeamItemByCode(project.team, ['CUSTOMER'])}</p>
                <p className="rt-card-subtitle">Заказчик: {getTeamItemByCode(project.team, ['TOP_MANAGER'])}</p>
                <p className="rt-card-subtitle">Куратор: {getTeamItemByCode(project.team, ['CURATOR', 'CURATOR_PROJECT'])}</p>
              </React.Fragment>
            )}

            {projectType === 'PROJECT' && (agreeCard && isProjectBti) && (
              <React.Fragment>
                <p className="rt-card-subtitle">Руководитель проекта: {getTeamItemByCode(project.team, ['LEADER', 'LEADER_PROJECT'])}</p>
              </React.Fragment>
            )}

            {projectType === 'PROGRAM' && (!agreeCard || !isProjectBti) && (
              <React.Fragment>
                <p className="rt-card-subtitle">Руководитель: {getTeamItemByCode(project.team, ['LEADER', 'LEADER_PROJECT'])}</p>
                <p className="rt-card-subtitle">Согласующий от заказчика: {getTeamItemByCode(project.team, ['CUSTOMER'])}</p>
                <p className="rt-card-subtitle">Заказчик: {getTeamItemByCode(project.team, ['TOP_MANAGER'])}</p>
                <p className="rt-card-subtitle">Куратор: {getTeamItemByCode(project.team, ['CURATOR', 'CURATOR_PROJECT'])}</p>
              </React.Fragment>
            )}

            {projectType === 'PROGRAM' && (agreeCard && isProjectBti) && (
              <React.Fragment>
                <p className="rt-card-subtitle">Руководитель программы: {getTeamItemByCode(project.team, ['LEADER', 'LEADER_PROJECT'])}</p>
              </React.Fragment>
            )}

            {projectType === 'PORTFOLIO' && (
              <React.Fragment>
                <p className="rt-card-subtitle">Руководитель портфеля: {getTeamItemByCode(project.team, ['LEADER', 'LEADER_PROJECT'])}</p>
              </React.Fragment>
            )}

            {projectType !== 'PROJECT' && (!agreeCard || !isProjectBti) && (
              <p className="rt-card-subtitle">
                Включает&nbsp;
                {projectType === 'PORTFOLIO' ? `${cntDeclOfNum(project.portfolioCount, ['портфель', 'портфеля', 'портфелей'])}, ` : ''}
                {cntDeclOfNum(project.programCount, ['программу', 'программы', 'программ'])},&nbsp;
                {cntDeclOfNum(project.projectCount, ['проект', 'проекта', 'проектов'])}
              </p>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
