import { get } from "lodash";
import { ProjectStatus, UserRight, WORK_STATUS_SUCCESS, WorkStatus, WorkType } from '@/config/const';
import { getDictCodeById, getDictObj, isEmptyValues, parseDate, selectDateToMoment } from '@/utils';
import { numericWorks } from "@/components/Gantt/util/historyUtil";
import { groupWorks } from '@/components/Gantt/util/utils';
import moment from 'moment/moment';

const groupBlock = (data: Work[]): GanttTableItem[] => {
  const groupedWorks = groupWorks(data);
  numericWorks(groupedWorks);
  return groupedWorks;
};

const flattenBlock = (data: GanttTableItem[], acc: GanttTableItem[] = []): Work[] => {
  data.forEach(work => {
    acc.push({
      ...work,
      subRows: undefined,
    });

    if (checkGroup(work) && work.subRows) {
      flattenBlock(work.subRows, acc);
    }
  });

  return acc;
};

/*
если кто то скажет что это странная шутка, то не пугайтесь, это норм
идея в том что мы группирвем блоки работ по их группам, получается список групп+обычные блоки не входящие в гуппу
(или группы по какой то причине нет)
а потом мы этот сгруппированный список разворачиваем в обычный список без групп
тогда сохраняется исходная сторитровка, но блоки работ входящие в группу будут идти за этой группой
*/
export const workGroupSort = (data: Work[]): Work[] => {
  return flattenBlock(groupBlock(data));
};

export const getProjectTypeTextNominative = projectType => ({
  PROJECT: 'Проект',
  PROGRAM: 'Программа',
  PORTFOLIO: 'Портфель'
}[projectType]);

const getProjectTypeCode = (typesDict, typeId) => {
  return get(getDictObj(typesDict, typeId), 'code');
};

export const getWorkNameWithProject = (work: GanttTableItem, typesDict: any, works: GanttTableItem[]) => {
  const baseGroupId = works.find(w => w.isBaseGroup && !w.isFromOtherProject)?.id;
  if (!baseGroupId || work.workGroupId === baseGroupId) {
    return `${work.name} (${getProjectTypeTextNominative(getProjectTypeCode(typesDict, work.projectTypeId))} "${work.projectVersionName}")`;
  }
  return work.name;
};

export const getWorkUrl = (work: Work, dict: any) => {
  return `/${getDictObj(dict.types, work.projectTypeId).code.toLowerCase()}/${work.projectVersionId}/work/${work.id}`;
};

export const checkGroup = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.GROUP;
};

export const checkMilestone = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.MILESTONE;
};

export const checkTask = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.TASK;
};

export type WorkWithChecked = Work & {
  isChecked: boolean;
};

export const getDuration = (dateStart, dateEnd, weekendUtil) => {
  return isEmptyValues(dateStart) || isEmptyValues(dateEnd)
    ? null
    : weekendUtil.getDuration(parseDate(dateStart), parseDate(dateEnd));
};

export const getCalendarDuration = (dateStart, dateEnd) => {
  return isEmptyValues(dateStart) || isEmptyValues(dateEnd)
    ? null
    : selectDateToMoment(dateEnd).diff(selectDateToMoment(dateStart), 'days') + 1;
};

export const isWorkExpired = (work: GanttTableItem) => {
  if (!isEmptyValues(work.isExpired)) {
    return work.isExpired;
  }

  if (work.isExtreme) {
    return false;
  }

  if (checkGroup(work)) {
    return work.subRows?.some(isWorkExpired);
  }

  const today = moment().startOf('day');
  const dateStart = parseDate(work.dateStart);
  const dateEnd = parseDate(work.dateEnd);

  if (work.status?.code === WorkStatus.PLAN) {
    return today.isAfter(checkMilestone(work) ? dateEnd : dateStart);
  }

  if (work.status?.code === WorkStatus.WORK) {
    return today.isAfter(dateEnd);
  }

  return false;
}

export const isWorkRemoveDisabled = (
  w: GanttTableItem,
  workStatusDict: DictItem,
  projectStatusDict: DictItem,
  checkRight,
  projectData: Project
) => {
  const workStatus = WorkStatus[getDictCodeById(workStatusDict, w.statusId)];
  const isSuccess = WORK_STATUS_SUCCESS.includes(workStatus);
  const projectStatus = ProjectStatus[getDictCodeById(projectStatusDict, projectData.statusId)];
  const isProjectDraft = projectData.version === 1 && [ProjectStatus.DRAFT, ProjectStatus.REWORK].includes(projectStatus);

  return !w.isFromOtherProject
    && (w.isExtreme || w.isBaseGroup || (isSuccess && !checkRight(UserRight.WORK_REMOVE_FORCE) && !isProjectDraft));
}