import cx from 'classnames';
import i18n from "i18next";
import React, { useEffect, useRef } from 'react';
import { createNewItem } from '@/components/Gantt';
import TableDragable from '@/components/Gantt/components/TableDragable';
import { AppendIcon, EditIcon, EyeIcon, UpDownIcon } from '@/components/Gantt/elements/Icons';
import { findAndUpdate, isRemoved } from '@/components/Gantt/util/utils';
import { getShortName } from '@/components/UserSearch';
import UserSearchInTable from '@/components/UserSearch/UserSearchInTable';
import { FORMAT_DATE, WORK_STATUS_SUCCESS, WorkStatus, WorkType } from '@/config/const';
import Input from '@/elements/Input';
import DateSelect from '@/elements/Input/DateSelect';
import SelectDict from '@/elements/Select/SelectDict';
import { dateEndClass, dateStartClass } from '@/pages/CreateProject/Blocks/TableResultWorks';
import { checkGroup, checkMilestone, checkTask, getWorkNameWithProject } from '@/pages/CreateProject/Blocks/utils';
import { createEditable } from '@/components/TableBeta/utils';
import {
  formatDate,
  formatDays, getDict, getDictCodeById,
  getDictObj, getProjectRoleOptionsList,
  prevent
} from '@/utils';
import { useAppSelector } from '@/store';
import { useShowModal } from "@/utils/hooks";
import { WORK_NEED_DOC_MESSAGE } from "@/pages/CreateProject/Blocks/EditBlock";
import Tooltip from '@/elements/Tooltip';
import { compact, isEmpty, sum } from 'lodash';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';
import { Link } from 'react-router-dom';

const getRowProps = (row) => {
  if (isRemoved(row.original)) {
    return {
      style: {
        display: 'none'
      }
    };
  }

  return undefined;
};

export const setFocusToTaskName = (id: number, isSelect = true) => {
  setTimeout(() => {
    const item = (document.querySelector(`[data-task-id="${id}"] .gantt-row-title .inputField`) as any);
    item?.focus();
    isSelect && item?.select();
  }, 100);
};

export const setFocusTo = (id, evenTargetSelector, focusSelector) => {
  setTimeout(() => {
    try {
      const item = (document.querySelector(`[data-task-id="${id}"] ${evenTargetSelector} ${focusSelector}`) as any);
      item?.focus();
    } catch (e) {
      //
    }
  }, 100);
};

const getColumns = ({
  readonly,
  isEditRight,
  dict,
  projectData,
  showModal,
  maxDepth,
  setData,
  setExpanded,
  setOpenWorkId,
  setEditRowId,
  editRowId,
  expandAll,
  isAllNotExpanded,
  workStatusDict,
  weekendUtil,
  works,
  milestoneView = false,
  componentsView = false,
}) => {
  const result: any[] = [];

  const isEditTable = !readonly && isEditRight;
  const isDraft = getDictObj(dict.status, projectData.statusId)?.code === 'DRAFT';

  const getMinDate = (work) => {
    if (checkGroup(work)) {
      return null;
    }

    return projectData.dateStart;
  }

  const getMaxDate = () => {
    return projectData.dateEnd;
  }

  const createNewItemInGroup = (id: number) => {
    let newItem;
    setData(oldData => {
      return findAndUpdate(oldData, id, (item) => {
        if (item.typeId !== WorkType.GROUP) {
          item.isLocalSaved = true;
          item.typeId = WorkType.GROUP;
        }
        item.subRows ||= [];
        newItem = createNewItem({
          workGroupId: id,
          projectId: projectData.projectId,
          projectVersionId: projectData.id,
        });
        item.subRows.push(newItem);

        return item;
      }, workStatusDict, weekendUtil);
    });

    setExpanded(oldExpanded => ({
      ...oldExpanded,
      [`${id}`]: true
    }));

    setTimeout(() => {
      setOpenWorkId(null);
      setEditRowId(newItem.id);
      setFocusToTaskName(newItem.id);
    }, 100);
  };

  const dragTooltip = (children = undefined, delay = undefined) =>
    <Tooltip
      children={children}
      delay={delay}
      text={<span>
        <b>Потяните</b> стрелочки для интерактивного перемещения блока работ<br/>
        <b>Двойной клик</b> по стрелочкам для выбора новой позиции блока работ
    </span>}
    />;

  return compact([
    !readonly && isEditTable ? {
      id: 'remove',
      Header: ({ props }) => {
        return (
          <input
            checked={props.isCheckedAll}
            onChange={() => props.onToggleCheckedAll(!props.isCheckedAll)}
            type="checkbox"
          />
        );
      },
      width: 30,
      Cell: ({ row, props }) => {
        if (row.original.isBaseGroup && !row.original.isFromOtherProject) {
          return null;
        }

        return (
          <input
            checked={props.checked[row.original.id] || false}
            onChange={() => props.onToggleChecked(row.original.id)}
            type="checkbox"
            title="Выделить блок работ"
          />
        );
      }
    } : undefined,
    {
      Header: "",
      width: 15 * maxDepth + 16,
      accessor: 'rowNum',
      Cell: ({value}) => value || '',
    },
    isEditTable && !milestoneView ? {
      id: 'append',
      Header: '',
      width: 24,
      className: 'icon-cell',
      Cell: ({ row }) => {
        if (row.original.isFromOtherProject || row.original.isExtreme) {
          return null;
        }

        return (
          <div>
            <AppendIcon className="link" onClick={prevent(() => createNewItemInGroup(row.id))} />
          </div>
        );
      }
    } : undefined,
    !readonly ? {
      id: 'edit',
      Header: "",
      width: 24,
      className: 'icon-cell',
      Cell: ({ row }) => {
        if (row.original.isFromOtherProject || row.original.isBaseGroup) {
          return <EyeIcon className="link" onClick={prevent(() => setOpenWorkId(row.id))} />
        }

        return (
          <EditIcon className="link" onClick={prevent(() => setOpenWorkId(row.id))} />
        );
      }
    } : undefined,
    !readonly ? {
      id: 'dragable',
      width: 24,
      className: 'icon-cell',
      Header: () => dragTooltip(),
      headerStyle: { paddingLeft: 0, paddingRight: 0 },
      Cell: ({ row }) => {
        if (!row.original.isFromOtherProject && (row.original.isExtreme || row.original.isBaseGroup)) {
          return null;
        }
        return <UpDownIcon/>
      },
      draggable: ({ row }) => row.original.isFromOtherProject || !row.original.isExtreme && !row.original.isBaseGroup,
    } : undefined,
    componentsView ? {
      accessor: 'projectId',
      width: 60,
      Header: 'ID',
      Cell: ({ row, value }) => {
        if (row.original.isFromOtherProject) {
          return (
            <Link
              to={`/static/${value}/work`}
              target="_blank"
            >
              <div className="text">
                {value}
              </div>
            </Link>
          )
        }
        return value;
      },
    } : undefined,
    {
      accessor: 'name',
      Header: () => {
        const getIcon = () => {
          if (isAllNotExpanded) {
            return <span><b>→</b> {i18n.t('workDataRequest.name')}</span>
          }
          return <span><b>↓</b> {i18n.t('workDataRequest.name')}</span>
        }

        return (
          <div onClick={prevent(expandAll)}>
            <Tooltip icon={getIcon()} text={isAllNotExpanded ? 'Развернуть все' : 'Свернуть все'}></Tooltip>
          </div>
        );
      },
      width: 300,
      resizable: true,
      Cell: (args) => {
        const { row, value } = args;
        const name = row.original.isFromOtherProject
          ? (!componentsView ? getWorkNameWithProject(row.original, dict.types, works) : value)
          : (isEditTable && editRowId === row.id && !row.original.isBaseGroup ? createEditable(Input)(args) : value);
        const title = row.original.isFromOtherProject && !componentsView ? getWorkNameWithProject(row.original, dict.types, works) : value;

        if (!row.canExpand || row.original.isExtreme) {
          return (
            <span
              style={{
                paddingLeft: `${row.depth * 16}px`,
              }}
              className="gantt-row-title"
            >
              {row.original.isFromOtherProject ? (
                <Link
                  to={`/static/${row.original.projectId}/work`}
                  target="_blank"
                >
                  <div className="text" title={title}>
                    {name}
                  </div>
                </Link>
              ) : (
                <div className="text" title={title}>
                  {name}
                </div>
              )}
            </span>
          );
        }

        return (
          <span
            style={{
              paddingLeft: `${row.depth * 16}px`,
            }}
            className="gantt-row-title"
          >
            <span
              {...row.getToggleRowExpandedProps({
                title: row.isExpanded ? 'Свернуть группу' : 'Развернуть группу',
                className: cx('canExpand js-no-event', { expanded: row.isExpanded }),
                onClick: prevent(() => row.toggleRowExpanded()),
              })}
            >
            </span>
            {row.original.isFromOtherProject ? (
              <Link
                to={`/static/${row.original.projectId}/work`}
                target="_blank"
              >
                <div className="text js-no-event" title={title}>
                  {name}
                </div>
              </Link>
            ) : (
              <div className="text js-no-event" title={title}>
                {name}
              </div>
            )}
          </span>
        );
      },
    },
    {
      Header: i18n.t('workDataRequest.statusId'),
      width: 110,
      accessor: 'statusId',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original)
          || args.row.original.isFromOtherProject) {
          return <span className='gantt-row-text'>{getDict(dict.workStatus, args.row.original.statusId)}</span>
        }

        return createEditable(SelectDict, {
          dictName: 'workStatus',
          placeholder: checkGroup(args.row.original) ? '' : "Выберите статус",
          filterFunc: item => checkMilestone(args.row.original) ? item.isMilestone : item.isWork,
          inTable: true,
          widthInput: 110,
          widthMenu: 120,
          fontSize: '12px',
          disabled: checkGroup(args.row.original) || args.row.original.isFromOtherProject,
          fastApplyData: true,
          isHideDropdown: true,
          checkNewVal: (initVal, newVal) => {
            if (!isDraft && initVal && newVal && newVal !== initVal
              &&  ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(getDictObj(dict.workStatus, newVal)?.code)) {
              showModal(WORK_NEED_DOC_MESSAGE);
              return false;
            }

            return true;
          }
        })(args);
      },
      dictType: 'workStatus'
    },
    !milestoneView ? {
      accessor: 'dateStart',
      Header: i18n.t('workDataRequest.dateStart'),
      width: 80,
      Cell: (args) => {
        const dateClass = cx(
          dateStartClass(args.value, args.row.original, dict.workStatus),
          checkMilestone(args.row.original) ? dateEndClass(args.value, args.row.original, dict.workStatus) : null,
        );

        if (!isEditTable || editRowId !== args.row.id || !checkTask(args.row.original) || args.row.original.isExtreme) {
          const date = checkMilestone(args.row.original) ? args.row.original.dateEnd : args.value;
          return <span className={cx(dateClass)}>
            {formatDate(date, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          minimumDate: getMinDate(args.row.original),
          maximumDate: args.row.original.dateEnd || getMaxDate(),
          isPortal: true,
          isHideIcon: true,
          className: cx(dateClass),
          fastApplyData: true,
          disabled: args.row.original.isFromOtherProject,
        })(args);
      }
    } : undefined,
    {
      accessor: 'dateEnd',
      Header: milestoneView ? i18n.t('ProjectMilestoneWeb.datePlan') : i18n.t('workDataRequest.dateEnd'),
      width: 80,
      Cell: (args) => {
        const item = args.row.original;

        const dateClass = cx(
          dateEndClass(args.value, args.row.original, dict.workStatus),
          checkMilestone(args.row.original) ? dateStartClass(args.value, args.row.original, dict.workStatus) : null,
        );

        if (!isEditTable || editRowId !== args.row.id || checkGroup(item) || item.isExtreme) {
          return <span className={cx(dateClass)}>
            {formatDate(args.value, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          minimumDate: !checkMilestone(item) && item.dateStart || getMinDate(item),
          maximumDate: getMaxDate(),
          isClearable: checkMilestone(args.row.original),
          isHideClear: true,
          isPortal: true,
          isInTable: true,
          isHideIcon: true,
          className: cx(dateClass),
          fastApplyData: true,
          disabled: item.isFromOtherProject,
        })(args);
      }
    },
    {
      Header: milestoneView ? i18n.t('ProjectMilestoneWeb.dateFact') : i18n.t('workDataRequest.dateEndFact'),
      width: 80,
      accessor: 'dateEndFact',
      Cell: (args) => {
        const isGroup = checkGroup(args.row.original);
        if (!isEditTable || editRowId !== args.row.id || isGroup || args.row.original.isExtreme) {
          return <span className={cx(dateEndClass(args.value, args.row.original, dict.workStatus))}>
            {formatDate(args.value, FORMAT_DATE, null)}
          </span>;
        }

        const workStatus = WorkStatus[getDictCodeById(dict.workStatus, args.row.original.statusId)];
        const isSuccess = WORK_STATUS_SUCCESS.includes(workStatus);

        return createEditable(DateSelect, {
          minimumDate: args.row.original.dateStart || getMinDate(args.row.original),
          maximumDate: getMaxDate(),
          isClearable: checkMilestone(args.row.original),
          isHideClear: true,
          isPortal: true,
          isInTable: true,
          isHideIcon: true,
          className: cx(dateEndClass(args.value, args.row.original, dict.workStatus)),
          fastApplyData: true,
          disabled: args.row.original.isFromOtherProject || isGroup || !isSuccess,
        })(args);
      }
    },
    !componentsView ? {
      accessor: 'dateEndInitial',
      Header: i18n.t('workDataRequest.dateEndInitial'),
      width: 80,
      Cell: (args) => {
        return formatDate(args.value, FORMAT_DATE, null);
      }
    } : undefined,
    !milestoneView ? {
      accessor: 'duration',
      Header: i18n.t('workDataRequest.duration'),
      width: 60,
      Cell: ({ value, row }) => <span className='gantt-row-text'>{formatDays(checkMilestone(row.original) ? 0 : value)}</span>,
    } : undefined,
    !milestoneView ? {
      accessor: 'calendarDuration',
      Header: i18n.t('workDataRequest.calendarDurationShort'),
      width: 60,
      Cell: (args) => <span className='gantt-row-text'>{formatDays(checkMilestone(args.row.original) ? 0 : args.value)}</span>
    } : undefined,
    !componentsView ? {
      accessor: 'responsible',
      Header: i18n.t('workDataRequest.responsible'),
      width: 140,
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original) || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{getShortName(args.value?.displayName) || null}</span>
        }

        return createEditable(UserSearchInTable, {
          placeholder: "Выберите исполнителя",
          inTable: true,
          widthInput: 140,
          widthMenu: 150,
          fontSize: '12px',
          isShort: true,
          isHideDropdown: true,
          disabled: args.row.original.isFromOtherProject,
        })(args);
      }
    } : undefined,
    !componentsView ? {
      accessor: 'responsibleRoleId',
      Header: i18n.t('workDataRequest.responsibleRoleId'),
      width: 120,
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original) || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{checkGroup(args.row.original) ? null : getDict(dict.roles, args.row.original.responsibleRoleId)}</span>
        }

        const projectTypeCode = getDictObj(dict.projectTypes, projectData.typeId)?.code;

        return createEditable(SelectDict, {
          customOptions: getProjectRoleOptionsList(dict.roles, projectTypeCode),
          placeholder: "Выберите роль",
          inTable: true,
          widthInput: 120,
          widthMenu: 130,
          fontSize: '12px',
          isHideDropdown: true,
          disabled: args.row.original.isFromOtherProject,
          fastApplyData: true,
        })(args);
      }
    } : undefined,
    !milestoneView && !componentsView ? {
      accessor: 'dateUpdateStatus',
      Header: i18n.t('workDataRequest.dateUpdateStatus'),
      width: 80,
      Cell: ({ value }) => formatDate(value, FORMAT_DATE, null)
    } : undefined,
  ]);
};

const GanttTable = ({
  projectData,
  expanded,
  setExpanded,
  expandAllClick,
  isAllNotExpanded,
  expandAll,
  data,
  link,
  maxDepth,
  setData,
  openWorkId,
  setOpenWorkId,
  editRowId,
  setEditRowId,
  readonly,
  isEditRight,
  checked,
  setChecked,
  dataFlatten,
  milestoneView = false,
  componentsView = false,
  isFullscreen,
  setSplitPercent,
}) => {
  const dict = useAppSelector(state => state.dict);
  const showModal = useShowModal();
  const weekendUtil = useWeekendUtil();

  const onClickRow = (row, e) => {
    if (e.detail === 2) {
      setOpenWorkId(row.id);
      return;
    }

    if (!readonly && isEditRight) {
      if (editRowId !== row.id && !row.original.isFromOtherProject) {
        setEditRowId(row.id);
        if (!e.nativeEvent.target.className) {
          return;
        }
        //Попытаемся поставить фокус на инпут
        setFocusTo(row.id, `.${e.nativeEvent.target.className}`, '.inputField');
      }
    }
  };

  const onToggleChecked = (workId: number) => {
    setChecked(prev => {
      return {
        ...prev,
        [workId]: !prev[workId]
      };
    });
  };

  const dataForChecking = dataFlatten.filter(item => !item.isBaseGroup || item.isFromOtherProject);
  const isCheckedAll = !isEmpty(dataForChecking) && dataForChecking.every(item => checked[item.id]);

  const onToggleCheckedAll = (isChecked) => {
    setChecked(dataForChecking.reduce((acc, item) => ({
      ...acc,
      [item.id]: isChecked,
    }), {}));
  };

  const focusKey = useRef(null);
  const columns = React.useMemo(() => {
    return getColumns({
      readonly,
      isEditRight,
      dict,
      projectData,
      showModal,
      maxDepth,
      setData,
      setExpanded,
      setOpenWorkId,
      setEditRowId,
      editRowId,
      expandAll,
      isAllNotExpanded,
      workStatusDict: dict.workStatus,
      weekendUtil,
      works: data,
      milestoneView,
      componentsView,
    });
  }, [maxDepth, editRowId, data, isAllNotExpanded, dict.workStatus?.status]);

  useEffect(() => {
    if (isFullscreen && componentsView && setSplitPercent && !isEmpty(columns)) {
      const curWidth = window.innerWidth - 16; //16 - отступы ганта от краев, по 8 с каждого
      const tableWidth = sum(columns.map(col => col.width || 0));
      const tableWidthPercent = (tableWidth + 4) / curWidth; //4 - половина длины сплиттера
      if (tableWidthPercent < 0.5) {
        setSplitPercent(tableWidthPercent);
      }
    }
  }, [isFullscreen, componentsView, setSplitPercent, columns]);

  return (
    <div className="gantt_table__container">
      <TableDragable
        data={data}
        link={link}
        columns={columns}
        expandedLocal={expanded}
        setExpanded={setExpanded}
        expandAllClick={expandAllClick}
        isAllNotExpanded={isAllNotExpanded}
        setData={setData}
        rowProps={getRowProps}
        readonly={readonly}
        isEditRight={isEditRight}
        onClickRow={onClickRow}
        milestoneView={milestoneView}
        ignoreWorkGroups={milestoneView}
        props={{
          checked,
          onToggleChecked,
          isCheckedAll,
          onToggleCheckedAll,
          isAcceptEdit: !readonly && isEditRight && !openWorkId,
          focusKey,
        }}
      />
    </div>
  );

};

export default GanttTable;