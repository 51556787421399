import cx from 'classnames';
import { isFunction, isNil } from 'lodash';
import React from 'react';
import NumberFormat from 'react-number-format';

import InputTitle from '@/components/InputTitle';
import { EditIcon } from '@/components/Gantt/elements/Icons';
import Tooltip from '@/elements/Tooltip';

export class InputNumField extends React.Component<any, any> {
  input: any;
  constructor(props) {
    super(props);

    this.input = React.createRef();
  }

  focus() {
    this.input.current.focus();
  }

  render() {
    const withValueLimit = ({ value }) => {
      if (!isNil(this.props.min) && (+value < +this.props.min || (+this.props.min >= 0 && value === '-'))) {
        return false;
      }

      if (!isNil(this.props.max) && (+value > this.props.max)) {
        return false;
      }

      return true;
    };

    return (
      <NumberFormat thousandSeparator={" "}
        isNumericString={true}
        decimalScale={0}
        isAllowed={withValueLimit}
        {...this.props}
        onValueChange={(values, sourceInfo) => this.props.onChange(values.value, sourceInfo)}
        onChange={undefined}
        value={this.props.value}
        getInputRef={this.input}
        decimalSeparator={','}
      />
    );
  }
}


const InputNum = ({
  isHide = false,
  titleClassName = undefined,
  disabled = false,
  min = -2_147_483_647,
  max = 2_147_483_647,
  inTable = false,
  inverted = false,
  tooltipDelay = 0,
  ...props
}) => {
  if (isHide) {
    return null;
  }

  // тут придётся немного поломать универсальность,
  // ибо NumberFormat не умеет одноврменно возвращать и событие и значание без форматирования
  const onChange = (e, source) => {
    if (isFunction(props.onChange)) {
      props.onChange(e, source);
    }
  };

  const value = isNil(props.value) ? '' : props.value;

  const {tooltip, inTitleDescription, hideRequiredDesc, name, ...inputNumFieldProps} = props;

  const isEdit = !disabled && !props.readonly;

  return (
    <InputTitle
      required={props.required}
      tooltip={tooltip}
      label={props.label}
      value={props.isFilled || value === 0 ? '1' : value}
      description={props.description}
      inTitleDescription={props.inTitleDescription}
      className={cx(titleClassName, { 'in-table': inTable })}
      readonly={props.readonly}
      disabled={disabled}
      hideRequiredDesc={hideRequiredDesc}
      info={inTable && isEdit && (
        <Tooltip text='Кликните на поле и введите значение' placement='top' delay={tooltipDelay}>
          <div className={cx('input-title__edit-tooltip', { inverted })}>
            <EditIcon/>
          </div>
        </Tooltip>
      )}
    >
      <InputNumField
        autoComplete='off'
        {...inputNumFieldProps}
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        className={cx(props.className, 'inputField', { withEditIcon: inTable && isEdit }, { inverted })}
        disabled={disabled}
      />
    </InputTitle>
  );
};

export default InputNum;
