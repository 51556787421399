import cx from 'classnames';
import { isEmpty, keyBy, orderBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StatusIndicator from '@/components/Indicator';
import Spoiler from '@/components/Spoiler';
import { FORMAT_DATE, ProjectStatusReportStatus } from '@/config/const';
import Select from '@/elements/Select';
import SwitchSlider from '@/elements/SwitchSlider';
import TextArea from '@/elements/TextArea';
import service from '@/services';
import {
  checkRight as createCheckRight,
  formatDate,
  formatDateWithFormat,
  formatDays,
  getDict,
  getDictCodeById,
  getDictForSelect,
  getDictObj,
  getRound
} from '@/utils';
import { getPathByType } from '@/utils/project';
import { useAppSelector } from '@/store';
import DocBlock from "@/components/Doc";
import { checkMilestone, getWorkNameWithProject } from '@/pages/CreateProject/Blocks/utils';
import RiskTable from "@/pages/CreateProject/Status/RiskTable";
import { getProjectTypeText } from '@/utils/project';
import i18n from 'i18next';

const getWorks = (statusId) => service.get(`/work/status-report/${statusId}`);

const workInfoGenerator = (work, dictWorkStatus, dictWorkType) => {
  return [
    { title: 'Тип', value: getDict(dictWorkType, work.typeId) },
    { title: 'Длительность', value: checkMilestone(work) ? null : formatDays(work.duration), className: cx('float-end', 'report-item__title-duration-row') },
    { title: 'Начало', value: checkMilestone(work) ? null : formatDate(work.dateStart, FORMAT_DATE) },
    { title: 'Окончание (план)', value: formatDate(work.dateEnd, FORMAT_DATE) },
    { title: 'Окончание (факт)', value: formatDate(work.dateEndFact, FORMAT_DATE) },
    { title: 'Текущий статус', value: `${getDict(dictWorkStatus, work.statusId)}${work.dateUpdateStatus ? `, был установлен ${formatDateWithFormat(work.dateUpdateStatus)}` : ''}` },
  ].filter(({ value }) => value);
};

const getMonth = (num) => {
  return [
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре',
  ][num];
};

const renderWorkList = (projectId, works, dict, workLinkGenerator, uniqKey) => {
  return (
    <>
      {isEmpty(works) ? (
        <div>
          Нет блоков работ
        </div>
      ) : works.map(work => (
        <div key={`${uniqKey}-${work.id}`} className="report-work__container" >
          <Link className="report-work__name" to={workLinkGenerator(work.id)}>
            {work.projectId === projectId ? work.name : getWorkNameWithProject(work, dict.types, works)}
          </Link>
          {workInfoGenerator(work, dict.workStatus, dict.workType).map(({ title, value, className }) => (
            <div key={`${uniqKey}-${work.id}-${title}`} className={className}>{title}: {value}</div>
          ))}
        </div>
      ))}
      <hr className="report-line" />
    </>
  );
};

const EditView = ({
  status,
  isReadonly,
  onChangeInput,
  onChangeRiskComment,
  isMiniMode = false,
  docRef = undefined,
  accentRequired = false,
  helper,
}) => {
  const [workData, setWorkData] = useState({
    active: [],
    cancel: [],
    end: [],
    endSoon: [],
  });
  const [works, setWorks] = useState([]);
  const [isWorksLoaded, setIsWorksLoaded] = useState(false);
  const [dict, projectInfo, checkRight,] = useAppSelector(state => [
    state.dict,
    state.NewProject?.newProjectData,
    createCheckRight(state),
  ]);

  const dictReportStatus = dict.reportStatus;
  const dictReportStatusSelect = getDictForSelect(dictReportStatus);

  const workLinkGenerator = (workId) => `/${getPathByType(projectInfo.projectTypeCode)}/${projectInfo.id}/work/${workId}`;

  const isNormalStatus = (value) => {
    return (getDictObj(dictReportStatus, value) || {}).code === 'PLAN';
  };

  const mergeTimingsAndWorks = (timings, works) => {
    const worksHash = keyBy(works, 'id');

    return orderBy(timings
      .filter(timing => timing.isOverdue)
      .map(timing => ({
        ...(worksHash[timing.projectWorkId] || []),
        ...timing,
      }))
      .map(timing => ({
        ...timing,
        projectWorkName: timing.projectId === projectInfo.projectId ? timing.name
          : getWorkNameWithProject(timing, dict.types, works),
      })), 'isOverdue', 'desc');
  };

  const isEdit = !isReadonly() && !status.published;
  const isProjectOfficeCommentEdit = checkRight('STATUS_REPORT_PROJECT_OFFICE_COMMENT_EDIT');

  useEffect(() => {
    if (!status.id) {
      return;
    }

    service.get(`/work/status-report-worksGroup/${status.id}`).then(setWorkData);
    getWorks(status.id).then(setWorks).then(() => setIsWorksLoaded(true));
  }, [status.id]);

  useEffect(() => {
    if (!status.projectVersionId) {
      return;
    }
  }, [status.projectVersionId]);

  const onChangeList = (listName, fieldName, id, newVal) => {
    const list = status[listName] || [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        onChangeInput(`${listName}[${i}].${fieldName}`, newVal);
        return;
      }
    }
  }

  const checkBudgetDeviation = () => {
    const maxBudgetDvn = 15;
    return status.budgetDdsDeviation > maxBudgetDvn
      || status.budgetFotDeviation > maxBudgetDvn
      || status.budgetOkbDeviation > maxBudgetDvn
      || status.budgetOpexDeviation > maxBudgetDvn;
  }

  const isAnyTimingsComment = () => status.timings?.some(timing => timing.comment);

  const withRedRequired = (condition) => {
    if (!condition) {
      return null;
    }
    return accentRequired ? 'red' : 'default'
  };

  const isConsolidateCommentRequired
    = withRedRequired(getDictObj(dictReportStatus, status.consolidateStatusId)?.code !== 'PLAN');

  const isBudgetCommentRequired
    = withRedRequired(getDictObj(dictReportStatus, status.budgetStatusId)?.code !== 'PLAN'
    || checkBudgetDeviation());
  const isResourceStatusNotPlan
    = withRedRequired(getDictObj(dictReportStatus, status.resourceStatusId)?.code !== 'PLAN');

  const updateValues = (newRiskData) => {
    onChangeRiskComment(newRiskData.map(riskComment => ({projectRiskId: riskComment?.risk.id,
      comment: riskComment?.comment})).filter(riskComment => riskComment.projectRiskId && riskComment.comment));
  };

  const childBudgetStatusCode = getDictCodeById(dictReportStatus, status.childBudgetStatusId);
  const childTimingStatusCode = getDictCodeById(dictReportStatus, status.childTimingStatusId);
  const childRiskStatusCode = getDictCodeById(dictReportStatus, status.childRiskStatusId);

  const childStatuses = [childBudgetStatusCode, childTimingStatusCode, childRiskStatusCode];

  const childDeviationCode = childStatuses.includes(ProjectStatusReportStatus.SIGNIFICANT_DEVIATION)
    ? ProjectStatusReportStatus.SIGNIFICANT_DEVIATION
    : childStatuses.includes(ProjectStatusReportStatus.SLIGHT_DEVIATION)
      ? ProjectStatusReportStatus.SLIGHT_DEVIATION : ProjectStatusReportStatus.PLAN;

  const statusToTextForChild = (statusCode) => statusCode === ProjectStatusReportStatus.SIGNIFICANT_DEVIATION
    ? 'со значительным отклонением' : 'с незначительным отклонением';

  const hide = helper?.hide || {};

  return (
    <React.Fragment>

      {status.isUpdate && (
        <div className="report-items__container">За прошедший период был выполнен запрос на изменения</div>
      )}

      <div className="report-items__container">
        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Сводный статус
            </div>
            <div className="report-item__title-status">
              <StatusIndicator value={status.consolidateStatusId} isWithText={true} />
            </div>
          </div>
          {childDeviationCode !== ProjectStatusReportStatus.PLAN && (
            <div className='input-title__description no-padding'>
              {`Имеются проекты/программы ${statusToTextForChild(childDeviationCode)} по сводному статусу`}
            </div>
          )}
          <hr className="report-line" />
          {status.published ? (
            <div>
              Отчет опубликован {formatDate(status.dateUpdate)}
            </div>
          ) : (
            <div>
              Отчет не опубликован
            </div>
          )}
          {isMiniMode ? (
            <>
              <div className="break-spaces">
                Комментарий: {status.consolidateComment || 'Не заполнен'}
              </div>
              {!hide.projectOfficeComment && (
                <div className="break-spaces">
                  Комментарий корпоративного проектного офиса: {status.projectOfficeComment || 'Не заполнен'}
                </div>
              )}
            </>
          ) : (
            <>
              <TextArea
                label="Комментарий"
                required={isConsolidateCommentRequired}
                value={status.consolidateComment}
                onChange={(val) => onChangeInput('consolidateComment', val.target.value)}
                disabled={!isEdit} />
              {!hide.projectOfficeComment && (
                <TextArea
                  label="Комментарий корпоративного проектного офиса"
                  value={status.projectOfficeComment}
                  onChange={(val) => onChangeInput('projectOfficeComment', val.target.value)}
                  disabled={isReadonly() || !isProjectOfficeCommentEdit} />
              )}
            </>
          )}
        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Бюджет
            </div>
            <div className="report-item__title-status">
              <StatusIndicator value={status.budgetStatusId} isWithText={true} />
            </div>
          </div>
          {childBudgetStatusCode && childBudgetStatusCode !== ProjectStatusReportStatus.PLAN && (
            <div className='input-title__description no-padding'>
              {`Имеются проекты/программы ${statusToTextForChild(childBudgetStatusCode)} по бюджету`}
            </div>
          )}
          <hr className="report-line" />
          <div className="report-item__title__container flex-nowrap">
            <div className="report-item__budget-plan-fact-container">
              <div className="report-item__budget-plan">
                План ОКВ: {getRound(status.budgetOkbPlan, 0)} {i18n.t('SUM_TITLE')}
              </div>
              <div className="report-item__budget-fact">
                Факт ОКВ: {getRound(status.budgetOkbFact, 0)} {i18n.t('SUM_TITLE')}
              </div>
            </div>
            <div className="report-item__title-percent nowrap">
              Отклонение ОКВ: {status.budgetOkbDeviation}%
            </div>
          </div>
          <div className="report-item__title__container flex-nowrap">
            <div className="report-item__budget-plan-fact-container">
              <div className="report-item__budget-plan">
                План ДДС: {getRound(status.budgetDdsPlan, 0)} {i18n.t('SUM_TITLE')}
              </div>
              <div className="report-item__budget-fact">
                Факт ДДС: {getRound(status.budgetDdsFact, 0)} {i18n.t('SUM_TITLE')}
              </div>
            </div>
            <div className="report-item__title-percent nowrap">
              Отклонение ДДС: {status.budgetDdsDeviation}%
            </div>
          </div>
          <div className="report-item__title__container flex-nowrap">
            <div className="report-item__budget-plan-fact-container">
              <div className="report-item__budget-plan">
                План OPEX: {getRound(status.budgetOpexPlan, 0)} {i18n.t('SUM_TITLE')}
              </div>
              <div className="report-item__budget-fact">
                Факт OPEX: {getRound(status.budgetOpexFact, 0)} {i18n.t('SUM_TITLE')}
              </div>
            </div>
            <div className="report-item__title-percent nowrap">
              Отклонение OPEX: {status.budgetOpexDeviation}%
            </div>
          </div>
          <div className="report-item__title__container flex-nowrap">
            <div className="report-item__budget-plan-fact-container">
              <div className="report-item__budget-plan">
                План ФОТ: {getRound(status.budgetFotPlan, 0)} {i18n.t('SUM_TITLE')}
              </div>
              <div className="report-item__budget-fact">
                Факт ФОТ: {getRound(status.budgetFotFact, 0)} {i18n.t('SUM_TITLE')}
              </div>
            </div>
            <div className="report-item__title-percent nowrap">
              Отклонение ФОТ: {status.budgetFotDeviation}%
            </div>
          </div>
          {(!isNormalStatus(status.budgetStatusId)) && (isMiniMode ? (
            <div className="break-spaces">
              Комментарий: {status.budgetComment || 'Не заполнен'}
            </div>
          ) : (
            <TextArea
              label="Комментарий"
              tooltip
              required={isBudgetCommentRequired}
              value={status.budgetComment}
              onChange={(val) => onChangeInput('budgetComment', val.target.value)}
              disabled={!isEdit} />
          ))}

        </div>

        <div className="report-item__container">
          <Spoiler title="Информация о блоках работ" titleClassName="report-item__title" defaultState={true}>
            <hr className="report-line" />
            <h6>Завершенные за прошедший месяц</h6>
            <hr className="report-line" />
            {renderWorkList(projectInfo.projectId, workData.end, dict, workLinkGenerator, 'end')}
            <h6>Активные работы</h6>
            <hr className="report-line" />
            {renderWorkList(projectInfo.projectId, workData.active, dict, workLinkGenerator, 'active')}
            <h6>Запланированные к завершению в ближайший месяц</h6>
            <hr className="report-line" />
            {renderWorkList(projectInfo.projectId, workData.endSoon, dict, workLinkGenerator, 'endSoon')}
            {workData.cancel.length ? (
              <>
                <h6>Отмененные</h6>
                <hr className="report-line" />
                {renderWorkList(projectInfo.projectId, workData.cancel, dict, workLinkGenerator, 'cancel')}
              </>
            ) : null}

          </Spoiler>
        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Сроки
            </div>
            <div className="report-item__title-status">
              <StatusIndicator value={status.timingStatusId} isWithText={true} />
            </div>
          </div>
          {childTimingStatusCode && childTimingStatusCode !== ProjectStatusReportStatus.PLAN && (
            <div className='input-title__description no-padding'>
              {`Имеются проекты/программы ${statusToTextForChild(childTimingStatusCode)} по срокам`}
            </div>
          )}
          {status.timingDeviation > 0 && (
            <div className="agree_warning_message">
              {`Сроки реализации графика работ превышают сроки реализации ${getProjectTypeText(projectInfo.projectTypeCode)}`}
            </div>
          )}
          {isWorksLoaded ? mergeTimingsAndWorks(status.timings, works).map((timing, i) => (
            <React.Fragment key={timing.id}>
              <hr className="report-line"/>
              <Link className="report-item__title__container bold" to={workLinkGenerator(timing.projectWorkId)}>
                {timing.projectWorkName}
              </Link>
              <div className="report-item__title__container">
                Тип: {getDict(dict.workType, timing.typeId)}
              </div>
              {!checkMilestone(timing) && (
                <div className="report-item__title__container">
                  Начало: {formatDate(timing.dateStart, FORMAT_DATE)}
                </div>
              )}
              <div className="report-item__title__container">
                <div className="margin-between report-item__timing-plan">
                  Окончание (план): {formatDate(timing.dateEnd, FORMAT_DATE)}
                </div>
                <div className={cx('report-item__title-percent', 'percent-row')}>
                  Отклонение: {checkMilestone(timing) ? `${formatDays(timing.daysDeviation)}` : `${timing.deviation}%`}
                </div>
              </div>
              {!checkMilestone(timing) && (
                <div className="report-item__title__container">
                  Длительность: {formatDays(timing.duration)}
                </div>
              )}
              <div className="report-item__title__container">
                Текущий статус: {timing.statusId
                ? `${getDict(dict.workStatus, timing.statusId)}${timing.dateUpdateStatus ? `, был установлен ${formatDateWithFormat(timing.dateUpdateStatus)}` : ''}`
                : ''}
              </div>
              <div className={cx('report-item__title-percent', 'percent-column')}>
                Отклонение: {timing.deviation}%
              </div>
              {isMiniMode ? (
                <div className="break-spaces">
                  Комментарий: {timing.comment || 'Не заполнен'}
                </div>
              ) : (
                <TextArea
                  label="Комментарий"
                  tooltip
                  required={
                    withRedRequired(timing.isOverdue
                      || (!isAnyTimingsComment()))
                  }
                  value={timing.comment}
                  onChange={(val) => onChangeList('timings', 'comment', timing.id, val.target.value)}
                  disabled={!isEdit} />
              )}
            </React.Fragment>
          )) : (
            <div>Загрузка...</div>
          )}
        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Ресурсы
            </div>
            <div className="report-item__title-status">
              <StatusIndicator value={status.resourceStatusId} isWithText={true} />
            </div>
          </div>
          {status.resources.map((resource, i) => (
            <React.Fragment key={resource.id}>
              <hr className="report-line" />
              <div className="report-item__title__container">
                <div className="bold">
                  {resource.projectEmployeeDisplayName}
                </div>
                <div className="report-item__title-team-load">
                  Загрузка в текущем проекте: {resource.projectEmployeeLoad}%
                </div>
              </div>
              <div className="report-item__title__container">
                <div className="margin-between report-item__resources-Факт">
                  План по всем проектам: {resource.fact}% в {getMonth(moment(status.dateCreate).get('month'))} {moment(status.dateCreate).format("YYYY")}
                </div>
                <div className="report-item__title-percent">
                  Отклонение: {resource.deviation}%
                </div>
              </div>
              {!isNormalStatus(status.resourceStatusId) && (isMiniMode ? (
                <div className="break-spaces">
                  Комментарий: {resource.comment || 'Не заполнен'}
                </div>
              ) : (
                <TextArea
                  label="Комментарий"
                  tooltip
                  required={isResourceStatusNotPlan}
                  value={resource.comment}
                  onChange={(val) => onChangeInput(`resources[${i}].comment`, val.target.value)}
                  disabled={!isEdit} />
              ))}

            </React.Fragment>
          ))}

        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Периметр проекта
            </div>
            {isMiniMode && (
              <div className="report-item__title-status">
                <StatusIndicator value={status.perimeterStatusId} isWithText={true} />
              </div>
            )}
          </div>

          {isMiniMode ? (
            <div>
              <hr className="report-line break-spaces" />
              Комментарий: {status.perimeterComment || 'Не заполнен'}
            </div>
          ) : (
            <div>
              <Select
                tooltip
                options={dictReportStatusSelect}
                label="Статус"
                value={status.perimeterStatusId}
                onChange={(value) => onChangeInput('perimeterStatusId', value)}
                disabled={!isEdit}
              />

              <TextArea
                label="Комментарий"
                tooltip
                value={status.perimeterComment}
                onChange={(e) => onChangeInput('perimeterComment', e.target.value)}
                disabled={!isEdit} />
            </div>
          )}

        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Эскалация
            </div>
            <div className="report-item__title-escalation">
              <SwitchSlider onChange={(val) => onChangeInput('escalation', val)} checked={status.escalation} disabled={!isEdit} />
            </div>
          </div>

          {isMiniMode ? (
            <div>
              <hr className="report-line break-spaces" />
              Комментарий: {status.escalationComment || 'Не заполнен'}
            </div>
          ) : (
            <TextArea
              tooltip
              label="Комментарий"
              value={status.escalationComment}
              onChange={(e) => onChangeInput('escalationComment', e.target.value)}
              disabled={!isEdit || !status.escalation} />
          )}

        </div>

        <div className="report-item__container">
          <div className="report-item__title__container">
            <div className="report-item__title">
              Риски
            </div>
            <div className="report-item__title-status">
              <StatusIndicator value={status.riskStatusId} isWithText={true} textGenerator={item => item.nameRisk} />
            </div>
          </div>
          {childRiskStatusCode && childRiskStatusCode !== ProjectStatusReportStatus.PLAN && (
            <div className='input-title__description no-padding'>
              {`Имеются проекты/программы  с реализовавшимися рисками 
              ${childRiskStatusCode === ProjectStatusReportStatus.SIGNIFICANT_DEVIATION
                ? 'с высокой величиной' : 'со средней величиной'}`}
            </div>
          )}
          <div className="report-item__risk__container">
            <RiskTable projectVersionId={status.projectVersionId}
                       dict={dict}
                       setData={updateValues}
                       statusComments={status.riskComments}
                       isAcceptEdit={isEdit}
                       statusReportId={status.id}
                       accentRequired={accentRequired}
                       reportDate={status.dateCreate}
            />
          </div>

        </div>
      </div>

      <div className="marginTop-20">
        <DocBlock
          tooltip
          isAcceptDelete={isEdit && checkRight('EDIT_PROJECT')}
          isEdit={isEdit}
          isRejectRemove={!isEdit}
          typeCode="STATUS_REPORT"
          ref={docRef}
          projectId={projectInfo.id}
          otherId={status.id} />
      </div>
    </React.Fragment>
  );
};

export default EditView;
