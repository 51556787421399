import service from '@/services';

export const loadUserInfo = (isUserActiveCheck) => {
  return (dispatch) => {
    return service.getUser()
      .then((body) => {
        if (!isUserActiveCheck(body)) {
          dispatch({
            type: 'LOGOUT_USER'
          })
          return;
        }

        dispatch({
          type: 'LOGIN_USER',
          payload: body
        })
      });
  };
};

export const loadUserFavoriteProjects = () => {
  return (dispatch) => {
    return service.get('/user/favorite/project')
      .then((body) => {
        dispatch({
          type: 'UPDATE_FAVORITE_PROJECTS',
          payload: body,
        })
      });
  };
};
