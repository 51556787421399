import { cloneDeep, isEmpty, isEqual, keyBy, uniq } from 'lodash';
import { LinkTypes } from '@/components/Gantt/const';
import { WeekendUtilType } from '@/components/Gantt/util/dateUtil';
import {
  filterRemoved, findAndUpdate, findByFuncAndUpdate, flatten, getDateEndForDuration, getDateRangeSimple, isChild
} from '@/components/Gantt/util/utils';
import { checkGroup, checkMilestone } from '@/pages/CreateProject/Blocks/utils';
import { getDictCodeById, isEmptyValues, isNotEmptyValues, momentToSelectDate, parseDate } from '@/utils';
import { Moment } from 'moment/moment';
import { WORK_STATUS_SUCCESS, WorkStatus } from '@/config/const';
import { ShowModal } from '@/utils/hooks';

const findChildLinks = (links: GanttTableLink[], id: number): GanttTableLink[] => {
  return links.filter(item => item.fromId === id);
};
const findParentLinks = (links: GanttTableLink[], id: number): GanttTableLink[] => {
  return links.filter(item => item.toId === id);
};

const getDeltaDays = (type: LinkTypes, parentItem: GanttTableItem, item: GanttTableItem, workStatusDict: DictItem) => {
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(getDateEndForDuration(parentItem, workStatusDict));
  const dateStart = parseDate(item.dateStart);
  const dateEnd = checkMilestone(item) ? dateStart : parseDate(item.dateEnd);

  switch (type) {
    case LinkTypes.END_TO_START:
      return parentDateEnd ? parentDateEnd.diff(dateStart, 'day') + 1 : 0;
    case LinkTypes.START_TO_START:
      return parentDateStart ? parentDateStart.diff(dateStart, 'day') : 0;
    case LinkTypes.START_TO_END:
      return parentDateStart ? parentDateStart.diff(dateEnd, 'day') - 1 : 0;
    case LinkTypes.END_TO_END:
      return parentDateEnd ? parentDateEnd.diff(dateEnd, 'day') : 0;
  }
};

const getParentMoveDays = (type: LinkTypes, parentItem: GanttTableItem, workStatusDict: DictItem) => {
  const parentDateStartPrev = parseDate(parentItem.dateStartPrev);
  const parentDateEndPrev = checkMilestone(parentItem) ? parentDateStartPrev : parseDate(parentItem.dateEndPrev);
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(getDateEndForDuration(parentItem, workStatusDict));

  switch (type) {
    case LinkTypes.END_TO_START:
    case LinkTypes.END_TO_END:
      return parentDateEnd ? parentDateEnd.diff(parentDateEndPrev, 'day') : 0;
    case LinkTypes.START_TO_START:
    case LinkTypes.START_TO_END:
      return parentDateStart ? parentDateStart.diff(parentDateStartPrev, 'day'): 0;
  }
};

const getLagDays = (
  type: LinkTypes,
  parentItem: GanttTableItem,
  item: GanttTableItem,
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
) => {
  const parentDateStartPrev = parseDate(parentItem.dateStartPrev);
  const parentDateEndPrev = checkMilestone(parentItem) ? parentDateStartPrev : parseDate(parentItem.dateEndPrev);
  const dateStart = parseDate(item.dateStart);
  const dateEnd = checkMilestone(item) ? dateStart : parseDate(getDateEndForDuration(item, workStatusDict));

  switch (type) {
    case LinkTypes.END_TO_START:
      return weekendUtil.getBetween(parentDateEndPrev, dateStart);
    case LinkTypes.START_TO_START:
      return weekendUtil.getBetween(parentDateStartPrev, dateStart);
    case LinkTypes.START_TO_END:
      return weekendUtil.getBetween(parentDateStartPrev, dateEnd);
    case LinkTypes.END_TO_END:
      return weekendUtil.getBetween(parentDateEndPrev, dateEnd);
  }
};

const getNewDateByLag = (
  type: LinkTypes,
  parentItem: GanttTableItem,
  lagDays: number,
  duration: number,
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
) => {
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(getDateEndForDuration(parentItem, workStatusDict));

  let start;
  let end;
  switch (type) {
    case LinkTypes.END_TO_START:
      start = parentDateEnd ? weekendUtil.getWorkDaysAfter(parentDateEnd, lagDays) : null;
      end = start ? weekendUtil.pad(weekendUtil.getDateEnd(start, duration)) : null;
      break;
    case LinkTypes.END_TO_END:
      end = parentDateEnd ? weekendUtil.getWorkDaysAfter(parentDateEnd, lagDays) : null;
      start = end ? weekendUtil.getDateStart(end, duration) : null;
      break;
    case LinkTypes.START_TO_START:
      start = parentDateStart ? weekendUtil.getWorkDaysAfter(parentDateStart, lagDays) : null;
      end = start ? weekendUtil.pad(weekendUtil.getDateEnd(start, duration)) : null;
      break;
    case LinkTypes.START_TO_END:
      end = parentDateStart ? weekendUtil.getWorkDaysAfter(parentDateStart, lagDays) : null;
      start = end ? weekendUtil.getDateStart(end, duration) : null;
      break;
  }

  return { start, end };
};

export interface CheckChildrenNotBeforeParentError {
  id: number;
  message: string;
  isBlocking: boolean;
}
export const checkChildrenNotBeforeParent = (
  currentItem: GanttTableItem,
  data: GanttTableItem[],
  links: GanttTableLink[],
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
  isCheckAllChain: boolean = false,
  checked: Record<number, boolean> = {},
  errors: CheckChildrenNotBeforeParentError[] = [],
) => {
  const dataFlatten = keyBy(flatten(data), 'id');
  const childLinks = findChildLinks(links, currentItem.id);

  childLinks.forEach(link => {
    if (link.toId === currentItem.id || checked[link.toId]) {
      return;
    }

    const child = dataFlatten[link.toId];
    if (!child) {
      return;
    }

    try {
      checkNotBeforeParent(child, data, links, weekendUtil, workStatusDict, false, false);
    } catch (e: any) {
      e.forEach(err => errors.push({ id: child.id, message: err.message, isBlocking: err.parentId !== currentItem.id }));
    }

    if (isCheckAllChain) {
      checked[child.id] = true;
      checkChildrenNotBeforeParent(child, data, links, weekendUtil, workStatusDict, isCheckAllChain, checked, errors);
    }
  });

  return errors;
}

export const checkNotBeforeParent = (
  currentItem: GanttTableItem,
  data: GanttTableItem[],
  links: GanttTableLink[],
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
  isFromEditForm: boolean = false,
  withMoveErrorText: boolean = true,
) => {
  if (WORK_STATUS_SUCCESS.includes(WorkStatus[getDictCodeById(workStatusDict, currentItem.statusId)])) {
    return;
  }

  const dataFlatten = keyBy(flatten(data), 'id');
  const parentLinks = findParentLinks(links, currentItem.id);
  const errors = [];
  parentLinks.forEach(link => {
    if (link.fromId === currentItem.id) {
      return;
    }

    const parent = dataFlatten[link.fromId];
    if (!parent) {
      return;
    }

    if (checkGroup(parent) && isChild(parent.subRows, currentItem.id)) {
      return;
    }

    const linkCode = weekendUtil.getLinkTypeCodeById(link.typeId);
    const deltaDays = getDeltaDays(linkCode, parent, currentItem, workStatusDict);

    if (deltaDays > 0) {
      const moveErrorText = withMoveErrorText ? (isFromEditForm
        ? `Для сохранения удалите связь с блоком работ "${parent.name}", измените тип связи или переместите текущий блок работ.`
        : `Для перемещения блока работ удалите связь с блоком работ "${parent.name}" или измените тип связи.`) : '';

      const error = { parentId: parent.id, message: null };
      switch (linkCode) {
        case LinkTypes.END_TO_START:
          error.message = `Работа ${currentItem.rowNum} "${currentItem.name}" не может начинаться раньше окончания работы ${parent.rowNum} "${parent.name}".\n${moveErrorText}`;
          break;
        case LinkTypes.START_TO_START:
          error.message = `Работа ${currentItem.rowNum} "${currentItem.name}" не может начинаться раньше начала работы ${parent.rowNum} "${parent.name}".\n${moveErrorText}`;
          break;
        case LinkTypes.START_TO_END:
          error.message = `Работа ${currentItem.rowNum} "${currentItem.name}" не может заканчиваться раньше начала работы ${parent.rowNum} "${parent.name}".\n${moveErrorText}`;
          break;
        case LinkTypes.END_TO_END:
          error.message = `Работа ${currentItem.rowNum} "${currentItem.name}" не может заканчиваться раньше окончания работы ${parent.rowNum} "${parent.name}".\n${moveErrorText}`;
      }

      errors.push(error);
    }
  });

  if (!isEmpty(errors)) {
    throw errors;
  }
}

export const fixByLinks = (
  id: number,
  oldData: GanttTableItem[],
  links: GanttTableLink[],
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
  isByNewLink: boolean = false,
) => {
  const dataFlatten = keyBy(flatten(oldData), 'id');
  const beUpdate: Record<number, GanttTableItem> = {};

  const fixByLinksLocal = (currentItem: GanttTableItem) => {
    if (!currentItem) {
      return;
    }

    const isGroup = checkGroup(currentItem);

    //Подвинем дочерние работы
    const childLinks = findChildLinks(links, currentItem.id);
    childLinks.forEach(link => {
      if (link.toId in beUpdate || link.toId === id) {
        return;
      }

      let isUpdate = false;
      const child = dataFlatten[link.toId];
      if (!child || child.isFromOtherProject) {
        return;
      }

      if (WORK_STATUS_SUCCESS.includes(WorkStatus[getDictCodeById(workStatusDict, child.statusId)])) {
        return;
      }

      if (isGroup && isByNewLink && isChild(currentItem.subRows, child.id)) {
        return;
      }

      const linkCode = weekendUtil.getLinkTypeCodeById(link.typeId);
      const parentMoveDays = getParentMoveDays(linkCode, currentItem, workStatusDict);
      const lagDays = getLagDays(linkCode, currentItem, child, weekendUtil, workStatusDict);
      const deltaDays = getDeltaDays(linkCode, currentItem, child, workStatusDict);

      if (deltaDays > 0 || (parentMoveDays < 0 && !isByNewLink)) {
        const currentDateStart = parseDate(child.dateStart);
        const currentDateEnd = parseDate(child.dateEnd);
        let newDateStart: Moment;
        let newDateEnd: Moment;
        if (deltaDays > 0) { //Если родитель накладывается на работу, то двигаем работу за родителя
          newDateStart = weekendUtil.pad(currentDateStart.add(deltaDays, 'day'));
          newDateEnd = weekendUtil.pad(weekendUtil.getDateEnd(newDateStart, child.duration));
        } else { // Если родителя подвинули назад, то двигаем работу тоже назад
          const newDates = getNewDateByLag(linkCode, currentItem, lagDays, child.duration, weekendUtil, workStatusDict);
          newDateStart = newDates.start;
          newDateEnd = newDates.end;
        }

        const dateStart = momentToSelectDate(newDateStart);
        const dateEnd = momentToSelectDate(newDateEnd);

        if (!isEqual(dateStart, child.dateStart) || !isEqual(dateEnd, child.dateEnd)) {
          isUpdate = true;
          beUpdate[child.id] = {
            ...child,
            dateStart: momentToSelectDate(newDateStart),
            dateEnd: momentToSelectDate(newDateEnd),
            dateStartPrev: currentDateStart,
            dateEndPrev: currentDateEnd,
            isLocalSaved: true,
          };
        }
      }

      if (isUpdate) {
        fixByLinksLocal(beUpdate[link.toId]);
      }
    });
  };

  fixByLinksLocal(dataFlatten[id]);

  return {
    fixedResult: findAndUpdate(oldData, Object.keys(beUpdate).map(item => +item), (item) => beUpdate[item.id], workStatusDict, weekendUtil),
    isChildrenUpdated: !!Object.keys(beUpdate).length,
  }
};

const mergeObjectWithArrays = (obj1, obj2) => {
  const result: Record<number, number[]> = {};

  [...Object.keys(obj1), ...Object.keys(obj2)].forEach(key => {
    result[key] = uniq([...(obj1[key] || []), ...(obj2[key] || [])]);
  });

  return result;
};

const getGroupsDeep = (oldData: GanttTableItem[], context = {}, result = {}): Record<number, number[]> => {
  if (isEmptyValues(oldData)) {
    return mergeObjectWithArrays(context, result);
  }

  const [head, ...tail] = oldData;

  Object.keys(context).forEach(key => {
    context[key].push(head.id);
  });

  if (isNotEmptyValues(head.subRows)) {
    const newContext = cloneDeep(context);
    newContext[head.id] ||= [];
    result = getGroupsDeep(head.subRows, newContext, result);
  }

  return getGroupsDeep(tail, context, mergeObjectWithArrays(context, result));
};

export const fixWorkInGroup = (oldData: GanttTableItem[], workStatusDict: DictItem, weekendUtil: WeekendUtilType): GanttTableItem[] => {
  return findByFuncAndUpdate(oldData, () => true, (item, groupId) => ({
    ...item,
    workGroupId: groupId,
    isLocalSaved: item.workGroupId != groupId || item.isLocalSaved,
  }), workStatusDict, weekendUtil);
};

export const fixGroup = (
  oldData: GanttTableItem[],
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
) => {
  const dataFlattenList = filterRemoved(flatten(oldData))
    .filter(item => !item.isFromOtherProject);
  const dataFlatten = keyBy(dataFlattenList, 'id');
  const beUpdate: Record<number, GanttTableItem> = {};

  const groups = getGroupsDeep(oldData);

  Object.entries(groups).forEach(([groupId, groupItemsId]) => {
    const curGroup = dataFlatten[groupId];
    if (!curGroup) {
      return;
    }

    const items = dataFlattenList.filter(item => !checkGroup(item) && groupItemsId.includes(item.id));
    const dateRange = getDateRangeSimple(items, workStatusDict);

    if (!dateRange) {
      beUpdate[groupId] = {
        ...curGroup,
        duration: null,
        dateStart: null,
        dateEnd: null,
        dateEndForRender: null,
      };
      return;
    }

    const isSuccess = WorkStatus.SUCCESS === getDictCodeById(workStatusDict, curGroup.statusId);
    beUpdate[groupId] = {
      ...curGroup,
      dateStart: momentToSelectDate(dateRange.min),
      dateEnd: momentToSelectDate(dateRange.maxPlan),
      dateEndFact: isSuccess ? momentToSelectDate(dateRange.maxFact) : null,
      dateEndForRender: momentToSelectDate(dateRange.max),
    };

    const duration = weekendUtil.getDuration(dateRange.min, parseDate(getDateEndForDuration(beUpdate[groupId], workStatusDict)));

    beUpdate[groupId] = {
      ...beUpdate[groupId],
      duration,
    }
  });

  let result = findByFuncAndUpdate(oldData, (item) => beUpdate.hasOwnProperty(item.id), (item) => beUpdate[item.id], workStatusDict, weekendUtil);
  result = fixWorkInGroup(result, workStatusDict, weekendUtil);

  return result;
};

export const showMoveChildrenModal = (
  errors: CheckChildrenNotBeforeParentError[],
  showModal: ShowModal,
  save: (isUpdateChildren: boolean, dropLinkChildrenIds?: number[]) => void,
  milestoneView = false
) => {
  const milestonePrefix = milestoneView ? 'Данная КТ имеет связи с другими работами. ' : ''
  if (isEmpty(errors)) {
    showModal(`${milestonePrefix}Переместить последующие связанные работы?`, true, {
      title: 'Внимание',
      isHideButtonClose: true,
      buttons: [
        { title: 'Переместить', onClick: () => save(true), closeAfterClick: true },
        { title: 'Не перемещать', onClick: () => save(false), closeAfterClick: true },
        { title: 'Отмена', closeAfterClick: true },
      ],
    });
  } else {
    const blockingError = errors.find(err => err.isBlocking);
    const errorText = blockingError?.message || errors[0].message;
    const errorChildIds = errors.map(({ id }) => +id);
    const actionText = blockingError ? `${milestonePrefix}Перемещение без разрыва связи невозможно, разорвать связь или отменить?`
      : `${milestonePrefix}Переместить последующие связанные работы или разорвать связь?`;
    showModal(`${errorText}${actionText}`, true, {
      title: 'Внимание',
      isHideButtonClose: true,
      buttons: [
        { title: 'Переместить', onClick: () => save(true), closeAfterClick: true, isHide: !!blockingError },
        { title: 'Разорвать связь', onClick: () => save(false, errorChildIds), closeAfterClick: true },
        { title: 'Отмена', closeAfterClick: true },
      ],
    });
  }
}