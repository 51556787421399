import React from 'react';
import TextArea from '@/elements/TextArea';
import i18n from 'i18next';
import SelectDict from '@/elements/Select/SelectDict';
import SwitchSliderTitle from '@/elements/SwitchSliderTitle';
import cx from 'classnames';
import { getDictByCode, isEmptyValues } from '@/utils';
import { ProjectStatus, UserRight } from '@/config/const';
import { useAppSelector } from '@/store';
import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import { isEmpty } from 'lodash';
import { useCheckRight, useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

export interface IEditBlockTopFields {
  data: Work;
  allWorks: Work[];
  isFromGantt: boolean;
  required: boolean | 'red' | 'default';
  handleChangeSelect: (field: string, value: any, updateIsDirty?: boolean) => void;
  readonly: boolean;
  isEdit: boolean;
  milestoneView: boolean;
}

const filterByType = (list, typeId) => {
  if (isEmptyValues(list)) {
    return;
  }

  return list
    .filter((item) => item.typesId.includes(typeId))
    .map(item => ({
      ...item,
      children: filterByType(item.children, typeId)
    }));
};

const EditBlockTopFields = (props: IEditBlockTopFields) => {
  const extModule = useExtImport<IExtendableProps>('pages/CreateProject/Blocks/Components/EditBlockTopFieldsExt');

  const { isFromGantt, required, data, handleChangeSelect, readonly, isEdit, allWorks, milestoneView } = props;

  const checkRight = useCheckRight();
  const [dict, newProjectData] = useAppSelector(state => [
    state.dict,
    state.NewProject.newProjectData,
  ]);

  const isEditRight = newProjectData.edit && checkRight(UserRight.EDIT_PROJECT);

  const isProjectActive = getDictByCode(dict.status, ProjectStatus.RELEASE)?.id === newProjectData?.statusId;
  const isGroup = checkGroup(data);

  const isGroupWithTasks = checkGroup(data)
    && !isEmpty(allWorks.filter(w => w.workGroupId === data.id && !w.isRemove));

  return (
    <ExtendableWrapper {...extModule} props={props}>
      <TextArea
        required={required}
        label={i18n.t('workDataRequest.name')}
        value={data.name}
        onChange={(_, val) => handleChangeSelect('name', val)}
        disabled={isProjectActive || readonly || data.isExtreme}
      />

      {!milestoneView && (
        <SelectDict
          required={required}
          label={i18n.t('workDataRequest.typeId')}
          value={data.typeId}
          onChange={(val) => {
            handleChangeSelect('typeId', val);
            setTimeout(() => handleChangeSelect('statusId', null, !isGroup), 200);
          }}
          dictName="workType"
          disabled={isProjectActive || readonly || (!isFromGantt && isEdit) || isGroupWithTasks || data.isExtreme}
        />
      )}

      {isGroup && (
        <SwitchSliderTitle
          title={i18n.t('workDataRequest.isShowDashboard')}
          checked={data.isShowDashboard}
          onChange={(val) => handleChangeSelect('isShowDashboard', val)}
          className={cx('no-padding', 'workList_edit__switch')}
          disabled={!isEditRight || readonly}
        />
      )}
    </ExtendableWrapper>
  )
}

export default EditBlockTopFields;